import { SNC2D } from "../../data/OSSD/Grade10/SNC2D";
import GradeCourseTemplate from "../../pageTemplate/GradeCourseTemplate";


export default function SNC2DPage() {
    return (
        <div>
            <GradeCourseTemplate data={SNC2D} />
        </div>
    )
}
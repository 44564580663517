import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import Divider from "../ToolComp/divider";
function Strategies(props) {
    return (
        <div className="strategy color-background">
            <div className="inner section">
                <h2>Teaching and Learning Strategies</h2>
                <Divider />
                <p>
                {props.strategy.p1}
            </p>
                <p>
                {props.strategy.p2}
            </p>
            <div className="strategy-content">
                <ul>
                    {props.strategy.lst.map(
                        (item) => (
                            <li><IoMdCheckmarkCircleOutline className="bullet-point-icon" />{item}</li>
                        )
                    )}
                </ul>
            </div>
            </div>

        </div>
    );
}

export default Strategies;
import { ELS2O } from "../../data/OSSD/Grade10/ELS2O";
import GradeCourseTemplate from "../../pageTemplate/GradeCourseTemplate";


export default function ELS2OPage() {
    return (
        <div>
            <GradeCourseTemplate data={ELS2O} />
        </div>
    )
}
import { CHC2D } from "../../data/OSSD/Grade10/CHC2D";
import GradeCourseTemplate from "../../pageTemplate/GradeCourseTemplate";


export default function CHC2DPage() {
    return (
        <div>
            <GradeCourseTemplate data={CHC2D} />
        </div>
    )
}
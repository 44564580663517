import { HashRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/GlobalStyle.css";

import Footer from "./components/CommonComp/Footer";
import GlobalStyle from "./components/CommonComp/GlobalStyle";

import Home from "./pages/commonPage/Home";
import Academic from "./pages/commonPage/Academic";
import Admission from "./pages/commonPage/Admission";
import Contact from "./pages/commonPage/Contact";
import CourseCalendar from "./pages/commonPage/CourseCalendar";

import GradeNineOverviewPage from "./pages/overviewPage/Grade9OverviewPage";
import GradeTenOverviewPage from "./pages/overviewPage/Grade10OverviewPage";
import GradeElevenOverviewPage from "./pages/overviewPage/Grade11OverviewPage";
import GradeTwelveOverviewPage from "./pages/overviewPage/Grade12OverviewPage";

import ESLCOpage from "./pages/ESLCoursePage/ESLCOpage";
import ESLDOpage from "./pages/ESLCoursePage/ESLDOpage";
import ESLEOPage from "./pages/ESLCoursePage/ESLEOPage";

import MHF4UPage from "./pages/grade12CoursePage/MHF4U";
import SPH4UPage from "./pages/grade12CoursePage/SPH4U";
import MCV4UPage from "./pages/grade12CoursePage/MCV4U";
import MDM4UPage from "./pages/grade12CoursePage/MDM4U";
import SCH4UPage from "./pages/grade12CoursePage/SCH4U";
import ENG4UPage from "./pages/grade12CoursePage/ENG4U";
import OLC4OPage from "./pages/grade12CoursePage/OLC4O";

import ENG3UPage from "./pages/grade11CoursePage/ENG3U";
import MCR3UPage from "./pages/grade11CoursePage/MCR3U";
import SBI3UPage from "./pages/grade11CoursePage/MCR3U";
import SCH3UPage from "./pages/grade11CoursePage/SCH3U";
import SPH3UPage from "./pages/grade11CoursePage/SPH3U";

import BBI2OPage from "./pages/grade10CoursePage/BBI2O";
import GLC2OPage from "./pages/grade10CoursePage/GLC2O";
import CHV2OPage from "./pages/grade10CoursePage/CHV2O";
import ICS2OPage from "./pages/grade10CoursePage/ICS2O";
import ENG2DPage from "./pages/grade10CoursePage/ENG2D";
import FSF2DPage from "./pages/grade10CoursePage/FSF2D";
import CHC2DPage from "./pages/grade10CoursePage/CHC2D";
import GLE2OPage from "./pages/grade10CoursePage/GLE2O";
import ELS2OPage from "./pages/grade10CoursePage/ELS2O";
import MPM2DPage from "./pages/grade10CoursePage/MPM2D";
import ASM2OPage from "./pages/grade10CoursePage/ASM2O";
import AWQ2OPage from "./pages/grade10CoursePage/AWQ2O";
import SNC2DPage from "./pages/grade10CoursePage/SNC2D";
import LKBDUPage from "./pages/grade10CoursePage/LKBDU";

import AVI1O from "./pages/grade9CoursePage/AVI1O";
import CGC1D from "./pages/grade9CoursePage/CGC1D";
import ENL1WPage from "./pages/grade9CoursePage/ENL1W";
import FSF1D from "./pages/grade9CoursePage/FSF1D";
import MTH1W from "./pages/grade9CoursePage/MTH1W";
import PPL1O from "./pages/grade9CoursePage/PPL1O";
import SNC1W from "./pages/grade9CoursePage/SNC1W";
import HeroWrapper from "./helper/HeroWrapper";
import JoinUs from "./pages/commonPage/JoinUs";
import Verify from "./pages/commonPage/Verify";
import University from "./pages/commonPage/University";

function App() {
  return (
    <div className="App">
      <HashRouter>
        <HeroWrapper />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/admission/calendar" element={<CourseCalendar />} />
          <Route path="/admission/application" element={<Admission />} />
          <Route path="/admission/yearcalendar" element={<Admission />} />
          <Route path="/academic" element={<Academic />} />
          <Route path="/resources/university" element={<University />} />

          <Route path="/contact/contactus" element={<Contact />} />
          <Route path="/contact/joinus" element={<JoinUs />} />
          <Route path="/login/verify" element={<Verify />} />
          <Route path="/academic/ESLCO" element={<ESLCOpage />} />
          <Route path="/academic/ESLDO" element={<ESLDOpage />} />
          <Route path="/academic/ESLEO" element={<ESLEOPage />} />
          <Route path="/academic/Grade9" element={<GradeNineOverviewPage />} />
          <Route path="/academic/Grade9/AVI1O" element={<AVI1O />} />
          <Route path="/academic/Grade9/CGC1D" element={<CGC1D />} />
          <Route path="/academic/Grade9/ENL1W" element={<ENL1WPage />} />
          <Route path="/academic/Grade9/FSF1D" element={<FSF1D />} />
          <Route path="/academic/Grade9/MTH1W" element={<MTH1W />} />
          <Route path="/academic/Grade9/PPL1O" element={<PPL1O />} />
          <Route path="/academic/Grade9/SNC1W" element={<SNC1W />} />
          <Route path="/academic/Grade10" element={<GradeTenOverviewPage />} />
          <Route path="/academic/Grade10/BBI2O" element={<BBI2OPage />} />
          <Route path="/academic/Grade10/GLC2O" element={<GLC2OPage />} />
          <Route path="/academic/Grade10/CHV2O" element={<CHV2OPage />} />
          <Route path="/academic/Grade10/ICS2O" element={<ICS2OPage />} />
          <Route path="/academic/Grade10/ENG2D" element={<ENG2DPage />} />
          <Route path="/academic/Grade10/FSF2D" element={<FSF2DPage />} />
          <Route path="/academic/Grade10/CHC2D" element={<CHC2DPage />} />
          <Route path="/academic/Grade10/GLE2O" element={<GLE2OPage />} />
          <Route path="/academic/Grade10/ELS2O" element={<ELS2OPage />} />
          <Route path="/academic/Grade10/MPM2D" element={<MPM2DPage />} />
          <Route path="/academic/Grade10/ASM2O" element={<ASM2OPage />} />
          <Route path="/academic/Grade10/AWQ2O" element={<AWQ2OPage />} />
          <Route path="/academic/Grade10/SNC2D" element={<SNC2DPage />} />
          <Route path="/academic/Grade10/LKBDU" element={<LKBDUPage />} />
          <Route path="/academic/Grade11" element={<GradeElevenOverviewPage />} />
          <Route path="/academic/Grade11/ENG3U" element={<ENG3UPage />} />
          <Route path="/academic/Grade11/MCR3U" element={<MCR3UPage />} />
          <Route path="/academic/Grade11/SBI3U" element={<SBI3UPage />} />
          <Route path="/academic/Grade11/SCH3U" element={<SCH3UPage />} />
          <Route path="/academic/Grade11/SPH3U" element={<SPH3UPage />} />
          <Route path="/academic/Grade12" element={<GradeTwelveOverviewPage />} />
          <Route path="/academic/Grade12/MHF4U" element={<MHF4UPage />} />
          <Route path="/academic/Grade12/SPH4U" element={<SPH4UPage />} />
          <Route path="/academic/Grade12/MCV4U" element={<MCV4UPage />} />
          <Route path="/academic/Grade12/MDM4U" element={<MDM4UPage />} />
          <Route path="/academic/Grade12/SCH4U" element={<SCH4UPage />} />
          <Route path="/academic/Grade12/ENG4U" element={<ENG4UPage />} />
          <Route path="/academic/Grade12/OLC4O" element={<OLC4OPage />} />
        </Routes>
        <Footer />
      </HashRouter>
      <GlobalStyle />
    </div>
  );
}

export default App;

import { useState } from "react";
import "../../styles/CompStyle/TabPanel.css";
export default function TabPanel({ tab }) {
    const [type, setType] = useState(0);
    const [activeButton, setActiveButton] = useState(0);
    const handleClick = (val) => {
        setType(val);
        setActiveButton(val);
    }
    const bottom = tab.map(
        (item) => (
            item.content.map(
                (content, index) => (
                    <p key={index} dangerouslySetInnerHTML={{ __html: content }} />
                )
            )
        )
    )
    return (
        <div className="tabPanel">
            <div className="tabs">
                {
                    tab.map(
                        (item, index) => (
                            <button style={{ backgroundColor: activeButton === index ? "#063970" : "white", color: activeButton === index ? "white" : "rgb(184, 134, 11)" }} onClick={() => handleClick(index)}>{item.title}</button>
                        )
                    )
                }
            </div>
            <div className="tab-content">
                {bottom[type]}
            </div>
        </div>
    )
}
import { IconContext } from "react-icons";
import { FcContacts, FcDiploma2, FcLibrary, FcCalculator, FcBusinessContact, FcBullish, FcPortraitMode, FcPlanner } from "react-icons/fc";
import Divider from "../ToolComp/divider";


function CourseDescription(props) {
    const truncatedCode = props.description.code.substring(0, 3); // Get the first three letters of code
    const imgSrc = `/img/academic_logo/${truncatedCode}.png`;
    return (

        <div className="descrip section">
            <div className="row">
                <div className="left">

                    <h1 className="title">
                        <span>
                            <img src={imgSrc} />
                        </span>
                        {props.description.code}
                    </h1>
                    <div className="line2"></div>
                    <h2>Course Description</h2>
                    <Divider />
                    <p>
                        {props.description.content}
                    </p>
                </div>
                <div className="right">
                    <ul className="descrp-list">
                    <IconContext.Provider value={{ size: "1.5rem" }}>
                        <li>
                            <FcContacts className="bullet-point-icon" />
                            <span>Course Code: </span>{props.description.code}
                        </li>
                        <li>
                            <FcDiploma2 className="bullet-point-icon" />
                            <span>Department:</span> {props.description.department}
                    </li>
                        <li>
                            <FcLibrary className="bullet-point-icon" />
                            <span>Course Type:</span>{props.description.type}
                    </li>
                    <li>
                            <FcCalculator className="bullet-point-icon" />
                            <span>Credit Value:</span>{props.description.credit}
                    </li>
                    <li>
                            <FcBusinessContact className="bullet-point-icon" />
                            <span>Prerequisite:</span>{props.description.prerequisite}
                    </li>
                    <li>
                            <FcBullish className="bullet-point-icon" />
                            <span>Curriculum Policy Document:</span>{props.description.document}
                    </li>
                    <li>
                            <FcPortraitMode className="bullet-point-icon" />
                            <span>Course Developer:</span>{props.description.developer}
                        </li>  
                        <li>
                            <FcPlanner className="bullet-point-icon" />
                            <span>Development Date:</span>{props.description.date}
                        </li>
                    </IconContext.Provider>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default CourseDescription;
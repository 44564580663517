import { useCollapse } from 'react-collapsed'

export default function CollapsText2({ title, content }) {
    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse(
        {
            collapsedHeight: 130,
        }
    )
    const buttonStyle = {
        fontWeight: 500,
        color: "#B8860B",
        backgroundColor: "transparent",
        border: "none",

    };

    return (
        <div className='col-box'>
            <h3>{title}</h3>
            <section {...getCollapseProps()}><p>{content.map(
                (item, index) => (
                    <div className={index} dangerouslySetInnerHTML={{ __html: item }} />
                )
            )}</p></section>
            <button className='toggle-button' style={buttonStyle} {...getToggleProps()}>
                {isExpanded ? 'Read Less' : 'Read More'}
            </button>
        </div>
    )
}
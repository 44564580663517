import "../../styles/PageStyle/Admission.css";
import { FaRegHandPointRight } from "react-icons/fa";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import Divider from "../../components/ToolComp/divider";
export default function Admission() {
    return (
        <div className="admission main-section">
            <div className="grid-container-two-equal-col section">
                <div className="col1">
                    <h2>Welcome</h2>
                    <Divider />
                    <p>We’re delighted that you’re interested in applying to study at Emerald Valley Academy! Follow these easy steps to apply online. One of our Admission Officers will contact you to finalize your application.
                    </p>
                </div>
                <div className="col2">
                    <h2>Before Begin</h2>
                    <Divider />
                    <p>Please note that all fields are required for you to apply successfully. If you have questions or are experiencing technical difficulties, please send us an email:<a href="mailto: admissions@evaschool.ca">admissions@evaschool.ca</a> .
                    </p>
                </div>
            </div>
            <div className="img-wrapper">
                <img src="/img/5.jpg" />
            </div>
            <div className="apply section">
                <h4 style={{ color: "#6B8E23", fontWeight: "bold" }}>Application procedures for Domestic and International Students in Canada (with a valid study permit)
                </h4>
                <ul>
                    <li style={{ listStyleType: "decimal" }}>
                        <p>
                            <span style={{ fontWeight: "bold" }}>
                                Fill out Emerald Valley Academy 's
                            </span>
                            <span style={{ margin: "4px 4px" }}>
                                registration form
                            </span>
                            <span style={{ fontWeight: "bold" }}>
                                and submit it via fax <a href="tel:+4372686158">437-268-6158</a>, email to&nbsp; 
                                <a href="mailto:admissions@evaschool.ca">
                                    admissions@evaschool.ca
                                </a> or mail to address:<a href="geo:latitude,longitude?q=Yonge+Street+%2C+Toronto%2C+Ontario">4750 Yonge St. Toronto, Ontario Canada M2N 5M6</a></span>
                        </p>

                    </li>
                    <li style={{ listStyleType: "decimal" }}>
                        <span style={{ fontWeight: "bold" }}>
                            Your application will not be processed until we receive all required documentation listed below:
                        </span>
                        <ul>
                            <li style={{ listStyleType: "lower-alpha" }}>
                                Photocopies of Personal Identification: This should encompass the student's birth certificate, a complete passport copy, student ID, or health card.
                            </li>
                            <li style={{ listStyleType: "lower-alpha" }}>
                                Photocopies of the most recent academic records, as well as those from the previous two years. If not in English, kindly provide a Notarized Translation.
                            </li>
                            <li style={{ listStyleType: "lower-alpha" }}>
                                For students applying to Grades 10-12, please include a transcript and Grade 10 Literacy Test Results.
                            </li>
                        </ul>
                    </li>
                    <li style={{ listStyleType: "decimal" }}>
                        Domestic Students are exempt from Application Fees, and successful applicants will receive a pre-approval school offer.
                    </li>
                    <li style={{ listStyleType: "decimal" }}>
                        Submit the tuition fee.
                    </li>
                    <li style={{ listStyleType: "decimal" }}>
                        Successful applicants will receive an official Letter of Acceptance and a Receipt confirming Paid Tuition.
                    </li>
                </ul>
                <div className="international-students">
                    <p>
                        For <span style={{ fontWeight: "bold" }}>
                            international students in Canada
                        </span> holding a valid study permit, please ensure you provide the following additional documents:
                    </p>
                    <ul>
                        <li>
                            <IoMdCheckmarkCircleOutline className="bullet-point-icon" /> Immunization Record: This serves as proof of vaccination against diseases such as tuberculosis, mumps, measles, rubella, diphtheria, tetanus, and polio. If these records are not in English, kindly include a Notarized Translation.
                        </li>
                        <li>
                            <IoMdCheckmarkCircleOutline className="bullet-point-icon" /> Legal Guardianship Papers: If you are under 18 years of age, please furnish us with the necessary legal guardianship documentation.
                        </li>
                        <li>
                            <IoMdCheckmarkCircleOutline className="bullet-point-icon" /> Mandatory Private Health Insurance: It is imperative to have comprehensive medical insurance coverage for the entire duration of your study period. You can obtain this insurance through Emerald Valley Academy.
                        </li>
                    </ul>
                </div>
                <div className="admission-require-international">
                    <h4 style={{ color: "#6B8E23", fontWeight: "bold" }}>
                        Admission Requirements For international students outside Canada
                    </h4>
                    <p>
                        International students outside Canada are advised to apply as early as possible so that they have sufficient time to obtain a student visa form at the nearest Canadian Embassy or Canadian Consulate General in their home country.
                    </p>
                    <p>
                        Please provide the following documents:
                    </p>
                    <ul>
                        <li>
                            <FaRegHandPointRight className="bullet-point-icon" /> Completed and signed <span style={{ fontWeight: "bold" }}>Application Form </span>by both student and parents.
                        </li>
                        <li>
                            <FaRegHandPointRight className="bullet-point-icon" /> Academic records, transcripts, or school documents from the student's previous country for the past two years. If these documents are not in English, please include a Notarized Translation.
                        </li>
                        <li>
                            <FaRegHandPointRight className="bullet-point-icon" /> Copy of Passport.
                        </li>
                        <li>
                            <FaRegHandPointRight className="bullet-point-icon" /> Legal Custodian Papers (Required for applicants under 18 years of age).
                        </li>
                        <li>
                            <FaRegHandPointRight className="bullet-point-icon" /> Immunization Record (If not in English, please provide a Notarized Translation).
                        </li>
                        <li>
                            <FaRegHandPointRight className="bullet-point-icon" /> CDN $300 non-refundable application processing fee (Accepted payment methods: Money Order, Cash, or Cheque).
                        </li>
                    </ul>
                    <p>
                        <span className="note">Please Note:</span> Acceptance is contingent upon the student's educational background and current academic standing. EVA will thoroughly review the application package. If admission is approved, the school will issue an acceptance letter along with additional information.
                    </p>
                </div>

            </div>

        </div>
    )
}
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Times New Roman', Times, serif;
    .title,p,li{
      color: rgb(132, 129, 129);
    };
    button{
    background: transparent;
    opacity:0.7;
    border-radius: 15px;
    border-width: 1px;
    outline: none;
    cursor: pointer;
    font-size: large;
    font-weight:500;
    padding: 15px 30px;
    height:auto;
    width:max-content;
    word-wrap:break-word;
    margin:0.5rem;
    };
    .color-background h2,.color-background h3,.color-background p,.color-background li{
      color:white;
    };
  .color-background {
      background-color: #063970;
      padding:1rem 2rem 1rem 1rem;
  };
}
  }
`;

export default GlobalStyle;
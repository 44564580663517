import Assessment from "../components/CourseComp/Assessment";
import CourseDescription from "../components/CourseComp/CourseDescription";
import Expectations from "../components/CourseComp/Expectation";
import GradeFinalGrade from "../components/GradeCourseComp/GradeFinalGrade";
import GradePlan from "../components/GradeCourseComp/GradePlan";
import Outline from "../components/CourseComp/Outline";
import Strategies from "../components/CourseComp/Strategies";

export default function GradeCourseTemplate(props) {
    return (
        <div className="grade-course course main-section">
            <div className="course-container">
                <CourseDescription description={props.data.description} />
                <Outline outline={props.data.outline} />
                <Expectations expectations={props.data.expectations} />
                <Assessment assessment={props.data.assessment} />
                <Strategies strategy={props.data.strategy} />
                <GradeFinalGrade />
                <GradePlan />
            </div>
        </div>
    )
}
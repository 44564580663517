import Divider from "../ToolComp/divider";

export default function GradeFinalGrade() {
    return (
        <div className="grade section">
            <h2>The Final Grade</h2>
            <Divider />
            <div className="table">
                <table>
                    <thead>
                        <tr>
                            <th>Percentage of Final Mark</th>
                            <th>Categories of Mark Breakdown</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td data-label="Percentage"> 70% </td>
                            <td data-label="Categories"> Assessments of Learning Tasks Throughout the Term </td>
                        </tr>
                        <tr>
                            <td data-label="Percentage"> 30% </td>
                            <td data-label="Categories">Final Written Examination And/Or RST</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <p>A student’s final grade is reflective of their most recent and most consistent level of achievement.</p>
            <p>The balance of the weighting of the categories of the achievement chart throughout the course is:</p>
            <div className="table">
                <table>
                    <thead>
                        <tr>
                            <th>Total</th>
                            <th>Knowledge</th>
                            <th>Inquiry</th>
                            <th>Communication</th>
                            <th>Application</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td data-label="Total">100%</td>
                            <td data-label="Knowledge">25%</td>
                            <td data-label="Inquiry">25%</td>
                            <td data-label="Communication">25%</td>
                            <td data-label="Application">25%</td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
    )
}
import React, { useState, useEffect } from 'react';
import "../../styles/CompStyle/PhotoGallery.css"

const images = [
    'EVA/img1.jpg',
'EVA/img2.png',
'EVA/img49.jpeg',
'EVA/img4.jpg',
'EVA/img5.jpg',
'EVA/img9.jpg',
'EVA/img10.jpg',
'EVA/img15.jpg',
'EVA/img21.jpg',
'EVA/img24.jpg',
'EVA/img35.jpg',
'EVA/img40.png',
'EVA/img41.png',
'EVA/img42.png',    
    // Add more images as needed
];

function ImageGallery() {
    const [currentImage, setCurrentImage] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentImage(currentImage => (currentImage + 1) % images.length);
        }, 5000); // Change image every 5 seconds

        return () => clearInterval(intervalId); // Clean up the interval on component unmount
    }, []);

    const handleNextClick = () => {
        setCurrentImage((currentImage + 1) % images.length); // Move to the next image
    };

    const handlePrevClick = () => {
        setCurrentImage((currentImage + images.length - 1) % images.length); // Move to the previous image
    };

    // const handleDotClick = index => {
    //     setCurrentImage(index);
    // };

    return (
        <div className="image-gallery" >
            <div className='gallery-container'>
                <button onClick={handlePrevClick}>&lt;</button>
                <img
                    src={images[currentImage]}
                    alt="Gallery"
                />
                <button onClick={handleNextClick}>&gt;</button>
            </div>

            {/* <div className="dots">
                {images.map((_, index) => (
                    <span
                        key={index}
                        onClick={() => handleDotClick(index)}
                    />
                ))}
            </div> */}
        </div>
    );
}

export default ImageGallery;

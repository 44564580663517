import { SPH3U } from "../../data/OSSD/Grade11/SPH3U";
import GradeCourseTemplate from "../../pageTemplate/GradeCourseTemplate";


export default function SPH3UPage() {
    return (
        <div>
            <GradeCourseTemplate data={SPH3U} />
        </div>
    )
}
import GradeCourseOverviewComp from "../GradeCourseOverviewComp";
import Title from "../ToolComp/title";
import "../../styles/Grade.css";

export default function Grade(props) {

    return (
        <div className="grade">
            <Title title={props.data.title} />
            <div className="grade-grid-wrapper">
                {
                    props.data.courses.map(
                        (item, index) => (
                            <div className={`grid-item ${index}`}>
                                <GradeCourseOverviewComp course={item} />
                            </div>
                        )
                    )
                }
            </div>
        </div>
    )

}
import { FSF1D } from "../../data/OSSD/Grade9/FSF1D";
import GradeCourseTemplate from "../../pageTemplate/GradeCourseTemplate";


export default function FSF1DPage() {
    return (
        <div>
            <GradeCourseTemplate data={FSF1D} />
        </div>
    )
}
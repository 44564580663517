import { useState } from "react";
import { FcCollapse } from "react-icons/fc";
export default function TogglePanel(props) {
    const [isOpen, setIsOpen] = useState(false);

    const togglePanel = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div>
            <div>
                <FcCollapse className="bullet-point-icon" />
                <button className="togglebutton" onClick={togglePanel}>{props.title}</button>
            </div>

            <div className="line2"></div>
            {isOpen && (
                <div>
                    <p style={{ paddingLeft: "2rem" }}>{props.content}</p>
                </div>
            )}
        </div>
    )
}
export const MDM4U = {
    title: "Data Management",
    description: {
        code: "MDM4U",
        department: "Mathematics",
        grade: "12",
        type: "University",
        credit: "1.0",
        prerequisite: "MCR3U, Functions, Grade 11, University or MCF3M, Functions and Applications, Grade 11, University/College",
        document: "Mathematics, The Ontario Curriculum, Grades 11 and 12, 2007",
        link: "http://www.edu.gov.on.ca/eng/curriculum/secondary/math1112currb.pdf",
        developer: "Emerald Valley Academy",
        date: 2021,
        content: "This course broadens students’ understanding of mathematics as it relates to managing data.Students will use methods for organizing and analyzing large amounts of information; they will use counting techniques, probability, and statistics in modeling and problem solving; and they will conduct a data management investigation that integrates the course expectations and encourages perseverance and independence. This course will be of particular interest to students planning to pursue university programs in business, social sciences, or the humanities.",
    },
    outline: {
        title: "Data Management MDM4U - Total Hours (110 Hours)",
        units: [
            {
                title: "Unit One: Counting and Probability",
                hour: "26 Hours",
                content: "In this unit, students will be introduced to probability vocabulary and notation in contexts involving simple counting; counting permutations and combinations; using mathematical notation to describe the number of permutations and combinations; solving counting problems and solving probability problems using counting principles.",
            },
            {
                title: "Unit Two:  Probability Distributions Introduction",
                hour: "26 Hours",
                contetn: "In this unit, students will understand probability distributions for discrete random variables. Explore and connect Binomial and Hypergeometric distributions. Recognize that the differences between a probability histogram and a frequency histogram may be the result of variability.",
            },
            {
                title: " Unit Three: Introduction to Organization of Data Analysis",
                hour: "26 Hours",
                content: "In this unit, organization of data for analysis is tackled to demonstrate an understanding of the role of data in statistical studies. Describe the characteristics of a good sample and compare sampling techniques .Design an effective survey and collect data. Understand how data is organized. Find sources of data, refine topic of interest and design a plan in preparation for the Culminating Investigation",
            },
            {
                title: " Unit Four : Statistical Analysis ",
                hour: "26 Hours",
                content: "Explore, analyse, interpret, and draw conclusions from one-variable data. Explore, analyse, interpret, and draw conclusions from two-variable data. Investigate and evaluate validity of statistical summaries. Culminating Investigation. Analyse, interpret, draw conclusions and write a report of their research. Present summary of findings. Critique presentations of their peers.",
            },
            {
                title: "Unit Five: Revolutions in Modern Physics: Quantum Mechanics and Special Relativity",
                hour: "22 Hours",
                content: "In this unit, some of the most exciting and counterintuitive concepts in physics, including Einstein’s ideas about relativity, photoelectric effect, and particle physics, will be examined. Quantum mechanics and special relativity will be investigated mathematically and related problems will be solved. In light of the revolutionary ideas studied in this unit, students will discuss how the introduction of new conceptual models can influence and change scientific thought, and lead to the development of new technologies.",
            },
            {
                title: " Rich Summative Task",
                hour: "4 Hours",
                content: "RST – Culminating Investigation",
            },
        ],
        note1: "This course is entirely online and does not require or rely on any textbook.",
        note2: "A scanner, smartphone camera, or similar device to upload handwritten or hand-drawn work",
    },
    expectations: {
        title: "MDM4U Mathematics of Data Management, Grade 12 University Preparation",
        content: [
            {
                title: "Counting And Probability",
                overall: [
                    "solve problems involving the probability of an event or a combination of events for discrete sample spaces;",
                    "solve problems involving the application of permutations and combinations to determine the probability of an event.",
                ]
            },
            {
                title: "Probability Distribution",
                overall: [
                    "demonstrate an understanding of discrete probability distributions, represent them numerically, graphically, and algebraically,determine expected values, and solve related problems from a variety of applications;",
                    "demonstrate an understanding of continuous probability distributions, make connections to discrete probability distributions, determine standard deviations, describe key features of the normal distribution, and solve related problems from a variety of applications.",
                ]
            },
            {
                title: "Organization Of Data For Analysis",
                overall: [
                    "demonstrate an understanding of the role of data in statistical studies and the variability inherent in data, and distinguish different types of data;",
                    "describe the characteristics of a good sample, some sampling techniques, and principles of primary data collection, and collect and organize data to solve a problem.",
                ]
            },
            {
                title: "Statistical Analysis",
                overall: [
                    "analyse, interpret, and draw conclusions from one-variable data using numerical and graphical summaries;",
                    "analyse, interpret, and draw conclusions from two-variable data using numerical, graphical, and algebraic summaries;",
                    "demonstrate an understanding of the applications of data management used by the media and the advertising industry and in various occupations."
                ]
            },
            {
                title: "Culminating Data Management Investigation",
                overall: [
                    "design and carry out a culminating investigation* that requires the integration and application of the knowledge and skills related to the expectations of this course;",
                    "communicate the findings of a culminating investigation and provide constructive critiques of the investigations of others.",
                ]
            },
        ]
    },
    assessment: {
        content: "There are three forms of assessment that will be used throughout this course:",
        tab: [
            {
                title: "Assessment for Learning: ",
                p: "Assessment for learning will directly influence student learning by reinforcing the connections between assessment and instruction, and provide ongoing feedback to the student. Assessment for learning occurs as part of the daily teaching process and helps teachers form a clear picture of the needs of the students because students are encouraged to be more active in their learning and associated assessment. Teachers gather this information to shape their teaching environment.",
                content: [
                    "Ongoing",
                    "Is tied to learning outcomes",
                    "Provides information that structures the teachers’ planning and instruction",
                    "Allows teachers to provide immediate and descriptive feedback that will guide student learning",
                ],
                p2: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
            },
            {
                title: "Assessment as Learning: ",
                p: "Assessment as learning is the use of a task or an activity to allow students the opportunity to use assessment to further their own learning. Self and peer assessments allow students to reflect on their own learning and identify areas of strength and need. These tasks offer students the chance to set their own personal goals and advocate for their own learning.",
                content: [],
                p2: "The purpose of assessment as learning is to enable students to monitor their own progress towards achieving their learning goals.",
            },
            {
                title: "Assessment of Learning: ",
                p: "Assessment of learning will occur at or near the end of a period of learning; this summary is used to make judgements about the quality of student learning using established criteria, to assign a value to represent that quality and to communicate information about achievement to students and parents.",
                content: [],
                p2: "Evidence of student achievement for evaluation is collected over time from three different sources - observations, conversations, and student products. Using multiple sources of evidence will increase the reliability and validity of the evaluation of student learning.",
            },
        ],
        p1: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
    },
    strategy: {
        p1: "The overall goal of this course is to help students use mathematical language skillfully, confidently, and flexibly. A wide range of instructional strategies are used to provide learning opportunities to accommodate a wide range of learning styles, interests, and ability levels.The following mathematical processes are used throughout the course as strategies for teaching and learning the concepts presented:",
        lst: [
            "Communicating: This course provides students with several opportunities to convey their knowledge, both orally and in writing. Students will use discussion boards to discuss what they’ve learned, produce reports that tie what they’ve studied to real-world applications, and make presentations to demonstrate comprehension of some ideas.",
            "Problem solving: By building on existing knowledge and abilities, this course scaffolds student learning. Students will have the opportunity to study earlier ideas and will be confronted with challenges that will require them to apply their skills in creative ways to solve problems in real-world situations.",
            "Reflecting: The reflective process is modeled in this course. The course shows proper communication using some examples and practice exercises to explain intermediate phases and reflect on solutions to see if they make sense in the given context.",
            "Selecting Tools and Computational Strategies: This course demonstrates how to utilize graphing tools to solve problems and introduce students to technologies that can make problem solving easier and more precise.",
            "Connecting: Students will apply the concepts learned in class to real-world situations. Through posed puzzles, investigations, and enrichment activities, students will be able to connect past concepts to new topics.",
            "Self-Assessment: Students receive immediate feedback and can self-assess their grasp of ideas through the use of interactive activities (e.g. multiple choice quizzes, hangman, puzzle activities).",
        ]
    },
    grade: {
        table1: {
            percentage: [
                "70%",
                "30%",
            ],
            category: [
                "Assessments of Learning Tasks Throughout the Term",
                "Final Written Examination And/Or RST",
            ],
        },
        p1: "A student’s final grade is reflective of their most recent and most consistent level of achievement.",
        p2: "The balance of the weighting of the categories of the achievement chart throughout the course is:",
        table2: {
            MATHEMATICS_OF_DATA_MANAGEMENT: "100%",
            knowledge: "25%",
            inquiry: "25%",
            communication: "25%",
            applicatoin: "25%",
        },
    },
    report: {
        p1: "Student achievement will be communicated formally to students via an official report card. Report cards are issued at the midterm point in the course, as well as upon completion of the course. Each report card will focus on two distinct, but related aspects of student achievement. ",
        p2: "First, the achievement of curriculum expectations is reported as a percentage grade. Additionally, the course median is reported as a percentage. The teacher will also provide written comments concerning the student’s strengths, areas for improvement, and next steps. Second, the learning skills are reported as a letter grade, representing one of four levels of accomplishment. The report card also indicates whether an OSSD credit has been earned. ",
        p3: "Upon completion of a course, EVA will send a copy of the report card back to the student’s home school (if in Ontario) where the course will be added to the ongoing list of courses on the student’s Ontario Student Transcript. The report card will also be sent to the student’s home address.",
    },
}
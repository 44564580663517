export const PPL1O = {
    title: "Healthy Active Living Education",
    description: {
        code: "PPL1O",
        department: "Health",
        type: "Open",
        grade: "9",
        credit: "1.0",
        prerequisite: "None",
        document: "Health and Physical Education, The Ontario Curriculum, Grades 9 to 12, 2015",
        link: "http://www.edu.gov.on.ca/eng/curriculum/secondary/health9to12.pdf",
        developer: "Emerald Valley Academy",
        date: 2021,
        content: "This course equips students with the knowledge and skills they need to make healthy choices now and lead healthy, active lives in the future. Through participation in a wide range of physical activities, students develop knowledge and skills related to movement competence and personal fitness that provide a foundation for active living. Students also acquire an understanding of the factors and skills that contribute to healthy development and learn how their own well-being is affected by, and affects, the world around them. Students build their sense of self, learn to interact positively with others, and develop their ability to think critically and creatively.",
    },
    outline: {
        title: "PPL1O, Grade 9 Healthy Active Living Education",
        units: [
            {
                title: "Unit One",
                hour: "22 Hours",
                content: "In this unit, students will explain how active living and healthy eating contribute to a person's physical health, mental, emotional, and spiritual well-being, and describe the benefits of a holistic approach to health. They will also apply their knowledge of basic nutrition principles and healthy eating practices to develop a one-day healthy eating plan and analyze the impact of exercise on nutritional requirements.",
            },
            {
                title: "Unit Two",
                hour: "20 Hours",
                contetn: "In this unit, students will demonstrate an understanding of the benefits and risks of using electronic communication technologies on teenagers' mental and emotional health, as well as strategies they can use to ensure their safety online while using these technologies. They will also identify warning signs and symptoms that could be related to mental health concerns, and describe a variety of strategies for coping with or improving mental health concerns affecting themselves and communicate your knowledge to a variety of audiences",
            },
            {
                title: "Unit Three",
                hour: "19 Hours",
                content: "In this unit, students will describe social variables that may drive youth to try substances like cannabis, alcohol, or vaping, as well as behaviors that lead to addictions, and explain how to utilize decision-making and communication skills to avoid using cannabis, alcohol, or vaping. Explain how social factors and peer pressure can lead to youngsters trying new substances or engaging in behaviors that lead to addiction and dangerous circumstances, and how decision-making and communication skills can be utilized to effectively respond to these influences.",
            },
            {
                title: "Unit Four",
                hour: "20 Hours",
                content: "In this unit, students will identify sources of information and assistance for students and young people by describing and analyzing various strategies of preventing unexpected pregnancy or sexually transmitted infections, such as HIV/AIDS. Demonstrate an awareness of the elements that can influence a person's understanding of their own sexual and gender identity, as well as resources for students. Demonstrate knowledge of the skills and methods required to develop healthy peer and intimate relationships. Apply a thorough awareness of sexual consent and sexual limitations, as well as techniques for keeping themselves sexually safe and decision-making abilities.",
            },
            {
                title: "Rich Summative Task",
                hour: "2 Hours",
                content: "The final assessment task is a proctored two hour exam worth 30% of the student’s final mark.",
            },
        ],
        note1: "This course is entirely online and does not require or rely on any textbook.",
        note2: "A scanner, smartphone camera, or similar device to upload handwritten or hand-drawn work",

    },
    expectations: {
        title: "PPL1O,Grade 9, Healthy Active Living Education",
        content: [
            {
                title: "Active Living",
                overall: [
                    "participate actively and regularly in a wide variety of physical activities, and demonstrate an understanding of factors that can influence and support their participation in physical activity now and throughout their lives; ",
                    "demonstrate an understanding of the importance of being physically active, and apply physical fitness concepts and practices that contribute to healthy, active living; ",
                    "demonstrate responsibility for their own safety and the safety of others as they participate in physical activities",
                ]
            },
            {
                title: "Movement Competence: Skills, Concepts, And Strategies",
                overall: [
                    "perform movement skills, demonstrating an understanding of the basic requirements of the skills and applying movement concepts as appropriate, as they engage in a variety of physical activities; ",
                    "apply movement strategies appropriately, demonstrating an understanding of the components of a variety of phy",
                ]
            },
            {
                title: "Healthy Living",
                overall: [
                    "demonstrate an understanding of factors that contribute to healthy development; ",
                    "demonstrate the ability to apply health knowledge and living skills to make reasoned decisions and take appropriate actions relating to their personal health and well-being; ",
                    "demonstrate the ability to make connections that relate to health and well-being – how their choices and behaviours affect both themselves and others, and how factors in the world around them affect their own and others’ health and well-being.",
                ]
            }
        ]
    },
    assessment: {
        content: "There are three forms of assessment that will be used throughout this course:",
        tab: [
            {
                title: "Assessment for Learning: ",
                p: "Assessment for learning will directly influence student learning by reinforcing the connections between assessment and instruction, and provide ongoing feedback to the student. Assessment for learning occurs as part of the daily teaching process and helps teachers form a clear picture of the needs of the students because students are encouraged to be more active in their learning and associated assessment. Teachers gather this information to shape their teaching environment.",
                content: [
                    "Ongoing",
                    "Is tied to learning outcomes",
                    "Provides information that structures the teachers’ planning and instruction",
                    "Allows teachers to provide immediate and descriptive feedback that will guide student learning",
                ],
                p2: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
            },
            {
                title: "Assessment as Learning: ",
                p: "Assessment as learning is the use of a task or an activity to allow students the opportunity to use assessment to further their own learning. Self and peer assessments allow students to reflect on their own learning and identify areas of strength and need. These tasks offer students the chance to set their own personal goals and advocate for their own learning.",
                content: [],
                p2: "The purpose of assessment as learning is to enable students to monitor their own progress towards achieving their learning goals.",
            },
            {
                title: "Assessment of Learning: ",
                p: "Assessment of learning will occur at or near the end of a period of learning; this summary is used to make judgements about the quality of student learning using established criteria, to assign a value to represent that quality and to communicate information about achievement to students and parents.",
                content: [],
                p2: "Evidence of student achievement for evaluation is collected over time from three different sources - observations, conversations, and student products. Using multiple sources of evidence will increase the reliability and validity of the evaluation of student learning.",
            },
        ],
        p1: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
    },
    strategy: {
        p1: "Using a variety of instructional strategies, the teacher will provide numerous opportunities for students to develop skills of inquiry, problem solving, and communication as they investigate and learn fundamental concepts. The integration of critical thinking and critical inquiry skills will provide a powerful tool for reasoning and problem solving, and is reflected in a meaningful blend of both process and content. ",
        p2: "",
        lst: [
            "Differentiated Learning and Assessment: This course allows students the flexibility to not only work through the course in the order they are most comfortable with, but also lets them select options for assessments that best fit their respective skill sets and comfort levels. The goal is to allow the student to demonstrate their understanding and ability through a variety of assessment opportunities.",
            "Communicating – Students will communicate their learning in a variety of ways.",
            "Technology and Media Strategies: Activity-specific skills and strategies are supported by video instruction. This allows students to see the movements prior to participation.",
            "Thinking Skills Strategies: Through the use of case studies and self-reflection, students will learn to think creatively and critically about issues involving healthy and active living.",
        ],
    },
    grade: {
        table1: {
            percentage: [
                "70%",
                "30%",
            ],
            category: [
                "Assessments of Learning Tasks Throughout the Term",
                "Final Written Examination And/Or RST",
            ],
        },
        p1: "A student’s final grade is reflective of their most recent and most consistent level of achievement.",
        p2: "The balance of the weighting of the categories of the achievement chart throughout the course is:",
        table2: {
            Healthy_Active_Living_Education: "100%",
            knowledge: "25%",
            inquiry: "25%",
            communication: "25%",
            applicatoin: "25%",
        },
    },
    report: {
        p1: "Student achievement will be communicated formally to students via an official report card. Report cards are issued at the midterm point in the course, as well as upon completion of the course. Each report card will focus on two distinct, but related aspects of student achievement. ",
        p2: "First, the achievement of curriculum expectations is reported as a percentage grade. Additionally, the course median is reported as a percentage. The teacher will also provide written comments concerning the student’s strengths, areas for improvement, and next steps. Second, the learning skills are reported as a letter grade, representing one of four levels of accomplishment. The report card also indicates whether an OSSD credit has been earned. ",
        p3: "Upon completion of a course, EVA will send a copy of the report card back to the student’s home school (if in Ontario) where the course will be added to the ongoing list of courses on the student’s Ontario Student Transcript. The report card will also be sent to the student’s home address.",
    },

}
export const MHF4U = {
    title: "Advanced Functions",
    description: {
        code: "MHF4U",
        department: "Mathematics",
        grade: "12",
        credit: "1.0",
        prerequisite: "MCR3U, Functions, Grade 11, University or MCT4C, Mathematics for College Technology, Grade 12, College",
        document: "Mathematics, The Ontario Curriculum, Grades 11 and 12, 2007",
        link: "http://www.edu.gov.on.ca/eng/curriculum/secondary/math1112currb.pdf",
        developer: "Emerald Valley Academy",
        date: 2021,
        content: "This course broadens students’ knowledge of functions. Students will examine the properties of polynomial, rational, logarithmic, and trigonometric functions, as well as create ways for combining them, increase their understanding of rates of change, and improve their ability to apply these concepts and skills. Students will also improve their understanding of the mathematical procedures required for senior mathematics success. This course is designed for students who are taking Calculus and Vectors as a prerequisite for a university degree, as well as those who want to brush up on their math skills before enrolling in one of the many university programs.",
    },
    outline: {
        title: "Advanced Functions MHF4U - Total Hours (110 Hours)",
        units: [
            {
                title: "Unit One: Characteristics of Functions",
                hour: "26 Hours",
                content: "In this unit, students will recall a few basic facts of a distance time graph. They will review transformations and their effects on a given graph. The concepts of function notation, relation, range, domain, and function notation will all be reviewed. Finally, strategies will be employed to aid in the understanding of rates of change.",
            },
            {
                title: " Unit Two: Polynomial and Rational Functions",
                hour: "27 Hours",
                content: "In this unit the students will investigate polynomial and rational functions. They will extend their knowledge about linear and quadratic functions to include cubic, quartic and quintic functions. Students will explore their graphs and characteristics, also distinguish polynomial functions from sinusoidal and exponential functions, and compare and contrast the graphs of various polynomial functions with the graphs of other types of functions. Students will also determine, through investigation with and without technology, key features (i.e., vertical and horizontal asymptotes, domain and range, intercepts, positive/negative intervals, increasing/decreasing intervals) of the graphs of rational functions that are the reciprocals of linear and quadratic functions, and make connections between the algebraic and graphical representations of these rational functions. Students will solve problems involving applications of simple rational functions and equations and explain the difference between the solution to an equation in one variable and the solution to an inequality in one variable, also demonstrate that given solutions satisfy an inequality and determine solutions to simple rational inequalities in one variable by graphing the corresponding functions, using graphing technology, and identifying intervals for which x satisfies the inequalities.",
            },
            {
                title: "Unit Three: Trigonometric Functions",
                hour: "27 Hours",
                content: "In this unit, students recognize the radian as an alternative unit to the degree for angle measurement, define the radian measure of an angle as the length of the arc that subtends this angle at the centre of a unit circle, and develop and apply the relationship between radian and degree measure. Students will also sketch the graphs of f(x) = sinx and f(x) = cosx for angle measures expressed in radians, and determine and describe some key properties (e.g., period of 2 π , amplitude of 1) in terms of radians. Students represent a sinusoidal function with an equation, given its graph or its properties, with angles expressed in radians. Students recognize that trigonometric identities are equations that are true for every value in the domain, prove trigonometric identities through the application of reasoning skills, using a variety of relationships, and verify identities using technology.",
            },
            {
                title: " Unit Four : Exponential and Logarithmic Functions",
                hour: "27 Hours",
                content: "In this unit, students make connections between related logarithmic and exponential equations and between the laws of exponents and the laws of logarithms, verify the laws of logarithms with or without technology, and use the laws of logarithms to simplify and evaluate numerical expressions. Students will also solve problems involving exponential and logarithmic equations algebraically, including problems arising from real-world applications.",
            },
            {
                title: " Final Exam",
                hour: "3 Hours",
                content: "The final assessment task is a proctored three hours exam worth 30% of the student’s final mark.",
            },
        ],
        note1: "This course is entirely online and does not require or rely on any textbook.",
    },
    expectations: {
        title: "MHF4U Advanced Functions, Grade 12, University Preparation",
        content: [
            {
                title: "Exponential And Logarithmic Functions",
                overall: [
                    "demonstrate an understanding of the relationship between exponential expressions and logarithmic expressions,evaluate logarithms, and apply the laws of logarithms to simplify numeric expressions;",
                    "identify and describe some key features of the graphs of logarithmic functions, make connections among the numeric, graphical, and algebraic representations of logarithmic functions, and solve related problems graphically;",
                    "solve exponential and simple logarithmic equations in one variable algebraically, including those in problems arising from real-world applications.",
                ]
            },
            {
                title: "Trigonometric Functions",
                overall: [
                    "Demonstrate an understanding of the meaning and application of radian measure",
                    "make connections between trigonometric ratios and the graphical and algebraic representations of the corresponding trigonometric functions and between trigonometric functions and their reciprocals, and use these connections to solve problems",
                    "solve problems involving trigonometric equations and prove trigonometric identities.",
                ]
            },
            {
                title: "Polynomial And Rational Functions",
                overall: [
                    "identify and describe some key features of polynomial functions, and make connections between the numeric, graphical, and algebraic representations of polynomial functions;",
                    "identify and describe some key features of the graphs of rational functions, and represent rational functions graphically;",
                    "solve problems involving polynomial and simple rational equations graphically and algebraically;",
                    "Demonstrate an understanding of solving polynomial and simple rational inequalities.",
                ],
            },
            {
                title: "Characteristics of Functions",
                overall: [
                    "demonstrate an understanding of average and instantaneous rate of change, and determine, numerically and graphically, and interpret the average rate of change of a function over a given interval and the instantaneous rate of change of a function at a given point;",
                    "determine functions that result from the addition, subtraction, multiplication, and division of two functions and from the composition of two functions, describe some properties of the resulting functions, and solve related problems;",
                    "compare the characteristics of functions, and solve problems by modelling and reasoning with functions, including problems with solutions that are not accessible by standard algebraic techniques",
                ]
            },
        ]
    },
    assessment: {
        content: "There are three forms of assessment that will be used throughout this course:",
        tab: [
            {
                title: "Assessment for Learning: ",
                p: "Assessment for learning will directly influence student learning by reinforcing the connections between assessment and instruction, and provide ongoing feedback to the student. Assessment for learning occurs as part of the daily teaching process and helps teachers form a clear picture of the needs of the students because students are encouraged to be more active in their learning and associated assessment. Teachers gather this information to shape their teaching environment.",
                content: [
                    "Ongoing",
                    "Is tied to learning outcomes",
                    "Provides information that structures the teachers’ planning and instruction",
                    "Allows teachers to provide immediate and descriptive feedback that will guide student learning",
                ],
                p2: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
            },
            {
                title: "Assessment as Learning: ",
                p: "Assessment as learning is the use of a task or an activity to allow students the opportunity to use assessment to further their own learning. Self and peer assessments allow students to reflect on their own learning and identify areas of strength and need. These tasks offer students the chance to set their own personal goals and advocate for their own learning.",
                content: [],
                p2: "The purpose of assessment as learning is to enable students to monitor their own progress towards achieving their learning goals.",
            },
            {
                title: "Assessment of Learning: ",
                p: "Assessment of learning will occur at or near the end of a period of learning; this summary is used to make judgements about the quality of student learning using established criteria, to assign a value to represent that quality and to communicate information about achievement to students and parents.",
                content: [],
                p2: "Evidence of student achievement for evaluation is collected over time from three different sources - observations, conversations, and student products. Using multiple sources of evidence will increase the reliability and validity of the evaluation of student learning.",
            },
        ],
        p1: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
    },
    strategy: {
        p1: "This course’s overarching goal is to enable students to use mathematics’ language competently, confidently, and flexibly. A number of instructional methodologies are used to give learning opportunities to meet a variety of learning styles, interests, and ability levels. The teaching and learning methodologies will be built on the following mathematical processes:",
        lst: [
            "Communicating: This course provides students with several opportunities to convey their knowledge, both orally and in writing. Students will use discussion boards to discuss what they’ve learned, produce reports that tie what they’ve studied to real-world applications, and make presentations to demonstrate comprehension of some ideas.",
            "Problem solving: By building on existing knowledge and abilities, this course scaffolds student learning. Students will have the opportunity to study earlier ideas and will be confronted with challenges that will require them to apply their skills in creative ways to solve problems in real-world situations.",
            "Reflecting: The reflective process is modeled in this course. The course shows proper communication using some examples and practice exercises to explain intermediate phases and reflect on solutions to see if they make sense in the given context.",
            "Selecting Tools and Computational Strategies: This course demonstrates how to utilize graphing tools to solve problems and introduce students to technologies that can make problem solving easier and more precise.",
            "Connecting: Students will apply the concepts learned in class to real-world situations. Through posed puzzles, investigations, and enrichment activities, students will be able to connect past concepts to new topics.",
            "Self-Assessment: Students receive immediate feedback and can self-assess their grasp of ideas through the use of interactive activities (e.g. multiple choice quizzes, hangman, puzzle activities).",
            "Feedback: Through constant communication with the instructor, timely feedback and encouragement was given.",
        ],
    },
    grade: {
        table1: {
            percentage: [
                "70%",
                "30%",
            ],
            category: [
                "Assessments of Learning Tasks Throughout the Term",
                "Final Written Examination And/Or RST",
            ],
        },
        p1: "A student’s final grade is reflective of their most recent and most consistent level of achievement.",
        p2: "The balance of the weighting of the categories of the achievement chart throughout the course is:",
        table2: {
            Advanced_Functions: "100%",
            knowledge: "25%",
            inquiry: "25%",
            communication: "25%",
            applicatoin: "25%",
        },
    },
    report: {
        p1: "Student achievement will be communicated formally to students via an official report card. Report cards are issued at the midterm point in the course, as well as upon completion of the course. Each report card will focus on two distinct, but related aspects of student achievement. ",
        p2: "First, the achievement of curriculum expectations is reported as a percentage grade. Additionally, the course median is reported as a percentage. The teacher will also provide written comments concerning the student’s strengths, areas for improvement, and next steps. Second, the learning skills are reported as a letter grade, representing one of four levels of accomplishment. The report card also indicates whether an OSSD credit has been earned. ",
        p3: "Upon completion of a course, EVA will send a copy of the report card back to the student’s home school (if in Ontario) where the course will be added to the ongoing list of courses on the student’s Ontario Student Transcript. The report card will also be sent to the student’s home address.",
    },


}
export const MCV4U = {
    title: "Calculus and Vectors",
    description: {
        code: "MCV4U",
        department: "Mathematics",
        grade: "12",
        type: "University",
        credit: "1.0",
        prerequisite: "MHF4U, Advanced Functions, Grade 12, University (may be taken concurrently)",
        document: "Mathematics, The Ontario Curriculum, Grades 11 and 12, 2007",
        link: "http://www.edu.gov.on.ca/eng/curriculum/secondary/math1112currb.pdf",
        developer: "Emerald Valley Academy",
        date: 2021,
        content: "This course expands on the students’ prior knowledge of functions and their growing awareness of change rates. Students will solve problems involving geometric and algebraic representations of vectors, as well as representations of lines and planes in three dimensions; they will broaden their understanding of rates of change to include the derivatives of polynomial, sinusoidal, exponential, rational, and radical functions; and they will apply these concepts and skills to the modeling of real-world relationships. Students will also improve their understanding of the mathematical procedures required for senior mathematics success. This course is designed for students interested in professions in science, engineering, economics, and some sectors of business, as well as those who will need to take a university-level calculus, linear algebra, or physics course.",
    },
    outline: {
        title: "Calculus and Vectors MCV4U - Total Hours (110 Hours)",
        units: [
            {
                title: "Unit One: Introduction to Derivatives",
                hour: "26 Hours",
                content: "In this unit students will deal with rates of change problems and the limit concept. To further investigate the concept of a limit, the unit briefly looks at the relationship between a secant line and a tangent line to a curve. To this point in the course students have been given a fixed point and have been asked to find the tangent slope at that value, in this section of the unit students will determine a tangent slope function similar to what they had done with a secant slope function. Sketching the graph of a derivative function is the final skill and topic",
            },
            {
                title: "Unit Two: Applications of Derivatives",
                hour: "27 Hours",
                contetn: "During this unit students will look at each of these types of problems individually: Pythagorean Theorem Problems (these include ladder and intersection problems), Volume Problems (these usually involve a 3-D shape being filled or emptied), Through Problems, Shadow problems and General Rate Problems.",
            },
            {
                title: "Unit Three: Intro to Vectors",
                hour: "27 Hours",
                content: "There are four main topics pursued in this initial unit of the course. These topics are: an introduction to vectors and scalars, vector properties, vector operations and plane figure properties.",
            },
            {
                title: "Unit Four: Lines and Planes",
                hour: "27 Hours",
                content: "This unit begins with students determining the vector, parametric and symmetric equations of lines in R2 and R3 . Students will also determine the vector, parametric, symmetric and scalar equations of planes in 3-space. Students will also learn the intersections of lines in 3-space and the intersections of a line and a plane in 3-space. Students will learn to determine the intersections of two or three planes by setting up and solving a system of linear equations in three unknowns. Students will also define the terms related to matrices while adding, subtracting, and multiplying them. Students will solve systems of linear equations involving up to three unknowns, using row reduction of matrices, with and without the aid of technology and interpreting row reduction of matrices as the creation of new linear systems equivalent to the original constitute the final two new topics of this important unit.",
            },
            {
                title: "Unit Five: Revolutions in Modern Physics: Quantum Mechanics and Special Relativity",
                hour: "22 Hours",
                content: "In this unit, some of the most exciting and counterintuitive concepts in physics, including Einstein’s ideas about relativity, photoelectric effect, and particle physics, will be examined. Quantum mechanics and special relativity will be investigated mathematically and related problems will be solved. In light of the revolutionary ideas studied in this unit, students will discuss how the introduction of new conceptual models can influence and change scientific thought, and lead to the development of new technologies.",
            },
            {
                title: "Exam/CPT",
                hour: "",
                content: "Final Exam: The final assessment task is a proctored three hours exam worth 30% of the student’s final mark. ",
            },
        ],
        note1: "This course is entirely online and does not require or rely on any textbook.",
        note2: "A scanner, smartphone camera, or similar device to upload handwritten or hand-drawn work",
    },
    expectations: {
        title: "MCV4U Calculus and Vectors, Grade 12 University Preparation",
        content: [
            {
                title: "Rate Of Change",
                overall: [
                    "demonstrate an understanding of rate of change by making connections between average rate of change over an interval and instantaneous rate of change at a point, using the slopes of secants and tangents and the concept of the limit;",
                    "graph the derivatives of polynomial, sinusoidal, and exponential functions, and make connections between the numeric, graphical, and algebraic representations of a function and its derivative;",
                    "verify graphically and algebraically the rules for determining derivatives; apply these rules to determine the derivatives of polynomial, sinusoidal, exponential, rational, and radical functions, and simple combinations of functions; and solve related problems.",
                ]
            },
            {
                title: "Derivatives And Their Applications",
                overall: [
                    "Make connections, graphically and algebraically, between the key features of a function and its first and second derivatives, and use the connections in curve sketching;",
                    "Solve problems, including optimization problems, that require the use of the concepts and procedures associated with the derivative, including problems arising from real-world applications and involving the development of mathematical models.",
                ]
            },
            {
                title: "Geometry And Algebra Of Vectors",
                overall: [
                    "demonstrate an understanding of vectors in two-space and three-space by representing them algebraically and geometrically and by recognizing their applications;",
                    "perform operations on vectors in two- space and three-space, and use the properties of these operations to solve problems, including those arising from real-world applications; 3. distinguish between the geometric representations of a single linear equation or a system of two linear equations in two-space and three-space, and determine different geometric configurations of lines and planes in three-space; 4. represent lines and planes using scalar",
                ]
            },

        ]
    },
    assessment: {
        content: "There are three forms of assessment that will be used throughout this course:",
        tab: [
            {
                title: "Assessment for Learning: ",
                p: "Assessment for learning will directly influence student learning by reinforcing the connections between assessment and instruction, and provide ongoing feedback to the student. Assessment for learning occurs as part of the daily teaching process and helps teachers form a clear picture of the needs of the students because students are encouraged to be more active in their learning and associated assessment. Teachers gather this information to shape their teaching environment.",
                content: [
                    "Ongoing",
                    "Is tied to learning outcomes",
                    "Provides information that structures the teachers’ planning and instruction",
                    "Allows teachers to provide immediate and descriptive feedback that will guide student learning",
                ],
                p2: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
            },
            {
                title: "Assessment as Learning: ",
                p: "Assessment as learning is the use of a task or an activity to allow students the opportunity to use assessment to further their own learning. Self and peer assessments allow students to reflect on their own learning and identify areas of strength and need. These tasks offer students the chance to set their own personal goals and advocate for their own learning.",
                content: [],
                p2: "The purpose of assessment as learning is to enable students to monitor their own progress towards achieving their learning goals.",
            },
            {
                title: "Assessment of Learning: ",
                p: "Assessment of learning will occur at or near the end of a period of learning; this summary is used to make judgements about the quality of student learning using established criteria, to assign a value to represent that quality and to communicate information about achievement to students and parents.",
                content: [],
                p2: "Evidence of student achievement for evaluation is collected over time from three different sources - observations, conversations, and student products. Using multiple sources of evidence will increase the reliability and validity of the evaluation of student learning.",
            },
        ],
        p1: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
    },
    strategy: {
        p1: "Using a variety of instructional strategies, the teacher will provide numerous opportunities for students to develop skills of inquiry, problem solving, and communication as they investigate and learn fundamental concepts. The integration of critical thinking and critical inquiry skills will provide a powerful tool for reasoning and problem solving, and is reflected in a meaningful blend of both process and content.",
        lst: [
            "Communicating: This course provides students with several opportunities to convey their knowledge, both orally and in writing. Students will use discussion boards to discuss what they’ve learned, produce reports that tie what they’ve studied to real-world applications, and make presentations to demonstrate comprehension of some ideas.",
            "Problem solving: By building on existing knowledge and abilities, this course scaffolds student learning. Students will have the opportunity to study earlier ideas and will be confronted with challenges that will require them to apply their skills in creative ways to solve problems in real-world situations.",
            "Reflecting: The reflective process is modeled in this course. The course shows proper communication using some examples and practice exercises to explain intermediate phases and reflect on solutions to see if they make sense in the given context.",
            "Selecting Tools and Computational Strategies: This course demonstrates how to utilize graphing tools to solve problems and introduce students to technologies that can make problem solving easier and more precise.",
            "Connecting: Students will apply the concepts learned in class to real-world situations. Through posed puzzles, investigations, and enrichment activities, students will be able to connect past concepts to new topics.",
            "Self-Assessment: Students receive immediate feedback and can self-assess their grasp of ideas through the use of interactive activities (e.g. multiple choice quizzes, hangman, puzzle activities).",
        ]
    },
    grade: {
        table1: {
            percentage: [
                "70%",
                "30%",
            ],
            category: [
                "Assessments of Learning Tasks Throughout the Term",
                "Final Written Examination And/Or RST",
            ],
        },
        p1: "A student’s final grade is reflective of their most recent and most consistent level of achievement.",
        p2: "The balance of the weighting of the categories of the achievement chart throughout the course is:",
        table2: {
            Calculus_and_Vectors: "100%",
            knowledge: "25%",
            inquiry: "25%",
            communication: "25%",
            applicatoin: "25%",
        },
    },
    report: {
        p1: "Student achievement will be communicated formally to students via an official report card. Report cards are issued at the midterm point in the course, as well as upon completion of the course. Each report card will focus on two distinct, but related aspects of student achievement. ",
        p2: "First, the achievement of curriculum expectations is reported as a percentage grade. Additionally, the course median is reported as a percentage. The teacher will also provide written comments concerning the student’s strengths, areas for improvement, and next steps. Second, the learning skills are reported as a letter grade, representing one of four levels of accomplishment. The report card also indicates whether an OSSD credit has been earned. ",
        p3: "Upon completion of a course, EVA will send a copy of the report card back to the student’s home school (if in Ontario) where the course will be added to the ongoing list of courses on the student’s Ontario Student Transcript. The report card will also be sent to the student’s home address.",
    },

}
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import Divider from "../ToolComp/divider";
export default function Evaluation(props) {
    if (!props || Object.keys(props).length === 0) {
        return <div></div>;
    }
    return (
        <div className="evaluation section">
            <h2 >{props.evaluation.title}</h2>
            <Divider />
            <p >
                {props.evaluation.p1}
            </p>
            <p >
                {props.evaluation.p2}
            </p>
            <div className="strategy-content">
                <ul>
                    {props.evaluation.lst && props.evaluation.lst.map(
                        (item) => (
                            <li><IoMdCheckmarkCircleOutline className="bullet-point-icon" />   {item}</li>
                        )
                    )}
                </ul>
            </div>
            <p>{props.evaluation.p3}</p>
        </div>
    );
}
import TwoColGridBg from "../ToolComp/twoColGridBg";

function Report(props) {
    return (
        <div className="report color-background">
            <TwoColGridBg img="/img/student.jpg" title="The Report Card" content={[props.report.p1, props.report.p2, props.report.p3]} reversed={false} />
        </div>
    );
}

export default Report;
import { CHV2O } from "../../data/OSSD/Grade10/CHV2O";
import GradeCourseTemplate from "../../pageTemplate/GradeCourseTemplate";


export default function CHV2OPage() {
    return (
        <div>
            <GradeCourseTemplate data={CHV2O} />
        </div>
    )
}
export const Grade12CourseOverviewData = {
    title: "Grade 12",
    courses: [
        {
            code: "MHF4U",
            name: "Advanced Functions",
            collab: false,
            link: "/#/academic/Grade12/MHF4U",
        },
        {
            code: "SPH4U",
            name: "Physics",
            collab: false,
            link: "/#/academic/Grade12/SPH4U",
        },
        {
            code: "MCV4U",
            name: "Calculus Vectors",
            collab: false,
            link: "/#/academic/Grade12/MCV4U",
        },
        {
            code: "MDM4U",
            name: "Data Management",
            collab: false,
            link: "/#/academic/Grade12/MDM4U",
        },
        {
            code: "SCH4U",
            name: "Chemistry",
            collab: false,
            link: "/#/academic/Grade12/SCH4U",
        },
        {
            code: "ENG4U",
            name: "English",
            collab: false,
            link: "/#/academic/Grade12/ENG4U",
        },
        {
            code: "OLC4O",
            name: "Ontario Secondary School Literacy Course",
            collab: false,
            link: "/#/academic/Grade12/OLC4O",
        },



    ]
}
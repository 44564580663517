import { MPM2D } from "../../data/OSSD/Grade10/MPM2D";
import GradeCourseTemplate from "../../pageTemplate/GradeCourseTemplate";


export default function MPM2DPage() {
    return (
        <div>
            <GradeCourseTemplate data={MPM2D} />
        </div>
    )
}
import { PPL1O } from "../../data/OSSD/Grade9/PPL1O";
import GradeCourseTemplate from "../../pageTemplate/GradeCourseTemplate";


export default function PPL1OPage() {
    return (
        <div>
            <GradeCourseTemplate data={PPL1O} />
        </div>
    )
}
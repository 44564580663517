import { ICS2O } from "../../data/OSSD/Grade10/ICS2O";
import GradeCourseTemplate from "../../pageTemplate/GradeCourseTemplate";


export default function ICS2OPage() {
    return (
        <div>
            <GradeCourseTemplate data={ICS2O} />
        </div>
    )
}
import { LKBDU } from "../../data/OSSD/Grade10/LKBDU";
import GradeCourseTemplate from "../../pageTemplate/GradeCourseTemplate";


export default function LKBDUPage() {
    return (
        <div>
            <GradeCourseTemplate data={LKBDU} />
        </div>
    )
}
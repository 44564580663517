export const GLE2O = {
    title: "Learning Strategies 1: Skills for Success in Secondary School",
    description: {
        code: "GLE2O",
        department: "Guidance and Career Education",
        type: "Open",
        credit: "1.0",
        grade: "10",
        prerequisite: "Recommendation of principal",
        document: "Guidance and Career Education, The Ontario Curriculum Grades 9 and 10, 2006",
        link: "http://www.edu.gov.on.ca/eng/curriculum/secondary/guidance910currb.pdf",
        developer: "Emerald Valley Academy",
        date: 2021,
        content: "This course focuses on learning strategies to help students become better, more independent learners. Students will learn how to develop and apply literacy and numeracy skills, personalmanagement skills, and interpersonal and teamwork skills to improve their learning and  achievement in school, the workplace, and the community.The course helps students build confidence and motivation to pursue opportunities for success in secondary school and beyond. ",
    },
    outline: {
        title: "GLE2O Learning Strategies 1- Total Hours (110 Hours)",
        units: [
            {
                title: "Unit One:Learn It, Don't Sweat It",
                hour: "25 Hours",
                content: "In this unit, students will Identify their interests ,  abilities and get to know their “smarts” and learning style and their role in learning. Students will sharpen their personal management skills and manage their time and personal space. Students will also learn how to set their goals, learn to use study skills effectively and identify healthy lifestyle choices that support learning and growth.",
            },
            {
                title: "Unit Two: Practice Your Skills",
                hour: "25 Hours",
                contetn: "In this unit, students will use a wide variety of reading strategies , read and understand different texts and visual information and use math procedures and concepts in real-life tasks. They will create different texts and graphics for different purposes and audiences and connect problem solving in math to other areas of life. Students will also identify actions, characteristics, skills, and resources that enhance learning and growth, initiate to improve the physical environment and social-emotional environment and demonstrate behaviours that reflect self-motivation and self-reliance.",
            },
            {
                title: "Unit Three: EXPLORE YOUR OPPORTUNITIES",
                hour: "25 Hours",
                content: "In this unit, students will find and record the information they need in a variety of situations and identify careers and volunteer opportunities that suit your interests and abilities. They will plan school courses and identify graduation requirements. Identifying school and community resources available to support learning, and explain how they can be accessed and describe individuals or programs that can assist with their specific learning needs. Students will also communicate effectively to seek information and assistance or to advocate for themselves.",
            },
            {
                title: "Unit Four: From Here to There",
                hour: "25 Hours",
                content: "In this unit, students will demonstrate effective use of study skills and test-preparation strategies in a variety of subjects and evaluate their impact on academic success, identify their learning styles, personal qualities, and learning challenges by analysing their past experiences, both successful and unsuccessful and Imagining using their new knowledge and skills after graduation.",
            },
            {
                title: "Exam",
                hour: "10 Hours",
                content: "will be proctored and worth 30% of the final mark.",
            },
        ],
        note1: "This course is entirely online and does not require or rely on any textbook.",
        note2: "A scanner, smartphone camera, or similar device to upload handwritten or hand-drawn work",

    },
    expectations: {
        title: "GLE2O: Learning Strategies 1 ,Grade 10",
        content: [
            {
                title: "Personal Management",
                overall: [
                    "use a self-assessment process to develop a personal profile for use in career development planning;",
                    "evaluate and apply the personal- management skills and characteristics needed for school success, document them in their portfolio, and demonstrate their use in a variety of settings;",
                    "demonstrate effective use of interpersonal skills within a variety of settings.",
                ],
            },
            {
                title: "Exploration Of Opportunities",
                overall: [
                    "use a research process to locate and select relevant career information from a variety of sources for inclusion in a portfolio;",
                    "identify current trends in society and the economy and describe their effect on work opportunities and work environments;",
                    "identify a broad range of options for present and future learning, work, and community involvement.",
                ]
            },
            {
                title: "Preparation For Transitions And Change",
                overall: [
                    "use appropriate decision-making and planning processes to set goals and develop a career plan;",
                    "analyse changes taking place in their personal lives, their community, and the economy, and identify strategies to facilitate smooth transitions during change;",
                    "demonstrate an understanding of, and the ability to prepare for, the job- search process. ",
                ]
            },
        ]
    },
    assessment: {
        content: "There are three forms of assessment that will be used throughout this course:",
        tab: [
            {
                title: "Assessment for Learning: ",
                p: "Assessment for learning will directly influence student learning by reinforcing the connections between assessment and instruction, and provide ongoing feedback to the student. Assessment for learning occurs as part of the daily teaching process and helps teachers form a clear picture of the needs of the students because students are encouraged to be more active in their learning and associated assessment. Teachers gather this information to shape their teaching environment.",
                content: [
                    "Ongoing",
                    "Is tied to learning outcomes",
                    "Provides information that structures the teachers’ planning and instruction",
                    "Allows teachers to provide immediate and descriptive feedback that will guide student learning",
                ],
                p2: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
            },
            {
                title: "Assessment as Learning: ",
                p: "Assessment as learning is the use of a task or an activity to allow students the opportunity to use assessment to further their own learning. Self and peer assessments allow students to reflect on their own learning and identify areas of strength and need. These tasks offer students the chance to set their own personal goals and advocate for their own learning.",
                content: [],
                p2: "The purpose of assessment as learning is to enable students to monitor their own progress towards achieving their learning goals.",
            },
            {
                title: "Assessment of Learning: ",
                p: "Assessment of learning will occur at or near the end of a period of learning; this summary is used to make judgements about the quality of student learning using established criteria, to assign a value to represent that quality and to communicate information about achievement to students and parents.",
                content: [],
                p2: "Evidence of student achievement for evaluation is collected over time from three different sources - observations, conversations, and student products. Using multiple sources of evidence will increase the reliability and validity of the evaluation of student learning.",
            },
        ],
        p1: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
    },
    strategy: {
        p1: "Throughout this course students will:",
        p2: "",
        lst: [
            "Students interact in student-paced and instructor-paced interactive, engaging instructional lessons.",
            "Understand the value of diversity in learning and in society",
            "Develop self-confidence and self-advocacy skills",
            "Refine their existing compensatory strategies (reading strategies, writing strategies, listening strategies, study strategies, researching strategies, strategies for memory, organizational strategies, and time management strategies) for learning and develop additional strategies to help meet their potential as learners",
            "Cultivate problem solving skills to become independent learners. ",
            "Opportunities to learn in a variety of ways- individually, cooperatively, independently, with teacher direction, through hands-on experiences, and through examples followed by practice. ",
            "By accomplishing prompts on interactive lessons, students can reflect on different texts. In addition, constant communication with teachers ensures that the students understand complex topics and apply them in their writing. They can also accomplish other tasks through the use of: animations, videos, discussion forums, live chat and other interactive objects.",
        ]
    },
    grade: {
        table1: {
            percentage: [
                "70%",
                "20%",
                "10%"
            ],
            category: [
                "Assessments of Learning Tasks Throughout the Term",
                "RST",
                "FInal Exam",
            ],
        },
        p1: "A student’s final grade is reflective of their most recent and most consistent level of achievement.",
        p2: "The balance of the weighting of the categories of the achievement chart throughout the course is:",
        table2: {
            Learning_Strategies: "100%",
            knowledge: "25%",
            inquiry: "25%",
            communication: "25%",
            applicatoin: "25%",
        },
    },
    report: {
        p1: "Student achievement will be communicated formally to students via an official report card. Report cards are issued at the midterm point in the course, as well as upon completion of the course. Each report card will focus on two distinct, but related aspects of student achievement. ",
        p2: "First, the achievement of curriculum expectations is reported as a percentage grade. Additionally, the course median is reported as a percentage. The teacher will also provide written comments concerning the student’s strengths, areas for improvement, and next steps. Second, the learning skills are reported as a letter grade, representing one of four levels of accomplishment. The report card also indicates whether an OSSD credit has been earned. ",
        p3: "Upon completion of a course, EVA will send a copy of the report card back to the student’s home school (if in Ontario) where the course will be added to the ongoing list of courses on the student’s Ontario Student Transcript. The report card will also be sent to the student’s home address.",
    },
}
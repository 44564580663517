import React from 'react';
import "../../styles/CompStyle/ImageText.css";
import Divider from './divider';
// Import your CSS file

function ImageTextComponent({ imageUrl, title, content, float }) {
    return (
        <div className="image-text-container">
            <h2>{title}</h2>
            <Divider />
            <div className='image-content-wrap'>
                <img src={imageUrl} alt="Image" className="image" style={{ float: float }} />

                {
                    content.map(
                        (text) => (
                            <p className="text" dangerouslySetInnerHTML={{ __html: text }} />
                        )
                    )
                }
            </div>

        </div>
    );
}

export default ImageTextComponent;
export const Grade10CourseOverviewData = {
    title: "Grade 10",
    courses: [
        {
            code: "BBI2O",
            name: "Business",
            collab: false,
            link: "/#/academic/Grade10/BBI2O",
        },
        {
            code: "GLC2O",
            name: "Careers",
            collab: false,
            link: "/#/academic/Grade10/GLC2O",
        }, {
            code: "CHV2O",
            name: "Civics",
            collab: false,
            link: "/#/academic/Grade10/CHV2O",
        }, {
            code: "ICS2O",
            name: "Computer Science",
            collab: false,
            link: "/#/academic/Grade10/ICS2O",
        }, {
            code: "ENG2D",
            name: "English",
            collab: false,
            link: "/#/academic/Grade10/ENG2D",
        }, {
            code: "FSF2D",
            name: "French",
            collab: false,
            link: "/#/academic/Grade10/FSF2D",
        }, {
            code: "CHC2D",
            name: "History",
            collab: false,
            link: "/#/academic/Grade10/CHC2D",
        }, {
            code: "GLE2O",
            name: "Learning Strategies",
            collab: false,
            link: "/#/academic/Grade10/GLE2O",
        },
        {
            code: "ELS2O",
            name: "Literacy Skills_ Reading and Writing",
            collab: false,
            link: "/#/academic/Grade10/ELS2O",
        },
        {
            code: "MPM2D",
            name: "Math",
            collab: false,
            link: "/#/academic/Grade10/MPM2D",
        }, {
            code: "ASM2O",
            name: "Media Arts",
            collab: false,
            link: "/#/academic/Grade10/ASM2O",
        }, {
            code: "AWQ2O",
            name: "Photography ",
            collab: false,
            link: "/#/academic/Grade10/AWQ2O",
        }, {
            code: "SNC2D",
            name: "Science",
            collab: false,
            link: "/#/academic/Grade10/SNC2D",
        },
        {
            code: "LKBDU",
            name: "International Languages- Mandarin",
            collab: false,
            link: "/#/academic/Grade10/LKBDU",
        }
    ]
}
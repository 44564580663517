import "../../styles/CompStyle/TwoColGridBg.css";
import Divider from "./divider";
export default function TwoColGridBg({ img, title, content, reversed = false }) {
    return (
        <div className="two-col-grid-bg">
            <div className={`grid-img-bg ${reversed ? 'img-reversed' : ''}`}>
                <img src={img} />
            </div>
            <div className={`grid-text-bg ${reversed ? 'text-reversed' : ''}`}>
                <h2>{title}</h2>
                <Divider />
                {
                    content.map(
                        (item, index) => (
                            <p className={index}>
                                {item}
                            </p>
                        )
                    )
                }
            </div>
        </div>
    )
}

import { MHF4U } from "../../data/OSSD/Grade12/MHF4U";
import GradeCourseTemplate from "../../pageTemplate/GradeCourseTemplate";


export default function MHF4UPage() {
    return (
        <div>
            <GradeCourseTemplate data={MHF4U} />
        </div>
    )
}
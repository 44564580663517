export const MCR3U = {
    title: "Functions",
    description: {
        code: "MCR3U",
        department: "Mathematics",
        grade: "11",
        credit: "1.0",
        prerequisite: "MPM2D, Principles of Mathematics, Grade 10, Academic",
        document: "Mathematics, The Ontario Curriculum, Grades 11 and 12, 2007",
        link: "http://www.edu.gov.on.ca/eng/curriculum/secondary/math1112currb.pdf",
        developer: "Emerald Valley Academy",
        date: 2021,
        content: "This course introduces the mathematical concept of the function by extending students’ experiences with linear and quadratic relations. Students will investigate properties of discrete and continuous functions, including trigonometric and exponential functions; represent functions numerically, algebraically, and graphically; solve problems involving applications of functions; investigate inverse functions; and develop facility in determining equivalent algebraic expressions. Students will reason mathematically and communicate their thinking as they solve multi-step problems.",
    },
    outline: {
        title: "Functions MCR3U- Total Hours (110 Hours)",
        units: [
            {
                title: "Characteristics of Functions ",
                hour: "26 Hours",
                content: "Students will explore functions in this unit, their representations, and their inverses, and how to make connections between the algebraic and graphical representations of functions using transformations. Students will learn how to determine the zeros and the maximum or minimum of a quadratic function, and solve problems involving quadratic functions, including problems arising from real-world applications. By the end of the unit students will be able to demonstrate an understanding of equivalence as it relates to simplifying polynomial, radical, and rational expressions.",
            },
            {
                title: " Unit Two: Exponential Functions",
                hour: "25 Hours",
                content: "This unit will explore several topics including evaluating powers with rational exponents, simplifying expressions containing exponents, and describing properties of exponential functions represented in a variety of ways. The emphasis will be on problem solving using these concepts.",
            },
            {
                title: "Unit Three: Trigonometric Functions",
                hour: "25 Hours",
                content: "This unit concentrates students’ attention on determining the values of the trigonometric ratios for angles less than 360º; proving simple trigonometric identities and solving problems using the primary trigonometric ratios. The sine law and the cosine law are developed. Students will learn to demonstrate an understanding of periodic relationships and sinusoidal functions, and make connections between the numeric, graphical, and algebraic representations of sinusoidal functions while solving problems involving sinusoidal functions, including problems arising from real-world applications.",
            },
            {
                title: " Unit Four : Discrete Functions",
                hour: "25 Hours",
                content: "The unit begins with an exploration of recursive sequences and how to represent them in a variety of ways. Making connections to Pascal’s triangle, demonstrating understanding of the relationships involved in arithmetic and geometric sequences and series, and solving related problems involving compound interest and ordinary annuities will form the rest of the unit.",
            },
            {
                title: " Final Exam",
                hour: "3 Hours",
                content: "This is a proctored exam worth 30% of your final grade",
            },
            {
                title: "RST",
                hour: "6 Hours",
                content: "This is a proctored assessment worth 30% of your final grade"
            }
        ],
        note1: "This course is entirely online and does not require or rely on any textbook.",
    },
    expectations: {
        title: "MHF3U Functions, Grade 11, University Preparation",
        content: [
            {
                title: "Characteristics Of Functions",
                overall: [
                    "demonstrate an understanding of functions, their representations, and their inverses, and make connections between the algebraic and graphical representations of functions using transformations;",
                    "determine the zeros and the maximum or minimum of a quadratic function, and solve problems involving quadratic functions, including problems arising from real-world applications;",
                    "demonstrate an understanding of equivalence as it relates to simplifying polynomial, radical, and rational expressions.",
                ]
            },
            {
                title: "Exponential Functions",
                overall: [
                    "evaluate powers with rational exponents, simplify expressions containing exponents, and describe properties of exponential functions represented in a variety of ways;",
                    "make connections between the numeric, graphical, and algebraic representations of exponential functions;",
                    "identify and represent exponential functions, and solve problems involving exponential functions, including problems arising from real-world applications.",
                ]
            },
            {
                title: "Discrete Functions",
                overall: [
                    "demonstrate an understanding of recursive sequences, represent recursive sequences in a variety of ways, and make connections to Pascal’s triangle;",
                    "demonstrate an understanding of the relationships involved in arithmetic and geometric sequences and series, and solve related problems;",
                    "make connections between sequences, series, and financial applications, and solve problems involving compound interest and ordinary annuities.",
                ],
            },
            {
                title: "Trigonometric Functions",
                overall: [
                    "determine the values of the trigonometric ratios for angles less than 360º; prove simple trigonometric identities; and solve problems using the primary trigonometric ratios, the sine law, and the cosine law;",
                    "demonstrate an understanding of periodic relationships and sinusoidal functions, and make connections between the numeric, graphical, and algebraic representations of sinusoidal functions;",
                    "identify and represent sinusoidal functions, and solve problems involving sinusoidal functions, including problems arising from real-world applications",
                ]
            },
        ]
    },
    assessment: {
        content: "There are three forms of assessment that will be used throughout this course:",
        tab: [
            {
                title: "Assessment for Learning: ",
                p: "Assessment for learning will directly influence student learning by reinforcing the connections between assessment and instruction, and provide ongoing feedback to the student. Assessment for learning occurs as part of the daily teaching process and helps teachers form a clear picture of the needs of the students because students are encouraged to be more active in their learning and associated assessment. Teachers gather this information to shape their teaching environment.",
                content: [
                    "Ongoing",
                    "Is tied to learning outcomes",
                    "Provides information that structures the teachers’ planning and instruction",
                    "Allows teachers to provide immediate and descriptive feedback that will guide student learning",
                ],
                p2: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
            },
            {
                title: "Assessment as Learning: ",
                p: "Assessment as learning is the use of a task or an activity to allow students the opportunity to use assessment to further their own learning. Self and peer assessments allow students to reflect on their own learning and identify areas of strength and need. These tasks offer students the chance to set their own personal goals and advocate for their own learning.",
                content: [],
                p2: "The purpose of assessment as learning is to enable students to monitor their own progress towards achieving their learning goals.",
            },
            {
                title: "Assessment of Learning: ",
                p: "Assessment of learning will occur at or near the end of a period of learning; this summary is used to make judgements about the quality of student learning using established criteria, to assign a value to represent that quality and to communicate information about achievement to students and parents.",
                content: [],
                p2: "Evidence of student achievement for evaluation is collected over time from three different sources - observations, conversations, and student products. Using multiple sources of evidence will increase the reliability and validity of the evaluation of student learning.",
            },
        ],
        p1: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
    },
    strategy: {
        p1: "This course’s overarching goal is to enable students to use mathematics’ language competently, confidently, and flexibly. A number of instructional methodologies are used to give learning opportunities to meet a variety of learning styles, interests, and ability levels. The teaching and learning methodologies will be built on the following mathematical processes:",
        lst: [
            "Communicating: This course provides students with several opportunities to convey their knowledge, both orally and in writing. Students will use discussion boards to discuss what they’ve learned, produce reports that tie what they’ve studied to real-world applications, and make presentations to demonstrate comprehension of some ideas.",
            "Problem solving: By building on existing knowledge and abilities, this course scaffolds student learning. Students will have the opportunity to study earlier ideas and will be confronted with challenges that will require them to apply their skills in creative ways to solve problems in real-world situations.",
            "Reflecting: The reflective process is modeled in this course. The course shows proper communication using some examples and practice exercises to explain intermediate phases and reflect on solutions to see if they make sense in the given context.",
            "Selecting Tools and Computational Strategies: This course demonstrates how to utilize graphing tools to solve problems and introduce students to technologies that can make problem solving easier and more precise.",
            "Connecting: Students will apply the concepts learned in class to real-world situations. Through posed puzzles, investigations, and enrichment activities, students will be able to connect past concepts to new topics.",
            "Self-Assessment: Students receive immediate feedback and can self-assess their grasp of ideas through the use of interactive activities (e.g. multiple choice quizzes, hangman, puzzle activities).",
            "Feedback: Through constant communication with the instructor, timely feedback and encouragement was given.",
        ],
    },
    grade: {
        table1: {
            percentage: [
                "70%",
                "30%",
            ],
            category: [
                "Assessments of Learning Tasks Throughout the Term",
                "Final Written Examination And/Or RST",
            ],
        },
        p1: "A student’s final grade is reflective of their most recent and most consistent level of achievement.",
        p2: "The balance of the weighting of the categories of the achievement chart throughout the course is:",
        table2: {
            Functions: "100%",
            knowledge: "25%",
            inquiry: "25%",
            communication: "25%",
            applicatoin: "25%",
        },
    },
    report: {
        p1: "Student achievement will be communicated formally to students via an official report card. Report cards are issued at the midterm point in the course, as well as upon completion of the course. Each report card will focus on two distinct, but related aspects of student achievement. ",
        p2: "First, the achievement of curriculum expectations is reported as a percentage grade. Additionally, the course median is reported as a percentage. The teacher will also provide written comments concerning the student’s strengths, areas for improvement, and next steps. Second, the learning skills are reported as a letter grade, representing one of four levels of accomplishment. The report card also indicates whether an OSSD credit has been earned. ",
        p3: "Upon completion of a course, EVA will send a copy of the report card back to the student’s home school (if in Ontario) where the course will be added to the ongoing list of courses on the student’s Ontario Student Transcript. The report card will also be sent to the student’s home address.",
    },


}
import { SCH3U } from "../../data/OSSD/Grade11/SCH3U";
import GradeCourseTemplate from "../../pageTemplate/GradeCourseTemplate";


export default function SCH3UPage() {
    return (
        <div>
            <GradeCourseTemplate data={SCH3U} />
        </div>
    )
}
import { useEffect } from "react";
import ReactDOM from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import ImageTextComponent from "../ToolComp/ImageText";
import Divider from "../ToolComp/divider";
import CollapsText2 from "../ToolComp/CollapsText2";


export default function GradePlan() {

    useEffect(() => {
        const listItems = document.querySelectorAll(".table-body-list li");

        const addIconToItem = (item) => {
            const icon = document.createElement("span");
            icon.className = "check-mark-list-item bullet-point-icon";

            const iconComponent = <FontAwesomeIcon icon={faCheck} />;
            ReactDOM.render(iconComponent, icon);

            // Append the div to the DOM
            item.insertBefore(icon, item.firstChild);
        };

        listItems.forEach((item) => {
            addIconToItem(item);
        });

        // Cleanup function to remove the event listener
        return () => {
            listItems.forEach((item) => {
                const icon = item.querySelector(".check-mark-list-item");
                if (icon) {
                    ReactDOM.unmountComponentAtNode(icon);
                    item.removeChild(icon);
                }
            });
        };
    }, []); // Empty dependency array ensures this effect runs only once
    return (
        <div className="consideration section">
            <h2>Program Planning Considerations for online course</h2>
            <Divider />
            <div className="pragrap1">
                <p className="plan-title">Cheating and Plagiarism</p>
                <p>Emerald Valley Academy International commits to having policies for assessments that minimize the risk of cheating. We also commit to begin each course with refresher learning on academic integrity.</p>
                <p>In the event of incidences of academic dishonesty, the student, Academic Director (and, in the case of students under 18, their parents) will be notified of the occurrence, of the consequence, and of the potential consequences of subsequent incidents.</p>
                <p className="plan-title">Imporper Citation</p>
                <p>Grades 11 and 12</p>
                <p>First Instance: A warning and an opportunity to redo the piece.</p>
                <p>Subsequent Instance: An opportunity to redo the piece to a maximum grade of 75%.</p>
                <p className="plan-title">Unaccredited Paraphrasing</p>
                <p>Grade 11 and 12</p>
                <p>First Instance: An opportunity to redo the piece to a maximum grade of 75%.</p>
                <p>Subsequent Instance: An opportunity to redo the piece to a maximum grade of 50%.</p>
                <p className="plan-title">Unaccredited Verbatim</p>
                <p>Grade 11 and 12</p>
                <p>First Instance: An opportunity to redo the piece to a maximum grade of 50%.</p>
                <p>Subsequent Instance: A grade of zero. No opportunity to resubmit.</p>
                <p className="plan-title">Full Plagiarism</p>
                <p>Grade 11 and 12</p>
                <p>First Instance: A grade of zero. No opportunity to resubmit.</p>
                <p>Subsequent Instance: A grade zero. No opportunity to resubmit.</p>
            </div>
            <img class="image-divider"
                src="/img/img-divider.png" />
            <div className="image-word">
                <ImageTextComponent imageUrl="/img/8.png" float="right" title="Instructional Approaches" content={[
                    "Teachers will use a variety of instructional strategies to help students become independent, strategic and successful learners. The key to student success is effective, accessible instruction. When planning this course of instruction, the teacher will identify the main concept and skills of the course, consider the context in which students will apply their learning and determine the students’ learning goals. The instructional program for this course will be well planned and will support students in reaching their optimal level of challenge for learning, while directly teaching the skills that are required for success.",
                    "Understanding student strengths and needs will enable the teacher to plan effective instruction and meaningful assessments. Throughout this course the teacher will continually observe and assess the students’ readiness to learn, their interests, and their preferred learning styles and individual learning needs.",
                    "Teachers will use differentiated instructional approaches such as:",
                    "<ul class='custom-bullet-list'><li>adjusting the method or pace of instruction</li><li>using a variety of resources</li><li>allowing a wide choice of topics</li><li>adjusting the learning environment</li><li>scaffolding instruction</li></ul>",
                    "During this course, the teacher will provide multiple opportunities for students to apply their knowledge and skills and consolidate and reflect upon their learning.",
                ]} />
            </div>

            <div className="image-word">
                <ImageTextComponent imageUrl="/img/playground.jpg" float="left" title="Planning the Program for Students with Special Educational Needs" content={[
                    "The teacher in this course is the key educator of students with special education needs. The teacher has a responsibility to help all students learn, and will work collaboratively with the guidance counselor, where appropriate, to achieve this goal. In planning this course, the teacher will pay particular attention to the following guidelines:",
                    "<ul class='custom-bullet-list'><li>All students have the ability to succeed</li> <li>Each student has his or her own unique patterns of learning</li><li>Successful instructional practices are founded on evidence-based research, tempered by experience</li><li>Universal design and differentiated instruction are effective and interconnected  means of meeting the learning or productivity needs of any group of students</li><li>Online teachers are the key educators for a student’s literacy and numeracy development</li><li>Online teachers need the support of the larger school community to create a learning environment that supports students with special education needs</li><li>Fairness is not sameness</li> </ul>",
                ]} reversed={true} />
            </div>

            <div className="list-table">
                <p>The teacher will use the following strategies:</p>
                <table>
                    <thead>
                        <tr>
                            <th colspan="2">Students with Special Education Needs</th>
                        </tr>
                    </thead>
                    <tbody className="table-body-list">
                        <tr>
                            <td data-label="Students with Special Education Needs">
                                <ul>
                                    <li>Extra time on tests and extended deadlines for major assessments</li>
                                    <li>Complete tasks or present information in ways that cater to individual learning styles</li>
                                    <li>Variety of teaching and learning strategies</li>
                                    <li>Scaffolding</li>
                                    <li>Break down (chunk) assignments</li>
                                    <li>A computer for assessments and exams</li>
                                    <li>Formula sheets, memory aids</li>
                                    <li>oral and written instructions</li>
                                    <li>Cue cards during instruction and Assessments</li>
                                    <li>Graphic organizers</li>
                                </ul>
                            </td>
                            <td data-label="Students with Special Education Needs">
                                <ul>
                                    <li>Specific strategies to enhance recall</li>
                                    <li>Non-verbal cues and reminders to remain focused</li>
                                    <li>Oral testing</li>
                                    <li>Allow for sufficient response time</li>
                                    <li>Experiential learning experiences so that students can make connections between curriculum and real-world examples</li>
                                    <li>Conferencing</li>
                                    <li>Prompting students through lessons and assessments</li>
                                    <li>Refocusing strategies</li>
                                    <li>Periodic breaks</li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="second-lanuage">
                <h2>Planning the Program for Students with English as a Second Language</h2>
                <Divider />
                <p>In planning this course for students with linguistic backgrounds other than English, the teacher will create a safe, supportive, and welcoming environment that nurtures the students’ self-confidence while they are receiving course instruction. Most English language learners who have developed oral proficiency in everyday English will nevertheless require instructional scaffolding to meet curriculum expectations. The teacher will adapt the instructional program in order to facilitate the success of these students in their classes. Appropriate adaptations and strategies for this course will include:</p>
                <div className="list-table second-language-table">
                <table>
                    <thead>
                        <tr>
                                <th colspan="2">Students with English as Second Language</th>
                        </tr>
                    </thead>
                        <tbody className="table-body-list">
                        <tr>
                            <td>
                                <ul>
                                    <li>Body language and non-verbal communication</li>
                                    <li>Model expectations</li>
                                    <li>Subject-specific dictionary</li>
                                    <li>Cooperative learning</li>
                                    <li>Concrete examples and materials</li>
                                    <li>Avoid idioms</li>
                                    <li>Bilingual Dictionaries</li>
                                    <li>Buddy system</li>
                                    <li>Peer tutors</li>
                                    <li>Allow sufficient response time</li>
                                    <li>Graphic organizers</li>
                                    <li>Scaffolding</li>
                                    <li>Story maps</li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>Conferencing</li>
                                    <li>Pre-writing strategies</li>
                                    <li>Literature circle</li>
                                    <li>Journal</li>
                                    <li>Previewing course readings / texts</li>
                                    <li>Materials that reflect cultural diversity</li>
                                    <li>Free voluntary reading</li>
                                    <li>Guided Reading</li>
                                    <li>Guided Writing</li>
                                    <li>Think Aloud</li>
                                    <li>Whole-Class Response</li>
                                    <li>Editing checklist</li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>

            </div>
            <div className="support">
                <h2>Supporting First Nations, Métis and Inuit Students</h2>
                <Divider />
                <p>Emerald Valley Academy International will promote active and engaged citizenship, which includes greater awareness of the distinct place and role of Indigenous (First Nation, Métis, and Inuit) peoples in our shared heritage and in the future in Ontario.</p>
                <p>Emerald Valley Academy International will:</p>
                <ul className="custom-bullet-list">
                    <li>increase the focus in school strategic planning to promote the voluntary, confidential self-identification of First Nation, Métis, and Inuit students as a means to enhance the success and well-being of Aboriginal students and to help close the achievement gap</li>
                    <li>continue to identify and share practices and resources to help improve First Nation, Métis, and Inuit student achievement and close the achievement gap</li>
                    <li>increase the training in our schools to respond to the learning and cultural needs of First Nation, Métis, and Inuit students</li>
                    <li>provide quality programs, services, and resources at our schools to support First Nation, Métis, and Inuit student</li>
                    <li>provide quality programs, services, and resources at our schools who support First Nation, Métis, and Inuit students to help create learning opportunities that support improved academic achievement and identify building</li>
                    <li>provide curriculum links that facilitates learning about contemporary and traditional First National, Métis, and Inuit cultures, histories, and perspectives among all students</li>
                    <li>develop awareness among teachers of the learning styles of First Nation, Métis, and Inuit students and employ instructional methods designed to enhance the learning of all First Nation, Métis, and Inuit students</li>
                    <li>implement targeted learning strategies for effective oral communication and mastery of reading and writing</li>
                    <li>implement strategies for developing critical and creative thinking</li>
                    <li>provide access to a variety of accurate and reliable Aboriginal resources such as periodicals, books, software, and resources in other media, including materials in the main Aboriginal languages in schools with First Nation, Métis, and Inuit students</li>
                    <li>provide a supportive and safe environment for all First Nation, Métis, and Inuit students</li>
                </ul>
            </div>
            <img class="image-divider"
                src="/img/img-divider.png" />
            <div className="techonology">
                <h2>The Role of Information and Communication Technology</h2>
                <Divider />
                <p>ICT tools will be integrated into this course for whole-class instruction and for the design of curriculum units that contain varied approaches to learning in order to meet diverse needs and interests of the students in this class. At the beginning of this class, all students will be made aware of issues related to Internet privacy, safety, and responsible use, as well as of the potential for abuse of this technology, particularly when it is used to promote hatred. ICT used in this course will include:</p>
                <div className="list-table">
                    <table>
                        <thead>
                            <tr>
                                <th colspan="2">Information and Communication Technology</th>
                            </tr>
                        </thead>
                        <tbody className="table-body-list">
                            <tr>
                                <td className="Information and Communication Technology">
                                    <ul>
                                        <li>Websites</li>
                                        <li>Online libraries</li>
                                        <li>Archives</li>
                                        <li>Public records</li>
                                        <li>YouTube</li>
                                        <li>Curriculum Digital Resources</li>
                                        <li>Widgets</li>
                                        <li>Online Graphing Calculator</li>
                                        <li>Cell phones</li>
                                        <li>iPads</li>
                                    </ul>
                                </td>
                                <td data-label="Information and Communication Technology">
                                    <ul>
                                        <li>DVDs</li>
                                        <li>Digital Camera</li>
                                        <li>Edsby</li>
                                        <li>G Suite</li>
                                        <li>Office 365</li>
                                        <li>Gizmos</li>
                                        <li>Labster</li>
                                        <li>Gradeslam</li>
                                        <li>Mathspace</li>
                                        <li>Mathletics</li>
                                        <li>Screencastify</li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="multi-col">
                <CollapsText2 title="Environmental Education Connections" content={[
                    "Although there are no specific environmental connections in this course, teachers will develop an environmental understanding fostered through the learning context (e.g., problems and examples related to environmental issues such as climate change, habitat destruction, population growth, energy conservation, and waste management).",
                    "Students will be encouraged to explore a range of environmental concerns using issue-based analysis and some of the following strategies:",
                    "<ul class='custom-bullet-list'><li>Community Connections</li><li>Simulation</li><li>Problem Solving</li><li>Co-operative Learning</li></ul>",
                ]} />
                <CollapsText2 title="Healthy Relationships in the learning Environment" content={[
                    "At Emerald Valley Academy International, every student is entitled to learn in a safe, respectful and caring environment, free from violence and harassment. The teacher will create a safe and supportive environment in the class by cultivating positive relationships between students and between the teacher and their students. The teacher will use the following strategies:",
                    "<ul class='custom-bullet-list'><li>Using inclusive language during instruction</li><li>Developing a learning environment where all students feel safe</li><li>Promotion of diversity and inclusivity in the classroom</li><li>Getting students involved within their school community</li><li>Making community connections</li><li>Peer Reflection</li><li>Group discussions</li></ul>",
                ]} />
                <CollapsText2 title="Equity and Inclusive Education in the Learning Environment" content={[
                    "At Emerald Valley Academy International we embrace multiculturalism, human rights and diversity as fundamental values. Bullying, hate propaganda and cyber bullying, racism, religious intolerance, homophobia and gender-based violence are still evident in our communities and, unfortunately, in our schools. At Emerald Valley Academy International we address the needs of a rapidly changing and increasingly complex society by ensuring that our policies evolve with changing societal needs.",
                    "Emerald Valley Academy International will:",
                    "<ul class='custom-bullet-list'><li>Create and support a positive safe online learning climate that fosters and promotes equity, inclusive education, diversity</li><li>Develop and implement an equity and inclusive education policy</li><li>Share effective practices and resources and promote and participate in collaborative learning opportunities</li><li>Seek out community partners to support school efforts by providing resources and professional learning opportunities</li></ul>",
                ]} />
                <CollapsText2 title="Ethics in the Learning Environment" content={[
                    "At Emerald Valley Academy International teachers provide varied opportunities for students to learn about ethical issues and to explore the role of ethics in decision making.",
                    "The following strategies will be used to develop students’ understanding of ethics:",
                    "<ul class='custom-bullet-list'><li>Making community connections</li><li>Model ethical behavior</li><li>Inclusive practice</li><li>Foster positive relationships with others</li></ul>",
                ]} />
                <CollapsText2 title="Financial Literacy Connections" content={[
                    "There is a growing recognition that the education system has a vital role to play in preparing young people to take their place as informed, engaged, and knowledgeable citizens in the global economy.",
                    "Because making informed decisions about economic and financial matters has become an increasingly complex undertaking in the modern world, where appropriate, the teacher will give students the opportunity to build knowledge and skills.",
                    "Strategies that will be used will include:",
                    "<ul class='custom-bullet-list'><li>Community connections</li><li>Simulation</li><li>Problem Solving</li><li>Inquiry-based learning</li><li>Cross-curricular connections</li><li>Critical literacy skills</li><li>Setting financial goals</li><li>Developing intra-personal skills</li></ul>",
                ]} />
                <CollapsText2 title="Literacy, Mathematical Literacy, and Inquiry Skills" content={[
                    "At Emerald Valley Academy International it is the responsibility of all of our teachers to explicitly teach literacy and inquiry skills. The following skills will be developed in each course delivered at Emerald Valley Academy International:",
                    "<ul class='custom-bullet-list'><li>Extract information</li><li>Analyze various types of digital representations, including graphs, charts, diagrams, etc.</li><li>Use appropriate and correct terminology, including that related to the concepts of disciplinary thinking</li><li>Making community connections</li><li>Peer reflecting</li> <li>Simulation</li><li>Problem solving</li><li>Cross-curricular connections</li><li>Foster use of proper terminology</li> <li>Inquiry and research skills</li><li>Helps students to develop a language for literacy, inquiry, and numeracy skills</li><li>Assist students with developing communication skills in areas of literacy, inquiry, and numeracy</li></ul>",
                ]} />
                <CollapsText2 title="Cooperative Education" content={[
                    "Cooperative education programs allow students to earn secondary school credits while completing a work placement in the community. These programs compliment students’ academic programs and are valuable for all students, whatever their post-secondary destination.",
                    "Cooperative education courses may be earned using this course as one of the related courses.",
                ]} />
                <CollapsText2 title="Experiential Learning" content={[
                    "Central to the philosophy at Emerald Valley Academy International is the focus on experiential learning. Planned learning experiences in the community, including job shadowing, field trips, and hands-on experiences will provide our students with opportunities to see the relevance of their classroom learning in a work setting, make connections between school and work, and explore a career of interest as they plan their pathways through secondary school and make postsecondary plans. ",
                ]} />
                <CollapsText2 title="Health and Safety in the Learning Environment" content={[
                    "As part of every course, students must be made aware that health and safety in their learning environment are the responsibility of all participants - at home, at school, and in the workplace. Teachers will model safe practices at all times when communicating with students online.",
                ]} />
                <CollapsText2 title="The Role of the School Library" content={[
                    "Although Emerald Valley Academy International does not have an official school library, students are encouraged to use e-books, local libraries, GALE resource archives and Curriculum Video Digital resources to develop important research and inquiry skills.",
                ]} />
            </div>
            <div className="promotion">
                <h2>Promotion of Careers</h2>
                <Divider />
                <p>The knowledge and skills students acquire in this course will be useful in helping students recognize the value of their education and applications to the world outside of school and identify possible careers, essential skills and work habits required to succeed. Students will learn how to connect their learning in asking questions and finding answers to employable skills.</p>
                <p>During this course the teacher will:</p>
                <ul className="custom-bullet-list">
                    <li>Ensure that all students develop the knowledge and skills they need to make informed education and career/life choices</li>
                    <li>Provide a learning environment and online school-wide opportunities for this learning</li>
                    <li>Engage parents and the broader community in the development, implementation, and evaluation of the program to support students in their learning</li>
                    <li>Use the four-step inquiry process linked to the four areas of learning:</li>
                    <ul className="custom-bullet-list">
                        <li>Knowing yourself - Who am I ?</li>
                        <li>Exploring opportunities - What are my opportunities?</li>
                        <li>Making decisions and setting goals - Who do I want to become?</li>
                        <li>Achieving goals and making transitions - What is my plan for achieving my goals?</li>
                    </ul>
                </ul>
                <p>The teacher will support students in this course in education and career/life planning by providing them with learning opportunities, filtered through the lens of the four inquiry questions, that allow them to apply subject-specific knowledge and skills to work-related situations; explore subject-related education and career/life options, and become competent, self-directed planners.</p>
            </div>
        </div>
    )
}
export const Grade11CourseOverviewData = {
    title: "Grade 11",
    courses: [
        {
            code: "ENG3U",
            name: "English",
            collab: false,
            link: "/#/academic/Grade11/ENG3U",
        },
        {
            code: "MCR3U",
            name: "Functions",
            collab: false,
            link: "/#/academic/Grade11/MCR3U",
        },
        {
            code: "SPH3U",
            name: "Physics",
            collab: false,
            link: "/#/academic/Grade11/SPH3U",
        },
        {
            code: "SCH3U",
            name: "Chemistry",
            collab: false,
            link: "/#/academic/Grade11/SCH3U",
        }, 
        {
            code: "SBI3U",
            name: "Biology",
            collab: false,
            link: "/#/academic/Grade11/SBI3U",
        },


        // {
        //     code: "BAF3M",
        //     name: "Accounting",
        //     collab: false,
        //     link: "",
        // },
        // {
        //     code: "HSP3U",
        //     name: "Anthropology, Psychology _ Sociology",
        //     collab: false,
        //     link: "",
        // },
        // {
        //     code: "BDI3C",
        //     name: "Introduction to Entrepreneurship",
        //     collab: false,
        //     link: "",
        // }, {
        //     code: "SBI3C",
        //     name: "Biology",
        //     collab: false,
        //     link: "",
        // },  {
        //     code: "ICS3U",
        //     name: "Computer Science",
        //     collab: false,
        //     link: "",
        // }, {
        //     code: "GWL3O",
        //     name: "Design Your Future",
        //     collab: false,
        //     link: "",
        // },  {
        //     code: "MBF3C",
        //     name: "Foundations of College Mathematics",
        //     collab: false,
        //     link: "",
        // }, {
        //     code: "FSF3U",
        //     name: "French",
        //     collab: false,
        //     link: "",
        // }, {
        //     code: "MCF3M",
        //     name: "Functions and Applications",
        //     collab: false,
        //     link: "",
        // }, 
        // {
        //     code: "CLU3M",
        //     name: "Law",
        //     collab: false,
        //     link: "",
        // },
        // {
        //     code: "BMI3C-3M",
        //     name: "Marketing",
        //     collab: false,
        //     link: "",
        // },
        // {
        //     code: "ASM3M",
        //     name: "Media Arts",
        //     collab: false,
        //     link: "",
        // },
        // {
        //     code: "EMS3O",
        //     name: "Media Studies",
        //     collab: false,
        //     link: "",
        // },
        // {
        //     code: "AWQ3M",
        //     name: "Photography",
        //     collab: false,
        //     link: "",
        // },
       
        // {
        //     code: "AVI3M",
        //     name: "Visual Arts",
        //     collab: false,
        //     link: "",
        // },


    ]
}
import "../../styles/CompStyle/Upperdown.css";
export default function Upperdown({ img, title, context }) {
    return (
        <div className="upperdown">
            <h2>{title}</h2>
            <div className="img">
                <img src={img} />
            </div>
            <div className="upperdown-context">
                {
                    context.map(
                        (item, index) => (
                            <p key={index} dangerouslySetInnerHTML={{ __html: item }} />
                        )
                    )
                }
            </div>

        </div>
    )
}
import { ASM2O } from "../../data/OSSD/Grade10/ASM2O";
import GradeCourseTemplate from "../../pageTemplate/GradeCourseTemplate";


export default function ASM2OPage() {
    return (
        <div>
            <GradeCourseTemplate data={ASM2O} />
        </div>
    )
}
export const ENG2D = {
    title: "English",
    description: {
        code: "ENG2D",
        department: "English",
        type: "Academic",
        credit: "1.0",
        grade: "10",
        prerequisite: "ENG1D, English, Grade 9, Academic or ENG1P, English, Grade 9, Applied",
        document: "English, The Ontario Curriculum, Grades 9 and 10, 2007",
        link: "http://www.edu.gov.on.ca/eng/curriculum/secondary/english910currb.pdf",
        developer: "Emerald Valley Academy",
        date: 2021,
        content: "This course is designed to extend the range of oral communication, reading, writing, and media literacy skills that students need for success in their secondary school academic programs and in their daily lives. Students will analyse literary texts from contemporary and historical periods, interpret and evaluate informational and graphic texts, and create oral, written, and media texts in a variety of forms. An important focus will be on the selective use of strategies that contribute to effective communication. This course is intended to prepare students for the compulsory Grade 11 university or college preparation course.        ",
    },
    outline: {
        title: "ENG2D English- Total Hours (110 Hours)",
        units: [
            {
                title: "Unit One: Short Stories",
                hour: "20 Hours",
                content: "A diverse collection of short stories provides students with the opportunity to read a wide variety of texts and to explore, discuss, and write. Topics focused on include P.A.C.T.S, scaffolded activities to prepare students for future essay writing which includes utilizing a constructed response strategy, integrating support into writing, and deeper analysis of text. Students will be introduced to rhetorical devices and will create a media assignment to demonstrate their understanding of how it is used in literature.",
            },
            {
                title: "Unit Two: Novel: The Field Guide to the North American Teenager",
                hour: "25 Hours",
                contetn: "Students will read the novel, “The Field Guide to the North  American Teenager” and answer comprehension questions. They will create a character profile scrapbook for the characters of the novel. They will also participate in the discussion forum and analyze the novel in terms of the information, ideas, issues, or theme, examining how various aspects of the texts contribute to the presentation or development of novel elements.",
            },
            {
                title: "Unit Three: Poetry",
                hour: "15 Hours",
                content: "Students will be introduced to different forms of poetry. They will learn how to create them properly. They will also learn about soliloquies to differentiate it from monologues as they familiarize themselves with “Shakespeare’s Hamlet”. Lastly, they will also learn to analyze poetry critically. ",
            },
            {
                title: "Unit Four: Media Studies",
                hour: "25 Hours",
                content: "Students will learn about communication, its process and its types. They will also learn about critical reading and participate in a discussion forum. Aside from that, they will also learn about radio broadcasting to podcast, radio script writing to write their own script, opinion writing to write their opinion essay, and film to write a film review. ",
            },
            {
                title: "Unit Five: Novel: Stamped: Racism, Anti-Racism and You",
                hour: "25 Hours",
                content: "Students will learn the history of Racism through Stamped: Racism, Anti-Racism and You. They will explore relevant themes and concepts in this extraordinary time. They will critically analyze ideas and connect them to come up with a solid understanding of one of the most controversial issues today."
            },
            {
                title: "Rich Summative Task",
                hour: "10 Hours",
                content: "This is a summative task assigned at the end of the course that brings together many of the expectations covered throughout the course and is created to assess and evaluate a student’s ability to demonstrate their understanding of the expectations through not only the product, but through conversation and observation.",
            },
        ],
        note1: "This course is entirely online and does not require or rely on any textbook.",
        note2: "A scanner, smartphone camera, or similar device to upload handwritten or hand-drawn work",

    },
    expectations: {
        title: "ENG2D: English ,Grade 10",
        content: [
            {
                title: "Oral Communication",
                overall: [
                    "Listening to Understand:: Listen in order to understand and respond appropriately in a Variety of situations for a variety of purposes;",
                    "Speaking to Communicate:: Use speaking skills and strategies appropriately to communicate with different audiences for a variety of purposes;",
                    "Reflecting on Skills and Strategies: Reflect on and identify their strengths as listeners and speakers, areas for improvement, and the strategies they found most helpful in oral communication situations.",
                ],
            },
            {
                title: "Reading And Literature Studies",
                overall: [
                    "Reading for Meaning: Read and demonstrate an understanding of a variety of literary, informational, and graphic texts, using a range of strategies to construct meaning;",
                    "Understanding Form and Style: Recognize a variety of text forms, text features, and stylistic elements and demonstrate understanding of how they help communicate meaning;",
                    "Reading With Fluency: Use knowledge of words and cueing systems to read fluently;",
                    "Reflecting on Skills and Strategies: Reflect on and identify their strengths as readers, areas for improvement, and the strategies they found most helpful before, during, and after reading.",
                ]
            },
            {
                title: "Writing",
                overall: [
                    "Developing and Organizing Content: Generate, gather, and organize ideas and information to write for an intended purpose and audience;",
                    "Using Knowledge of Form and Style: Draft and revise their writing, using a variety of literary, informational, and graphic forms and stylistic elements appropriate for the purpose and audience;",
                    "Applying Knowledge of Conventions: Use editing, proofreading, and publishing skills and strategies, and knowledge of language conventions, to correct errors, refine expression, and present their work effectively;",
                    "Reflecting on Skills and Strategies: Reflect on and identify their strengths as writers, areas for improvement, and the strategies they found most helpful at different stages in the writing process."
                ]
            },
            {
                title: "Media Studies",
                overall: [
                    "Understanding Media Texts: Demonstrate an understanding of a variety of media texts;",
                    "Understanding Media Forms, Conventions, and Techniques: Identify some media forms and explain how the conventions and techniques associated with them are used to create meaning;",
                    "Creating Media Texts: Create a variety of media texts for different purposes and audiences, using appropriate forms, conventions, and techniques;",
                    "Reflecting on Skills and Strategies: Reflect on and identify their strengths as media interpreters and creators, areas for improvement, and the strategies they found most helpful in understanding and creating media texts.",
                ]
            },
        ]
    },
    assessment: {
        content: "There are three forms of assessment that will be used throughout this course:",
        tab: [
            {
                title: "Assessment for Learning: ",
                p: "Assessment for learning will directly influence student learning by reinforcing the connections between assessment and instruction, and provide ongoing feedback to the student. Assessment for learning occurs as part of the daily teaching process and helps teachers form a clear picture of the needs of the students because students are encouraged to be more active in their learning and associated assessment. Teachers gather this information to shape their teaching environment.",
                content: [
                    "Ongoing",
                    "Is tied to learning outcomes",
                    "Provides information that structures the teachers’ planning and instruction",
                    "Allows teachers to provide immediate and descriptive feedback that will guide student learning",
                ],
                p2: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
            },
            {
                title: "Assessment as Learning: ",
                p: "Assessment as learning is the use of a task or an activity to allow students the opportunity to use assessment to further their own learning. Self and peer assessments allow students to reflect on their own learning and identify areas of strength and need. These tasks offer students the chance to set their own personal goals and advocate for their own learning.",
                content: [],
                p2: "The purpose of assessment as learning is to enable students to monitor their own progress towards achieving their learning goals.",
            },
            {
                title: "Assessment of Learning: ",
                p: "Assessment of learning will occur at or near the end of a period of learning; this summary is used to make judgements about the quality of student learning using established criteria, to assign a value to represent that quality and to communicate information about achievement to students and parents.",
                content: [],
                p2: "Evidence of student achievement for evaluation is collected over time from three different sources - observations, conversations, and student products. Using multiple sources of evidence will increase the reliability and validity of the evaluation of student learning.",
            },
        ],
        p1: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
    },
    strategy: {
        p1: "Using a variety of instructional strategies, the teacher will provide numerous opportunities for students to develop skills of inquiry, problem solving, and communication as they investigate and learn fundamental concepts. The integration of critical thinking and critical inquiry skills will provide a powerful tool for reasoning and problem solving, and is reflected in a meaningful blend of both process and content.",
        p2: "",
        lst: [
            "Students interact in student-paced and instructor-paced interactive, engaging instructional lessons.",
            "Reading, Writing, Oral Communication strategies, and Media Studies enhance students’ literacy skills, especially for complex texts.",
            "Videos in the course illustrate topics such as literary devices, research methods, MLA formatting, novel studies, advertisements and public service announcements.",
            "Teaching on The Field Guide to the North American Teenager students through the novel while introducing racial discrimination and social and class inequality. Students connect these themes to real-life issues and situations.",
            "Scaffolding longer writing assignments (literary essays and research proposals) allow students to work with the process of creating thesis statements, outlines, drafts, and final copies. Teacher feedback at each level enables students to improve both style and content in their written pieces.",
            "By accomplishing prompts on interactive lessons, students can reflect on different texts. In addition, constant communication with teachers ensures that the students understand complex topics and apply them in their writing.",
            "Independent, creative and critical thinking are practiced throughout the units to prepare students for university.",
        ]
    },
    grade: {
        table1: {
            percentage: [
                "70%",
                "30%",
            ],
            category: [
                "Assessments of Learning Tasks Throughout the Term",
                "Final Written Examination And/Or RST",
            ],
        },
        p1: "A student’s final grade is reflective of their most recent and most consistent level of achievement.",
        p2: "The balance of the weighting of the categories of the achievement chart throughout the course is:",
        table2: {
            English: "100%",
            knowledge: "25%",
            inquiry: "25%",
            communication: "25%",
            applicatoin: "25%",
        },
    },
    report: {
        p1: "Student achievement will be communicated formally to students via an official report card. Report cards are issued at the midterm point in the course, as well as upon completion of the course. Each report card will focus on two distinct, but related aspects of student achievement. ",
        p2: "First, the achievement of curriculum expectations is reported as a percentage grade. Additionally, the course median is reported as a percentage. The teacher will also provide written comments concerning the student’s strengths, areas for improvement, and next steps. Second, the learning skills are reported as a letter grade, representing one of four levels of accomplishment. The report card also indicates whether an OSSD credit has been earned. ",
        p3: "Upon completion of a course, EVA will send a copy of the report card back to the student’s home school (if in Ontario) where the course will be added to the ongoing list of courses on the student’s Ontario Student Transcript. The report card will also be sent to the student’s home address.",
    },
}
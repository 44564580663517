export default function covertTabData(originalTabData) {
    return originalTabData.map(
        (tab) => {
            const newTab = {
                title: tab.title,
                content: [],
            };

            if (tab.p) {
                newTab.content.push(tab.p);
            }

            if (tab.content && tab.content.length > 0) {
                const listItems = tab.content.map((item, index) => `<li className=${index}>${item}</li>`);
                const ulString = `<ul>${listItems.join("")}</ul>`;
                newTab.content.push(ulString);
            }

            if (tab.p2) {
                newTab.content.push(tab.p2);
            }

            return newTab;
        }
    );
}
import { MTH1W } from "../../data/OSSD/Grade9/MTH1W";
import GradeCourseTemplate from "../../pageTemplate/GradeCourseTemplate";


export default function MTH1WPage() {
    return (
        <div>
            <GradeCourseTemplate data={MTH1W} />
        </div>
    )
}
import { useState } from "react";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

function Expectations(props) {
    const [type, setType] = useState(0);
    const [activeButton, setActiveButton] = useState(0);

    const handleClick = (val) => {
        setType(val);
        setActiveButton(val);

    }
    const right = props.expectations.content.map((item) => (
        <ul>
            {item.overall.map((lst) => (
                <li><IoMdCheckmarkCircleOutline className="bullet-point-icon" />{lst}</li>
            ))}
        </ul>
    ))

    return (
        <div className="expectation color-background">
            <div className="inner section">
            <h2>{props.expectations.title}</h2>
                <div className="grid-container">
                <div className="detail-list">
                    {props.expectations.content.map((item, index) => (
                        <button style={{ backgroundColor: activeButton === index ? " #065470	" : "white", color: activeButton === index ? "white" : "#063970", opacity: 1.0, }} onClick={() => handleClick(index)}>{item.title}</button>
                    ))}
                </div>
                    <div className="detail-content">
                        <p>Overall Expectations</p>
                        <p>By the end of this course, students will:</p>
                    {right[type]}
                </div>
            </div>
            </div>


        </div>
    );
}

export default Expectations;
export const FSF2D = {
    title: "Core French",
    description: {
        code: "FSF2D",
        department: "French",
        type: "Academic",
        credit: "1.0",
        grade: "10",
        prerequisite: "Core French, Grade 9, Academic or Applied",
        document: "French as a Second Language, The Ontario Curriculum, Grades 9 to 12, 2014",
        link: "https://www.edu.gov.on.ca/eng/curriculum/secondary/fsl912curr2014.pdf",
        developer: "Emerald Valley Academy",
        date: 2021,
        content: "This course provides opportunities for students to communicate in French about personally relevant, familiar, and academic topics in real-life situations with increasing independence. Students will exchange information, ideas, and opinions with others in guided and increasingly spontaneous spoken interactions. Students will develop their skills in listening, speaking, reading, and writing through the selective use of strategies that contribute to effective communication. They will also increase their understanding and appreciation of diverse French-speaking communities, and will develop skills necessary for lifelong language learning. ",
    },
    outline: {
        title: "FSF2D French- Total Hours (110 Hours)",
        units: [
            {
                title: "Unit One: Le présent",
                hour: "15 Hours",
                content: "In this unit, students will learn to communicate their meaning clearly, using appropriate verbs and different forms of past tense appropriately and following conventions for correct spelling, word order, and punctuation. They will explore various greetings, French culture in the world and write a variety of French presentations to convey information.",
            },
            {
                title: "Unit Two: Les informations",
                hour: "15 Hours",
                contetn: "In this unit, students will learn about messages in oral French texts about topics related to the news and various forms of media. They will learn about prepositions, pronouns and linking words",
            },
            {
                title: "Unit Three: La culture française",
                hour: "15 Hours",
                content: "In this unit, students will become familiar with using proper French verbs and coordinating conjunction to express facts and ideas.They will find out about aspects of French cultures, celebrations and traditions and make connections to personal experiences and their own and other communitiesStudents also focus on building their written and oral communication skills to help them to further become French speakers.",
            },
            {
                title: "Unit Four: Fous de sports",
                hour: "15 Hours",
                content: "In this unit, students will learn about verbs related to sports and the proper use of direct and indirect object pronouns in French. They will also produce prepared and spontaneous messages in French to communicate information, ideas, and opinions to describe things.",
            },
            {
                title: "Unit Five: Notre planète",
                hour: "15 Hours",
                content: "In this unit, students will focus on the conditional mood, impersonal versus personal expressions to express hypothetical events and their consequences. They will investigate topics related to our planet and the environment and write a formal letter and a blog to explain their environmental concerns.",
            },
            {
                title: "Unit Six: La Santé",
                hour: "15 Hours",
                content: "In this unit, students will read French texts to understand the elements that are important to stay in good health. They will also learn to conjugate pronominal verbs in the past tense to write about and to talk about your routines and events in the past.",
            },
            {
                title: "Unit Seven: Le Petit Nicolas",
                hour: "7 Hours",
                content: "In this unit, students will identify a variety of reading comprehension strategies and use them before, during, and after reading short stories about Le Petit Nicolas.",
            },
            {
                title: "Rich Summative Task 20%",
                hour: "10 Hours",
                content: "This is a summative task assigned at the end of the course that brings together many of the expectations covered throughout the course and is created to assess and evaluate a student’s ability to demonstrate their understanding of the expectations through not only the product, but through conversation and observation.",
            },
            {
                title: "Final Exam",
                hour: "3 Hours",
                content: "Students will complete a Final exam reviewing grammatical and lexical concepts learned throughout this course.",
            },
        ],
        note1: "This course is entirely online and does not require or rely on any textbook.",
        note2: "A scanner, smartphone camera, or similar device to upload handwritten or hand-drawn work",

    },
    expectations: {
        title: "FSF2D: Core French ,Grade 10",
        content: [
            {
                title: "Listening",
                overall: [
                    "determine meaning in a variety of authentic and adapted oral French texts, using a range of listening strategies;",
                    "interpret messages accurately while interacting in French for a variety of purposes and with diverse audiences;",
                    "demonstrate an understanding of information in oral French texts about aspects of culture in diverse French-speaking communities and other communities around the world, and of French sociolinguistic conventions used in a variety of situations and communities.",
                ],
            },
            {
                title: "Speaking",
                overall: [
                    "communicate information and ideas orally in French, using a variety of speaking strategies, appropriate language structures, and language appropriate to the purpose and audience;",
                    "participate in spoken interactions in French for a variety of purposes and with diverse audiences;",
                    "in their spoken communications, demonstrate an awareness of aspects of culture in diverse French-speaking communities and other communities around the world, and of the appropriate use of French sociolinguistic conventions in a variety of situations.",
                ]
            },
            {
                title: "Reading",
                overall: [
                    "determine meaning in a variety of authentic and adapted French texts, using a range of reading comprehension strategies;",
                    "identify the purpose(s), characteristics, and aspects of style of a variety of authentic and adapted text forms in French, including fictional, informational, graphic, and media forms; ",
                    "demonstrate an understanding of information in French texts about aspects of culture in diverse French-speaking communities and other communities around the world, and of French sociolinguistic conventions used in a variety of situations and communities.",
                ]
            },
            {
                title: "Writing",
                overall: [
                    "write French texts for different purposes and audiences, using a variety of forms and knowledge of language structures and conventions of written French appropriate for this level;",
                    "Use the stages of the writing process – including pre-writing, producing drafts, revising, editing, and publishing – to develop and organize content, clarify ideas and expression, correct errors, and present their work effectively;",
                    "in their written work, demonstrate an awareness of aspects of culture in diverse French-speaking communities and other communities around the world, and of the appropriate use of French sociolinguistic conventions in a variety of situations.",
                ]
            },
        ]
    },
    assessment: {
        content: "There are three forms of assessment that will be used throughout this course:",
        tab: [
            {
                title: "Assessment for Learning: ",
                p: "Assessment for learning will directly influence student learning by reinforcing the connections between assessment and instruction, and provide ongoing feedback to the student. Assessment for learning occurs as part of the daily teaching process and helps teachers form a clear picture of the needs of the students because students are encouraged to be more active in their learning and associated assessment. Teachers gather this information to shape their teaching environment.",
                content: [
                    "Ongoing",
                    "Is tied to learning outcomes",
                    "Provides information that structures the teachers’ planning and instruction",
                    "Allows teachers to provide immediate and descriptive feedback that will guide student learning",
                ],
                p2: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
            },
            {
                title: "Assessment as Learning: ",
                p: "Assessment as learning is the use of a task or an activity to allow students the opportunity to use assessment to further their own learning. Self and peer assessments allow students to reflect on their own learning and identify areas of strength and need. These tasks offer students the chance to set their own personal goals and advocate for their own learning.",
                content: [],
                p2: "The purpose of assessment as learning is to enable students to monitor their own progress towards achieving their learning goals.",
            },
            {
                title: "Assessment of Learning: ",
                p: "Assessment of learning will occur at or near the end of a period of learning; this summary is used to make judgements about the quality of student learning using established criteria, to assign a value to represent that quality and to communicate information about achievement to students and parents.",
                content: [],
                p2: "Evidence of student achievement for evaluation is collected over time from three different sources - observations, conversations, and student products. Using multiple sources of evidence will increase the reliability and validity of the evaluation of student learning.",
            },
        ],
        p1: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
    },
    strategy: {
        p1: "Core French FSF2D provides students opportunities to sharpen the skills they have previously acquired through various assignments ranging from interactive independent modules, applying previously acquired skills in their Core French course such as writing short essays and critiques, and critical analysis of relevant teacher-selected articles and texts. Presentation techniques form the basis of study as students create oral presentations through screencasts, social media, online applications and audio files in conjunction with media-related assignments that reflect their communication and grammar theory studies.",
        p2: "",
        lst: [
            "Students interact in student-paced and instructor-paced interactive, engaging instructional lessons.",
            "Listening, Speaking, Reading and Writing strategies enhance students’ literacy skills, especially for complex texts.",
            "Videos in the course illustrate topics such as today’s news and media, French culture around the world, sports, our environment and health. It will also approach structural and stylistic elements of grammar and vocabulary. ",
            "Teaching on news articles and current life events will help students connect these themes to real-life issues and situations.",
            "Scaffolding longer writing assignments (critique essays and research activities) allow students to work with the process of creating thesis statements, outlines, drafts, and final copies. Teacher feedback at each level enables students to improve both style and content in their written pieces.",
            "By accomplishing prompts on interactive lessons, students can reflect on different texts. In addition, constant communication with teachers ensures that the students understand complex topics and apply them in their writing.",
            "Independent, creative and critical thinking are practiced throughout the units to prepare students for university.",
        ]
    },
    grade: {
        table1: {
            percentage: [
                "70%",
                "20%",
                "10",
            ],
            category: [
                "Assessments of Learning Tasks Throughout the Term",
                "Rich Summative Task",
                "Final Exam",
            ],
        },
        p1: "A student’s final grade is reflective of their most recent and most consistent level of achievement.",
        p2: "The balance of the weighting of the categories of the achievement chart throughout the course is:",
        table2: {
            Core_French: "100%",
            knowledge: "25%",
            inquiry: "25%",
            communication: "25%",
            applicatoin: "25%",
        },
    },
    report: {
        p1: "Student achievement will be communicated formally to students via an official report card. Report cards are issued at the midterm point in the course, as well as upon completion of the course. Each report card will focus on two distinct, but related aspects of student achievement. ",
        p2: "First, the achievement of curriculum expectations is reported as a percentage grade. Additionally, the course median is reported as a percentage. The teacher will also provide written comments concerning the student’s strengths, areas for improvement, and next steps. Second, the learning skills are reported as a letter grade, representing one of four levels of accomplishment. The report card also indicates whether an OSSD credit has been earned. ",
        p3: "Upon completion of a course, EVA will send a copy of the report card back to the student’s home school (if in Ontario) where the course will be added to the ongoing list of courses on the student’s Ontario Student Transcript. The report card will also be sent to the student’s home address.",
    },
}
import Divider from "../../components/ToolComp/divider";
import Title from "../../components/ToolComp/title";
import { UniversityData } from "../../data/UniversityData";
import "../../styles/PageStyle/University.css";

export default function University() {
    return (
        <div className="university main-section">
            <Title title="Leading Universities Recognizing OSSD" />
            <div className="university-container ">
                {
                    Object.keys(UniversityData).map(
                        (country) => (
                            <div className={`country ${country}`} key={country}>
                                <h1>{country}</h1>
                                <Divider />
                                <div className="grid-university section">
                                    {
                                        UniversityData[country].map(
                                            (university) => (
                                                <a href={university.link} target="_blank" rel="noopener noreferrer">
                                                    <img
                                                        src={`/img/university_logo/${university.img}`}
                                                        alt={university.name}
                                                    />
                                                </a>
                                            )
                                        )
                                    }
                                </div>
                            </div>
                        )
                    )
                }
            </div>
        </div>
    )
}
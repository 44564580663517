import { ENG3U } from "../../data/OSSD/Grade11/ENG3U";
import GradeCourseTemplate from "../../pageTemplate/GradeCourseTemplate";


export default function ENG3UPage() {
    return (
        <div>
            <GradeCourseTemplate data={ENG3U} />
        </div>
    )
}
import "../../styles/CompStyle/divider.css";

export default function Divider() {
    return (
        <div className="divider">
            <span></span>
            <span>o</span>
            <span></span>
        </div>
    )
}
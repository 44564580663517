import { MCR3U } from "../../data/OSSD/Grade11/MCR3U";
import GradeCourseTemplate from "../../pageTemplate/GradeCourseTemplate";


export default function MCR3UPage() {
    return (
        <div>
            <GradeCourseTemplate data={MCR3U} />
        </div>
    )
}
import "../../styles/CompStyle/TwoColGrid.css";
import Divider from "./divider";
export default function TwoColGrid({ img, title, content, reversed = false }) {
    return (
        <div className="two-col-grid">
            <div className={`grid-img ${reversed ? 'img-reversed' : ''}`}>
                <img src={img} />
            </div>
            <div className={`grid-text ${reversed ? 'text-reversed' : ''}`}>
                <h2>{title}</h2>
                <Divider />
                {
                    content.map(
                        (item, index) => (
                            <p key={index} dangerouslySetInnerHTML={{ __html: item }} />
                        )
                    )
                }
            </div>
        </div>
    )
}
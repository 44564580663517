// import Table from "../../components/ToolComp/dataTable";
import Divider from "../../components/ToolComp/divider";
import Title from "../../components/ToolComp/title";
import "../../styles/PageStyle/Verify.css";


export default function Verify() {
    return (
        <div className="verify main-section">
            <Title title="Verification Portal" />
            <div className="verify-container section">
                <h1>Emerald Valley Academy Student Verification Portal</h1>
                <Divider />
                <p>The Ontario Secondary School Diploma (OSSD) is an esteemed credential issued by the Ontario Ministry of Education, widely recognized and respected by universities worldwide. As a Ministry-inspected school in Ontario and a global provider of the OSSD program, <span style={{ color: "rgb(184, 134, 11)", fontWeight: "bold" }}>Emerald Valley Academy</span> is deeply committed to maintaining the integrity of the OSSD diploma and ensuring a smooth transition to post-secondary education for all our graduates. This website is designed to facilitate the verification process for university admissions officers, allowing them to authenticate the Ontario Student Transcripts for both EVA students who have graduated and those who are currently in progress.</p>
                <p>To verify a student's record, whether they have already graduated or are currently enrolled, please contact <a href="mailto:admissions@evaschool.ca">admissions@evaschool.ca</a></p>
                {/* <p>To verify a student's record, whether they have already graduated or are currently enrolled, please enter the student's Ontario Education Number (OEN) and their birthdate as indicated on their Ontario Student Transcript.</p>
                <div className="table">
                    <Table />
                </div>
                <p>To obtain further information, verify a student who cannot be found, or report any concerns about the authenticity of documents, please contact <a href="mailto:admissions@evaschool.ca">admissions@evaschool.ca</a></p> */}
                {/* <p><span className="note">Note:</span>If this page is not functioning, for more accurate information and service, please email us at <a href="mailto:admissions@evaschool.ca">admissions@evaschool.ca</a></p> */}
            </div>

        </div>
    )
}
export const FSF1D = {
    title: "Core French",
    description: {
        code: "FSF1D",
        department: "French",
        type: "Academic",
        grade: "9",
        credit: "1.0",
        prerequisite: "Minimum of 600 hours of French instruction, or equivalent",
        document: "French as a Second Language, The Ontario Curriculum, Grades 9 to 12, 2014",
        link: "https://www.edu.gov.on.ca/eng/curriculum/secondary/fsl912curr2014.pdf",
        developer: "Emerald Valley Academy",
        date: 2021,
        content: "This course provides opportunities for students to communicate and interact in French with increasing independence, with a focus on familiar topics related to their daily lives. Students will develop their skills in listening, speaking, reading, and writing by using language learning strategies introduced in the elementary Core French program, and will apply creative and critical thinking skills in various ways. They will also enhance their understanding and appreciation of diverse French-speaking communities, and will develop skills necessary for lifelong language learning. ",
    },
    outline: {
        title: "FSF1D, Grade 9 Core French",
        units: [
            {
                title: "Unit One: Je me présente",
                hour: "35 Hours",
                content: "This unit focuses on students sharing information about themselves, as well as a friend. Using qualifying adjectives, they will write descriptive paragraphs. ",
            },
            {
                title: "Unit Two: Les médias",
                hour: "35 Hours",
                contetn: "This unit has students explore the various types of media and the way in which they are impacted by media on a regular basis. This unit asks students to think about themselves as a consumer and the way in which the media manipulates them.Students also become familiar with using proper French grammar to express their opinions and ideas. Students also focus on building their written and oral communication skills to help them to further become French speakers.",
            },
            {
                title: "Unit Three: Le bilinguisme au Canada",
                hour: "35 Hours",
                content: "This unit has students explore French culture and how it has developed over time. Students will also develop an understanding of Francophone culture and how it has helped to shape the Canadian identity. Students will also learn how to use direct and indirect objects as a grammar skill.They will also learn how to incorporate the appropriate pronouns and learn grammar concepts to express their ideas and opinions. Students will also put their research skills to use by researching the impact of the French language around the world. They also continue to practice their listening, speaking and oral communication skills to further develop their French abilities.",
            },
            {
                title: "RST and Final Exam 30%",
                hour: "5 Hours, written and oral RST",
                content: "Rich Summative Assessment 30% The final evaluation consists of speaking, reading and writing. A different format is used for the speaking components than the reading and writing. There are three parts to this Rich Summative Assessment. Together these final assessment tasks constitute 30% of the student’s final mark.",
            },
        ],
        note1: "This course is entirely online and does not require or rely on any textbook.",
        note2: "A scanner, smartphone camera, or similar device to upload handwritten or hand-drawn work",

    },
    expectations: {
        title: "FSF1D,Grade 9, Core French",
        content: [
            {
                title: "Listening",
                overall: [
                    "determine meaning in a variety of authentic and adapted oral French texts, using a range of listening strategies;",
                    "interpret messages accurately while interacting in French for a variety of purposes and with diverse audiences;",
                    "demonstrate an understanding of information in oral French texts about aspects of culture in diverse French-speaking communities and other communities around the world, and of French sociolinguistic conventions used in a variety of situations and communities.",
                ]
            },
            {
                title: "Speaking",
                overall: [
                    "communicate information and ideas orally in French, using a variety of speaking strategies, appropriate language structures, and language appropriate to the purpose and audience;",
                    "participate in spoken interactions in French for a variety of purposes and with diverse audiences;",
                    "in their spoken communications, demonstrate an awareness of aspects of culture in diverse French-speaking communities and other communities around the world, and of the appropriate use of French sociolinguistic conventions in a variety of situations.",
                ]
            },
            {
                title: "Reading",
                overall: [
                    "determine meaning in a variety of authentic and adapted French texts, using a range of reading comprehension strategies;",
                    "identify the purpose(s), characteristics, and aspects of style of a variety of authentic and adapted text forms in French, including fictional, informational, graphic, and media forms; ",
                    "demonstrate an understanding of information in French texts about aspects of culture in diverse French-speaking communities and other communities around the world, and of French sociolinguistic conventions used in a variety of situations and communities.",
                ]
            },
            {
                title: "Writing",
                overall: [
                    "write French texts for different purposes and audiences, using a variety of forms and knowledge of language structures and conventions of written French appropriate for this level;",
                    "Use the stages of the writing process – including pre-writing, producing drafts, revising, editing, and publishing – to develop and organize content, clarify ideas and expression, correct errors, and present their work effectively;",
                    "in their written work, demonstrate an awareness of aspects of culture in diverse French-speaking communities and other communities around the world, and of the appropriate use of French sociolinguistic conventions in a variety of situations.",
                ]
            }
        ]
    },
    assessment: {
        content: "There are three forms of assessment that will be used throughout this course:",
        tab: [
            {
                title: "Assessment for Learning: ",
                p: "Assessment for learning will directly influence student learning by reinforcing the connections between assessment and instruction, and provide ongoing feedback to the student. Assessment for learning occurs as part of the daily teaching process and helps teachers form a clear picture of the needs of the students because students are encouraged to be more active in their learning and associated assessment. Teachers gather this information to shape their teaching environment.",
                content: [
                    "Ongoing",
                    "Is tied to learning outcomes",
                    "Provides information that structures the teachers’ planning and instruction",
                    "Allows teachers to provide immediate and descriptive feedback that will guide student learning",
                ],
                p2: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
            },
            {
                title: "Assessment as Learning: ",
                p: "Assessment as learning is the use of a task or an activity to allow students the opportunity to use assessment to further their own learning. Self and peer assessments allow students to reflect on their own learning and identify areas of strength and need. These tasks offer students the chance to set their own personal goals and advocate for their own learning.",
                content: [],
                p2: "The purpose of assessment as learning is to enable students to monitor their own progress towards achieving their learning goals.",
            },
            {
                title: "Assessment of Learning: ",
                p: "Assessment of learning will occur at or near the end of a period of learning; this summary is used to make judgements about the quality of student learning using established criteria, to assign a value to represent that quality and to communicate information about achievement to students and parents.",
                content: [],
                p2: "Evidence of student achievement for evaluation is collected over time from three different sources - observations, conversations, and student products. Using multiple sources of evidence will increase the reliability and validity of the evaluation of student learning.",
            },
        ],
        p1: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
    },
    strategy: {
        p1: "Core French provides students opportunities to sharpen the skills they have previously acquired through various assignments ranging from interactive independent modules, applying previously acquired skills in their 600 hours French instruction such as writing short essays and critiques, research proposals, and critical analysis of relevant teacher-selected articles and texts. Presentation techniques form the basis of study as students create oral presentations through screencasts, social media, online applications and audio files in conjunction with media-related assignments that reflect their communication and grammar theory studies.",
        p2: "",
        lst: [
            "Students interact in student-paced and instructor-paced interactive, engaging instructional lessons.",
            "Listening, Speaking, Reading and Writing strategies enhance students’ literacy skills, especially for complex texts.",
            "Videos in the course illustrate topics such as introduction, structural and stylistic elements of grammar, vocabulary and digital media.",
            "Teaching on news articles and current life events will help students connect these themes to real-life issues and situations.",
            "Scaffolding longer writing assignments (critique essays and research projects) allow students to work with the process of creating thesis statements, outlines, drafts, and final copies. Teacher feedback at each level enables students to improve both style and content in their written pieces",
            "By accomplishing prompts on interactive lessons, students can reflect on different texts. In addition, constant communication with teachers ensures that the students understand complex topics and apply them in their writing.",
            "Independent, creative and critical thinking are practiced throughout the units to prepare students for university.",
        ],
    },
    grade: {
        table1: {
            percentage: [
                "70%",
                "30%",
            ],
            category: [
                "Assessments of Learning Tasks Throughout the Term",
                "Final Written Examination And/Or RST",
            ],
        },
        p1: "A student’s final grade is reflective of their most recent and most consistent level of achievement.",
        p2: "The balance of the weighting of the categories of the achievement chart throughout the course is:",
        table2: {
            French: "100%",
            knowledge: "25%",
            inquiry: "25%",
            communication: "25%",
            applicatoin: "25%",
        },
    },
    report: {
        p1: "Student achievement will be communicated formally to students via an official report card. Report cards are issued at the midterm point in the course, as well as upon completion of the course. Each report card will focus on two distinct, but related aspects of student achievement. ",
        p2: "First, the achievement of curriculum expectations is reported as a percentage grade. Additionally, the course median is reported as a percentage. The teacher will also provide written comments concerning the student’s strengths, areas for improvement, and next steps. Second, the learning skills are reported as a letter grade, representing one of four levels of accomplishment. The report card also indicates whether an OSSD credit has been earned. ",
        p3: "Upon completion of a course, EVA will send a copy of the report card back to the student’s home school (if in Ontario) where the course will be added to the ongoing list of courses on the student’s Ontario Student Transcript. The report card will also be sent to the student’s home address.",
    },

}
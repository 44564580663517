import Assessment from "../components/CourseComp/Assessment";
import CourseDescription from "../components/CourseComp/CourseDescription";
import Expectations from "../components/CourseComp/Expectation";
import FinalGrade from "../components/CourseComp/FinalGrade";
import Outline from "../components/CourseComp/Outline";
import Strategies from "../components/CourseComp/Strategies";
import Report from "../components/CourseComp/Report";
import Evaluation from "../components/CourseComp/Evaluation";

import "../styles/course.css";
import PlanConsideration from "../components/CourseComp/PlanConsideration";
export default function ESLCourseTemplate(props) {
    return (
        <div className="course main-section">
            <div className="course-container ">
                <CourseDescription description={props.data.description} />
                <Outline outline={props.data.outline} />
                <Expectations expectations={props.data.expectations} />
                <Assessment assessment={props.data.assessment} />
                <Strategies strategy={props.data.strategy} />
                <Evaluation evaluation={props.data.evaluation} />
                <FinalGrade grade={props.data.grade} />
                <Report report={props.data.report} />
                <PlanConsideration consideration={props.data.consideration} />
            </div>
        </div>
    );
}

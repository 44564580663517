import { ENL1W } from "../../data/OSSD/Grade9/ENL1W";
import GradeCourseTemplate from "../../pageTemplate/GradeCourseTemplate";


export default function ENL1WPage() {
    return (
        <div>
            <GradeCourseTemplate data={ENL1W} />
        </div>
    )
}
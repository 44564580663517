import Divider from "../ToolComp/divider";
import TogglePanel from "../ToolComp/togglePanel";
function Outline(props) {

    return (
        <div className="section">
            <div className="outline">
                <h2>Course Outline</h2>
                <Divider />
                <h4>{props.outline.title}</h4>

                <div>
                    {props.outline.units.map((item) => (
                        <TogglePanel title={`${item.title} (${item.hour})`} content={item.content} />
                    ))
                    }
                </div>
            </div>
            <div className="resource">
                <h4 style={{ color: '#B8860B', fontWeight: 'bold' }}>Resources required by the students</h4>
                <p><span className="note">Note: </span>{props.outline.note1}</p>
                <p>{props.outline.note2}</p>
                <p>{props.outline.note3}</p>
                <p>{props.outline.note4}</p>
                <p>{props.outline.note5}</p>
            </div>
        </div>
    );
}

export default Outline;
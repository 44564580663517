import { FSF2D } from "../../data/OSSD/Grade10/FSF2D";
import GradeCourseTemplate from "../../pageTemplate/GradeCourseTemplate";


export default function FSF2DPage() {
    return (
        <div>
            <GradeCourseTemplate data={FSF2D} />
        </div>
    )
}
import React from 'react';
import { useLocation } from 'react-router-dom';
import Hero from '../components/HomePageComp/Hero';
import Nav from '../components/CommonComp/Nav';

function HeroWrapper() {
    const location = useLocation();
    const isHomePage = location.pathname === '/';

    return isHomePage ? (
        <>
            <Hero />
            <Nav />
        </>
    ) : (
        <Nav />
    );
}

export default HeroWrapper;
export const ICS2O = {
    title: "Introduction to Computer Studies",
    description: {
        code: "ICS2O",
        department: "Computer Studies",
        type: "Open",
        credit: "1.0",
        grade: "10",
        prerequisite: "None",
        document: "Computer Studies, The Ontario Curriculum, Grades 10 to 12, 2008",
        link: "http://www.edu.gov.on.ca/eng/curriculum/secondary/computer10to12_2008.pdf",
        developer: "Emerald Valley Academy",
        date: 2021,
        content: "This course introduces students to computer programming. Students will plan and write simple computer programs by applying fundamental programming concepts, and learn to create clear and maintainable internal documentation. They will also learn to manage a computer by studying hardware configurations, software selection, operating system functions, networking, and safe computing practices. Students will also investigate the social impact of computer technologies, and develop an understanding of environmental and ethical issues related to the use of computers. ",
    },
    outline: {
        title: "ICS20 Introduction to Computer Studies- Total Hours (110 Hours)",
        units: [
            {
                title: "Unit One: Understanding Computers",
                hour: "10 Hours",
                content: "In this unit, students will use correct terminology to describe computer hardware, describe the functions of the internal components of a computer and assess user computing needs and select appropriate hardware components for different situations. Also students will understand Software products, Operating Systems, Home Computer Networking and Maintenance and Security.",
            },
            {
                title: "Unit Two:  Introduction to Programming",
                hour: "70 Hours",
                contetn: "In this unit, students will use correct terminology to describe programming concepts, describe the types of data that computers can process and store, explain the difference between constants and variables used in programming, determine the expressions and instructions to use in a programming statement, taking into account the order of operations and identify situations in which decision and looping structures are required. As well as Writing Programs, and Code Maintenance.",
            },
            {
                title: "Unit Three: Computers and Society",
                hour: "20 Hours",
                content: "In this unit, students will describe a variety of adaptive technologies that help to improve computer accessibility, explain the impact on the privacy of techniques for collecting and processing data, describe how portable computing devices and describe issues associated with access to online services. As well as Environmental Stewardship and Sustainability, Ethical Issues and Postsecondary Opportunities.",
            },
            {
                title: "Unit Four: Entrepreneurship",
                hour: "25 Hours",
                content: "In this unit, students will investigate some of the innovations and unique ideas that have come from people all around Canada. They will also look into the parallels and contrasts between successful entrepreneurs' personalities and character qualities. This inquiry will assist them in reflecting on their own qualities as a potential businessperson.",
            },
            {
                title: "Exam",
                hour: "10 Hours",
                content: "The final assessment task is a proctored exam worth 30% of the student's final mark. ",
            },
        ],
        note1: "This course is entirely online and does not require or rely on any textbook.",
        note2: "A scanner, smartphone camera, or similar device to upload handwritten or hand-drawn work",

    },
    expectations: {
        title: "ICS2O: Introduction to Computer Studies ,Grade 10, Open",
        content: [
            {
                title: "Understanding COmputers",
                overall: [
                    "Describe the functions of different types of hardware compoenents, and assess the hardware needs of users",
                    "Describe the different types of software products, and assess the software needs of users",
                    "Use the basic functions of an operating system correctly",
                    "explain the importance of software updates and system maintenance to manage the performance and increase the security of a computer",
                ],
            },
            {
                title: "Introcutions to Programming",
                overall: [
                    "Describe fundamental programmming concepts and constructs",
                    "Plan and write simple programs using fundamental programmming concepts",
                    "Apply basic code maintenance techiques when writing programs",
                ]
            },
            {
                title: "Computers and Society",
                overall: [
                    "Describe key aspects of the impact of computers and related technologies on society",
                    "Describe computer use policies that promote environmental stewardship and sustainability",
                    "Describe legal and ethical issues related to the use of computing devices",
                    "Describe possecondary education and career propespects related to computer stuides",
                ]
            },
        ]
    },
    assessment: {
        content: "There are three forms of assessment that will be used throughout this course:",
        tab: [
            {
                title: "Assessment for Learning: ",
                p: "Assessment for learning will directly influence student learning by reinforcing the connections between assessment and instruction, and provide ongoing feedback to the student. Assessment for learning occurs as part of the daily teaching process and helps teachers form a clear picture of the needs of the students because students are encouraged to be more active in their learning and associated assessment. Teachers gather this information to shape their teaching environment.",
                content: [
                    "Ongoing",
                    "Is tied to learning outcomes",
                    "Provides information that structures the teachers’ planning and instruction",
                    "Allows teachers to provide immediate and descriptive feedback that will guide student learning",
                ],
                p2: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
            },
            {
                title: "Assessment as Learning: ",
                p: "Assessment as learning is the use of a task or an activity to allow students the opportunity to use assessment to further their own learning. Self and peer assessments allow students to reflect on their own learning and identify areas of strength and need. These tasks offer students the chance to set their own personal goals and advocate for their own learning.",
                content: [],
                p2: "The purpose of assessment as learning is to enable students to monitor their own progress towards achieving their learning goals.",
            },
            {
                title: "Assessment of Learning: ",
                p: "Assessment of learning will occur at or near the end of a period of learning; this summary is used to make judgements about the quality of student learning using established criteria, to assign a value to represent that quality and to communicate information about achievement to students and parents.",
                content: [],
                p2: "Evidence of student achievement for evaluation is collected over time from three different sources - observations, conversations, and student products. Using multiple sources of evidence will increase the reliability and validity of the evaluation of student learning.",
            },
        ],
        p1: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
    },
    strategy: {
        p1: "Teachers continually build their awareness of students’ learning strengths and needs by observing and assessing their readiness to learn, their interests, and their learning styles and preferences. As teachers develop and deepen their understanding of individual students, they can respond more effectively to the students’ needs by differentiating instructional approaches – adjusting the method or pace of instruction, using different types of resources, allowing a wider choice of topics, even adjusting the learning environment, if appropriate, to suit the way their students learn and how they are best able to demonstrate their learning. Unless students have an Individual Education Plan with modified curriculum expectations, what they learn continues to be guided by the curriculum expectations and remains the same for all students.",
        p2: "",
        lst: [
            "Students interact in student-paced and instructor-paced interactive, engaging instructional lessons.",
            "Students will be engaged in classroom practices to enhance higher-order thinking capability and problem solving ability. ",
            "The students will have opportunities to learn in a variety of ways such as individually, cooperatively, independently with the teacher’s direction through investigation involving kinds of experience and through practice examples.  ",
            "The learning/teaching approaches and strategies will vary according to the learning goals and student’s needs in order to help students achieve the curriculum expectations. ",
            "Opportunities to learn in a variety of ways- individually, cooperatively, independently, with teacher direction, through hands-on experiences, and through examples followed by practice.",
            "By accomplishing prompts on interactive lessons, students can reflect on different texts. In addition, constant communication with teachers ensures that the students understand complex topics and apply them in their writing.",
        ]
    },
    grade: {
        table1: {
            percentage: [
                "70%",
                "30%",
            ],
            category: [
                "Assessments of Learning Tasks Throughout the Term",
                "Final Written Examination And/Or RST",
            ],
        },
        p1: "A student’s final grade is reflective of their most recent and most consistent level of achievement.",
        p2: "The balance of the weighting of the categories of the achievement chart throughout the course is:",
        table2: {
            Computer_Science: "100%",
            knowledge: "25%",
            inquiry: "25%",
            communication: "25%",
            applicatoin: "25%",
        },
    },
    report: {
        p1: "Student achievement will be communicated formally to students via an official report card. Report cards are issued at the midterm point in the course, as well as upon completion of the course. Each report card will focus on two distinct, but related aspects of student achievement. ",
        p2: "First, the achievement of curriculum expectations is reported as a percentage grade. Additionally, the course median is reported as a percentage. The teacher will also provide written comments concerning the student’s strengths, areas for improvement, and next steps. Second, the learning skills are reported as a letter grade, representing one of four levels of accomplishment. The report card also indicates whether an OSSD credit has been earned. ",
        p3: "Upon completion of a course, EVA will send a copy of the report card back to the student’s home school (if in Ontario) where the course will be added to the ongoing list of courses on the student’s Ontario Student Transcript. The report card will also be sent to the student’s home address.",
    },
}
export const CGC1D = {
    title: "Issues in Canadian Geography",
    description: {
        code: "CGC1D",
        department: "Canadian and World Studies",
        type: "Academic",
        grade: "9",
        credit: "1.0",
        prerequisite: "None",
        document: "Canadian and World Studies, The Ontario Curriculum, Grades 9 and 10, 2013",
        link: "http://www.edu.gov.on.ca/eng/curriculum/secondary/canworld910curr2013.pdf",
        developer: "Emerald Valley Academy",
        date: 2020 - 2021,
        content: "This course examines interrelationships within and between Canada’s natural and human systems and how these systems interconnect with those in other parts of the world. Students will explore environmental, economic, and social geographic issues relating to topics such as transportation options, energy choices, and urban development. Students will apply the concepts of geographic thinking and the geographic inquiry process, including spatial technologies, to investigate various geographic issues and to develop possible approaches for making Canada a more sustainable place in which to live. ",
    },
    outline: {
        title: "CGC1D, Grade 9 Issues in Canadian Geography",
        units: [
            {
                title: "Unit 1: Interactions in the Physical Environment",
                hour: "25 Hours",
                content: "Students learn about Canada's geography and apply geographic thinking concepts to questions relating to Canada’s size and location, the natural environment, physical landscapes, and the people that have made Canada home, including where Canadians choose to live and work.",
            },
            {
                title: "Unit 2: Managing Canada’s resources and industries",
                hour: "25 Hours",
                contetn: "Students learn about Canada's natural environment, including: physical landscape and regional variation, weather and climate, Canada's ecology, biospheres, vegetation and ecozones.",
            },
            {
                title: "Unit 3: Canada's Changing Population",
                hour: "25 Hours",
                content: "Students learn about Canada's population, including subjects such as: demography, population trends, interpreting statistics, immigration, global influences, settlement patterns, urbanization, and land use.",
            },
            {
                title: "Unit 4: Liveable Communities",
                hour: "25 Hours",
                content: "Students learn about sustainability and globalization. Students are asked to assess their own ecological footprints, as well as Canada's footprint compared to other countries. ",
            },
            {
                title: "Rich Task Summative 20%",
                hour: "8 Hours",
                content: "This is a summative task assigned at the end of the course that brings together many of the expectations covered throughout the course and is created to assess and evaluate a student’s ability to demonstrate their understanding of the expectations through not only the product, but through conversation and observation.",
            },
            {
                title: "Final Exam 10%",
                hour: "2 Hours",
                content: "This is a proctored exam worth 10% of your final grade.",
            },
        ],
        note1: "This course is entirely online and does not require or rely on any textbook.",
        note2: "A scanner, smartphone camera, or similar device to upload handwritten or hand-drawn work",

    },
    expectations: {
        title: "CGC1D,Grade 9, Issues in Canadian Geography",
        content: [
            {
                title: "Geographic Inuqiry and Skill Development",
                overall: [
                    "Geographic Inquiry: use the geographic inquiry process and the concepts of geographic thinking when investigating issues relating to Canadian geography.",
                    "Developing Transferable Skills: apply in everyday contexts skills, including spatial technolgy skills, developed through the investigation of canadian geography, and identify some careers in which a background in geography might be an asset."
                ]
            },
            {
                title: "Interactins in the Physical Environment",
                overall: [
                    "The Physical Environment and Human ActivitesL analyse various interactions between physical processes, phenomena, and events and human activities in Canada",
                    "Interralationships between Physical Systems, Processes, and Events: analyse characteristics of various physical processes,phenomena, and events affecting canada and their interralationship with global ohysical systems",
                    "The Characteristics of canada's Natural Environment: describe various charactersitics of the natural environment and the spatial distribution of pyuhsical features in canada, and explain the role of physical processes, phenomena, and events in shaping them.",
                ]
            },
            {
                title: "Managing Canada's Resources and Industries",
                overall: [
                    "The Sustainability of Resourese:analyse impacts of resource prolicy, resource managemtn, and consumer choices on resouce sustainability in canada",
                    "The Development of Resouce:analyse issues related to the distribution, availabilty, and development of natural resources in canda from a geographic perspective",
                    "Industries nad Economic Development: assess the relative importance of different industrial sectors to Canadian econmy and Canada's place in the glboal econnomy, and analyse factors that influence the location of indsutries in these sectors",
                ]
            }
        ]
    },
    assessment: {
        content: "There are three forms of assessment that will be used throughout this course:",
        tab: [
            {
                title: "Assessment for Learning: ",
                p: "Assessment for learning will directly influence student learning by reinforcing the connections between assessment and instruction, and provide ongoing feedback to the student. Assessment for learning occurs as part of the daily teaching process and helps teachers form a clear picture of the needs of the students because students are encouraged to be more active in their learning and associated assessment. Teachers gather this information to shape their teaching environment.",
                content: [
                    "Ongoing",
                    "Is tied to learning outcomes",
                    "Provides information that structures the teachers’ planning and instruction",
                    "Allows teachers to provide immediate and descriptive feedback that will guide student learning",
                ],
                p2: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
            },
            {
                title: "Assessment as Learning: ",
                p: "Assessment as learning is the use of a task or an activity to allow students the opportunity to use assessment to further their own learning. Self and peer assessments allow students to reflect on their own learning and identify areas of strength and need. These tasks offer students the chance to set their own personal goals and advocate for their own learning.",
                content: [],
                p2: "The purpose of assessment as learning is to enable students to monitor their own progress towards achieving their learning goals.",
            },
            {
                title: "Assessment of Learning: ",
                p: "Assessment of learning will occur at or near the end of a period of learning; this summary is used to make judgements about the quality of student learning using established criteria, to assign a value to represent that quality and to communicate information about achievement to students and parents.",
                content: [],
                p2: "Evidence of student achievement for evaluation is collected over time from three different sources - observations, conversations, and student products. Using multiple sources of evidence will increase the reliability and validity of the evaluation of student learning.",
            },
        ],
        p1: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
    },
    strategy: {
        p1: "Issues in Canadian Geography CGC1D provides students opportunities to sharpen the skills they have previously acquired through various assignments ranging from interactive independent learning tutorials, short essays, critical analysis and note taking. Presentation techniques form the basis of study as students create oral presentations through screencasts, and audio files in conjunction with geography-related assignments that reflect their understanding of issues in Canadian geography.  ",
        p2: "",
        lst: [
            "Students interact in student-paced and instructor-paced interactive, engaging instructional lessons.",
            "The Geographic Inquiry Process (formulate questions, gather and organize, interpret and analyse, evaluate and draw conclusions and communicate) enhances students to develop and refine their critical and creative skills, problem-solving skills, and communication skills, guiding students in their investigations of issues, events, developments, and/or various geographic phenomena.",
            "Videos in the course illustrate topics such as Interactions in the Physical Environment, Managing Canada’s resources and industries, Canada's Changing Population and Liveable Communities",
            "Scaffolding longer geography-related assignments allow students to work with the process of the geographic inquiry. Teacher feedback at each level enables students to improve both style and content in their projects",
            "By accomplishing prompts on interactive lessons, students can reflect on different texts. In addition, constant communication with teachers ensures that the students understand complex topics and apply them in their assessments",
            "The inquiry process is practiced throughout the units to prepare students for the next courses",
        ],
    },
    grade: {
        table1: {
            percentage: [
                "70%",
                "30%",
            ],
            category: [
                "Assessments of Learning Tasks Throughout the Term",
                "Final Written Examination And/Or RST",
            ],
        },
        p1: "A student’s final grade is reflective of their most recent and most consistent level of achievement.",
        p2: "The balance of the weighting of the categories of the achievement chart throughout the course is:",
        table2: {
            Geography: "100%",
            knowledge: "25%",
            inquiry: "25%",
            communication: "25%",
            applicatoin: "25%",
        },
    },
    report: {
        p1: "Student achievement will be communicated formally to students via an official report card. Report cards are issued at the midterm point in the course, as well as upon completion of the course. Each report card will focus on two distinct, but related aspects of student achievement. ",
        p2: "First, the achievement of curriculum expectations is reported as a percentage grade. Additionally, the course median is reported as a percentage. The teacher will also provide written comments concerning the student’s strengths, areas for improvement, and next steps. Second, the learning skills are reported as a letter grade, representing one of four levels of accomplishment. The report card also indicates whether an OSSD credit has been earned. ",
        p3: "Upon completion of a course, EVA will send a copy of the report card back to the student’s home school (if in Ontario) where the course will be added to the ongoing list of courses on the student’s Ontario Student Transcript. The report card will also be sent to the student’s home address.",
    },

}
import background from "../../image/8.png";
export default function Title(props) {
    const styles = {
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "center", // Set background position to top
        backgroundRepeat: "no-repeat",
        width: "auto",
        height: "10rem",
        opacity: "0.8",
        marginBottom: "4rem",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    }
    return (
        <div className="title" style={styles}>
            <h2>{props.title}</h2>
        </div>
    )
}
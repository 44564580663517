export const BBI2O = {
    title: "Introduction to Business",
    description: {
        code: "BBI2O",
        department: "Business Studies",
        type: "Open",
        credit: "1.0",
        grade: "10",
        prerequisite: "Business Studies, The Ontario Curriculum, Grades 9 to 10, 2006",
        document: "Business Studies, The Ontario Curriculum, Grades 11 to 12, 2006",
        link: "",
        developer: "Emerald Valley Academy",
        date: 2017 - 2018,
        content: "This course introduces you to the world of business. Students will develop an understanding of the functions of business, including accounting, marketing, information and communication technology, human resources, and production, and of the importance of ethics and social responsibility. This course builds a foundation for further studies in business and helps you develop the business knowledge and skills you will need in your everyday life.",
    },
    outline: {
        title: "BBI2O Business- Total Hours (110 Hours)",
        units: [
            {
                title: "Unit One: Business Fundamentals",
                hour: "25 Hours",
                content: "In this unit, students will investigate how an individual's needs might drive someone to create a business to address those needs. Students will also look at the differences between small and large firms, such as how they are structured and how ownership differs between them. Furthermore, they will investigate how trade between Canada and other nations influences corporate operations and creates obstacles that must be solved.",
            },
            {
                title: "Unit Two: Functions of a Business",
                hour: "25 Hours",
                contetn: "In this unit, students will learn about the various components that comprise a successful business. They will investigate the functions that each part performs, how they collaborate, and the problems they confront when conducting business. Students will also investigate how different techniques to dealing with employees effect productivity and profit, and how this affects the success of a firm. To develop the finest product or service possible, all of these elements must fit together and perform properly.",
            },
            {
                title: " Unit Three: Finance",
                hour: "25 Hours",
                content: "In this unit, students will investigate the many financial aid options accessible to company owners and individuals, as well as methods for calculating prospective costs and tracking the flow of money. They will also look into ways for companies to save, invest, and safeguard their money.",
            },
            {
                title: "Unit Four: Entrepreneurship",
                hour: "25 Hours",
                content: "In this unit, students will investigate some of the innovations and unique ideas that have come from people all around Canada. They will also look into the parallels and contrasts between successful entrepreneurs' personalities and character qualities. This inquiry will assist them in reflecting on their own qualities as a potential businessperson.",
            },
            {
                title: "RST",
                hour: "10 Hours",
                content: "The RST is 30% of the Final Mark",
            },
        ],
        note1: "This course is entirely online and does not require or rely on any textbook.",
        note2: "A scanner, smartphone camera, or similar device to upload handwritten or hand-drawn work",

    },
    expectations: {
        title: "BBI2O: Introduction to Business ,Grade 10",
        content: [
            {
                title: "Business Fundamentals",
                overall: [
                    "demonstrate an understanding of how businesses respond to needs, wants, supply, and demand;",
                    "compare types of businesses;",
                    "demonstrate an understanding of ethics and social responsibility in business;",
                    "demonstrate an understanding of the benefits and challenges for Canada in the field of international business.",
                ],
            },
            {
                title: "Functions of a Business",
                overall: [
                    "explain the role of production in business;",
                    "explain the role of human resources in business;",
                    "demonstrate an understanding of sound management practices in business;",
                    "demonstrate an understanding of the importance and role of marketing in business;",
                    "demonstrate an understanding of the importance and role of accounting in business;",
                    "demonstrate an understanding of the importance and role of information and communication technology in business.",
                ]
            },
            {
                title: "Finance",
                overall: [
                    "demonstrate an understanding of income and spending issues facing individuals and businesses;",
                    "demonstrate an understanding of how banks and other financial institutions operate;",
                    "demonstrate an understanding of effective investment practices;",
                    "analyse the role and importance of credit in personal and business finance."
                ]
            },
            {
                title: "Entrepreneurship",
                overall: [
                    "describe characteristics and skills associated with successful entrepreneurs and demonstrate an understanding of the contributions to Canadian business of selected entrepreneurs;",
                    "analyse the importance of invention and innovation in entrepreneurship",
                ]
            },
        ]
    },
    assessment: {
        content: "There are three forms of assessment that will be used throughout this course:",
        tab: [
            {
                title: "Assessment for Learning: ",
                p: "Assessment for learning will directly influence student learning by reinforcing the connections between assessment and instruction, and provide ongoing feedback to the student. Assessment for learning occurs as part of the daily teaching process and helps teachers form a clear picture of the needs of the students because students are encouraged to be more active in their learning and associated assessment. Teachers gather this information to shape their teaching environment.",
                content: [
                    "Ongoing",
                    "Is tied to learning outcomes",
                    "Provides information that structures the teachers’ planning and instruction",
                    "Allows teachers to provide immediate and descriptive feedback that will guide student learning",
                ],
                p2: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
            },
            {
                title: "Assessment as Learning: ",
                p: "Assessment as learning is the use of a task or an activity to allow students the opportunity to use assessment to further their own learning. Self and peer assessments allow students to reflect on their own learning and identify areas of strength and need. These tasks offer students the chance to set their own personal goals and advocate for their own learning.",
                content: [],
                p2: "The purpose of assessment as learning is to enable students to monitor their own progress towards achieving their learning goals.",
            },
            {
                title: "Assessment of Learning: ",
                p: "Assessment of learning will occur at or near the end of a period of learning; this summary is used to make judgements about the quality of student learning using established criteria, to assign a value to represent that quality and to communicate information about achievement to students and parents.",
                content: [],
                p2: "Evidence of student achievement for evaluation is collected over time from three different sources - observations, conversations, and student products. Using multiple sources of evidence will increase the reliability and validity of the evaluation of student learning.",
            },
        ],
        p1: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
    },
    strategy: {
        p1: "The purpose of this course is to provide students with the foundational knowledge to be successful in business studies. To ensure that the purpose is met, a wide variety of instructional strategies are used to provide learning opportunities that accommodate a variety of learning styles, interests, and ability levels. Through the use of discussions, this course offers students the opportunity to share their understanding both in oral as well as written form. This also gives them the opportunity to broaden their understanding as they read and comment on the posts of others. This course provides students with opportunities to research aspects of business independently, with direction and guiding questions provided within the course. ",
        p2: "",
        lst: [
            "Students interact in student-paced and instructor-paced interactive, engaging instructional lessons.",
            "Helping students become self-directed, lifelong learners is a fundamental aim of the guidance and career education curriculum.",
            "Engaged in active and experiential learning strategies, they tend to retain knowledge for longer periods and develop meaningful skills. ",
            "Active and experiential learning strategies also enable students to apply their knowledge and skills to real-life issues and situations.",
            "Opportunities to learn in a variety of ways- individually, cooperatively, independently, with teacher direction, through hands-on experiences, and through examples followed by practice.",
            "By accomplishing prompts on interactive lessons, students can reflect on different texts. In addition, constant communication with teachers ensures that the students understand complex topics and apply them in their writing.",
        ]
    },
    grade: {
        table1: {
            percentage: [
                "70%",
                "30%",
            ],
            category: [
                "Assessments of Learning Tasks Throughout the Term",
                "Final Written Examination And/Or RST",
            ],
        },
        p1: "A student’s final grade is reflective of their most recent and most consistent level of achievement.",
        p2: "The balance of the weighting of the categories of the achievement chart throughout the course is:",
        table2: {
            Introduce_to_Business: "100%",
            knowledge: "25%",
            inquiry: "25%",
            communication: "25%",
            applicatoin: "25%",
        },
    },
    report: {
        p1: "Student achievement will be communicated formally to students via an official report card. Report cards are issued at the midterm point in the course, as well as upon completion of the course. Each report card will focus on two distinct, but related aspects of student achievement. ",
        p2: "First, the achievement of curriculum expectations is reported as a percentage grade. Additionally, the course median is reported as a percentage. The teacher will also provide written comments concerning the student’s strengths, areas for improvement, and next steps. Second, the learning skills are reported as a letter grade, representing one of four levels of accomplishment. The report card also indicates whether an OSSD credit has been earned. ",
        p3: "Upon completion of a course, EVA will send a copy of the report card back to the student’s home school (if in Ontario) where the course will be added to the ongoing list of courses on the student’s Ontario Student Transcript. The report card will also be sent to the student’s home address.",
    },
}
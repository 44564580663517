export const MPM2D = {
    title: "Foundations of Mathematics",
    description: {
        code: "MPM2D",
        department: "Mathematics",
        type: "Academic",
        credit: "1.0",
        grade: "10",
        prerequisite: "MPM1D, Principles of Mathematics, Grade 9, Academic or MFM1P, Foundations of Mathematics, Grade 9, Applied and MPM1H, Mathematics Transfer Course, Grade 9",
        document: "Mathematics, The Ontario Curriculum, Grades 9 and 10, 2005",
        link: "",
        developer: "Emerald Valley Academy",
        date: 2023,
        content: "This course introduces you to the world of business. Students will develop an understanding of the functions of business, including accounting, marketing, information and communication technology, human resources, and production, and of the importance of ethics and social responsibility. This course builds a foundation for further studies in business and helps you develop the business knowledge and skills you will need in your everyday life.",
    },
    outline: {
        title: "MPM2D Foundations of Mathematics- Total Hours (110 Hours)",
        units: [
            {
                title: "Unit One: Analytic Geometry",
                hour: "33 Hours",
                content: "In this unit, students will investigate a variety of tools that can be used in geometric problem-solving scenarios.. Students will use their knowledge of analytic geometry tools to classify quadrilaterals based on their properties. Students will also use their knowledge of analytic geometry tools in conjunction with their knowledge of linear relations to solve complex geometry problems.",
            },
            {
                title: "Unit Two: (Quadratic Relations in the Form y = ax² + bx + c)",
                hour: "34 Hours",
                contetn: "In this unit, students will investigate the properties of a quadratic relation's vertex form and apply these properties to graph quadratic relations. Students will also learn how to convert from standard form to vertex form. Students will use these skills to solve real-world problems involving quadratic model maximums and minimums.",
            },
            {
                title: "Unit Three: Trigonometry",
                hour: "34 Hours",
                content: "Students will develop strategies for representing trinomial expressions in various forms in this unit. Students will learn how to choose the best strategy for any given problem. These abilities are critical and are regarded as foundational for the remainder of this course and future mathematics studies.",
            },
            {
                title: "Final Exam ",
                hour: "3 Hours",
                content: "This is a proctored exam worth 30% of your final grade.",
            },
            {
                title: "RST",
                hour: "6 Hours",
                content: "This is a proctored assessment worth 30% of your final grade",
            },
        ],
        note1: "This course is entirely online and does not require or rely on any textbook.",
        note2: "A scanner, smartphone camera, or similar device to upload handwritten or hand-drawn work",

    },
    expectations: {
        title: "MPM2D: Foundations of Mathematics ,Grade 10",
        content: [
            {
                title: "Quadratic Relations Of The Form Y = Ax2+Bx+C",
                overall: [
                    "determine the basic properties of quadratic relations;",
                    "relate transformations of the graph of y=x2 to the algebraic representation y=a(x– h)2+ k;",
                    "solve quadratic equations and interpret the solutions with respect to the corresponding relations;",
                    "solve problems involving quadratic relations.",
                ],
            },
            {
                title: "Analytic Geometry",
                overall: [
                    "model and solve problems involving the intersection of two straight lines;",
                    "solve problems using analytic geometry involving properties of lines and line segments;",
                    "verify geometric properties of triangles and quadrilaterals, using analytic geometry.",
                ]
            },
            {
                title: "Trigonometry",
                overall: [
                    "use their knowledge of ratio and proportion to investigate similar triangles and solve problems related to similarity;",
                    "solve problems involving right triangles, using the primary trigonometric ratios and the Pythagorean theorem;",
                    "solve problems involving acute triangles, using the sine law and the cosine law.",
                ]
            },
        ]
    },
    assessment: {
        content: "There are three forms of assessment that will be used throughout this course:",
        tab: [
            {
                title: "Assessment for Learning: ",
                p: "Assessment for learning will directly influence student learning by reinforcing the connections between assessment and instruction, and provide ongoing feedback to the student. Assessment for learning occurs as part of the daily teaching process and helps teachers form a clear picture of the needs of the students because students are encouraged to be more active in their learning and associated assessment. Teachers gather this information to shape their teaching environment.",
                content: [
                    "Ongoing",
                    "Is tied to learning outcomes",
                    "Provides information that structures the teachers’ planning and instruction",
                    "Allows teachers to provide immediate and descriptive feedback that will guide student learning",
                ],
                p2: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
            },
            {
                title: "Assessment as Learning: ",
                p: "Assessment as learning is the use of a task or an activity to allow students the opportunity to use assessment to further their own learning. Self and peer assessments allow students to reflect on their own learning and identify areas of strength and need. These tasks offer students the chance to set their own personal goals and advocate for their own learning.",
                content: [],
                p2: "The purpose of assessment as learning is to enable students to monitor their own progress towards achieving their learning goals.",
            },
            {
                title: "Assessment of Learning: ",
                p: "Assessment of learning will occur at or near the end of a period of learning; this summary is used to make judgements about the quality of student learning using established criteria, to assign a value to represent that quality and to communicate information about achievement to students and parents.",
                content: [],
                p2: "Evidence of student achievement for evaluation is collected over time from three different sources - observations, conversations, and student products. Using multiple sources of evidence will increase the reliability and validity of the evaluation of student learning.",
            },
        ],
        p1: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
    },
    strategy: {
        p1: "This course gives students the opportunity to develop the skills, knowledge, and habits that will support them in their education and career/life planning. Students will learn about global work trends, and seek opportunities within the school and community to expand and strengthen their transferable skills and their ability to adapt to the changing world of work. On the basis of exploration, reflective practice, and decision-making processes, students will make connections between their skills, interests, and values and their postsecondary options, whether in apprenticeship training, college, community living, university, or the workplace. They will set goals and create a plan for their first postsecondary year. As part of their preparation for the future, they will learn about personal financial management – including the variety of saving and borrowing tools available to them and how to use them to their advantage – and develop a budget for their first year after secondary school.",
        p2: "",
        lst: [
            "Students interact in student-paced and instructor-paced interactive, engaging instructional lessons.",
            "Helping students become self-directed, lifelong learners is a fundamental aim of the guidance and career education curriculum.",
            "Engaged in active and experiential learning strategies, they tend to retain knowledge for longer periods and develop meaningful skills. ",
            "Active and experiential learning strategies also enable students to apply their knowledge and skills to real-life issues and situations.",
            "Opportunities to learn in a variety of ways- individually, cooperatively, independently, with teacher direction, through hands-on experiences, and through examples followed by practice.",
            "By accomplishing prompts on interactive lessons, students can reflect on different texts. In addition, constant communication with teachers ensures that the students understand complex topics and apply them in their writing.",
        ]
    },
    grade: {
        table1: {
            percentage: [
                "70%",
                "30%",
            ],
            category: [
                "Assessments of Learning Tasks Throughout the Term",
                "Final Written Examination And/Or RST",
            ],
        },
        p1: "A student’s final grade is reflective of their most recent and most consistent level of achievement.",
        p2: "The balance of the weighting of the categories of the achievement chart throughout the course is:",
        table2: {
            Mathematics: "100%",
            knowledge: "25%",
            inquiry: "25%",
            communication: "25%",
            applicatoin: "25%",
        },
    },
    report: {
        p1: "Student achievement will be communicated formally to students via an official report card. Report cards are issued at the midterm point in the course, as well as upon completion of the course. Each report card will focus on two distinct, but related aspects of student achievement. ",
        p2: "First, the achievement of curriculum expectations is reported as a percentage grade. Additionally, the course median is reported as a percentage. The teacher will also provide written comments concerning the student’s strengths, areas for improvement, and next steps. Second, the learning skills are reported as a letter grade, representing one of four levels of accomplishment. The report card also indicates whether an OSSD credit has been earned. ",
        p3: "Upon completion of a course, EVA will send a copy of the report card back to the student’s home school (if in Ontario) where the course will be added to the ongoing list of courses on the student’s Ontario Student Transcript. The report card will also be sent to the student’s home address.",
    },
}
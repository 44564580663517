import { CGC1D } from "../../data/OSSD/Grade9/CGC1D";
import GradeCourseTemplate from "../../pageTemplate/GradeCourseTemplate";


export default function CGC1DPage() {
    return (
        <div>
            <GradeCourseTemplate data={CGC1D} />
        </div>
    )
}
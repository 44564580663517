import { ENG2D } from "../../data/OSSD/Grade10/ENG2D";
import GradeCourseTemplate from "../../pageTemplate/GradeCourseTemplate";


export default function ENG2DPage() {
    return (
        <div>
            <GradeCourseTemplate data={ENG2D} />
        </div>
    )
}
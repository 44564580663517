import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import Divider from "../ToolComp/divider";
function FinalGrade(props) {
    return (
        <div className="grade section">
            <h2>The Final Grade</h2>
            <Divider />
            <p>{props.grade.p1}</p>
            <ul>
                {props.grade.lst.map(
                    (item) => (
                        <li><IoMdCheckmarkCircleOutline className="bullet-point-icon" />  {item}</li>
                    )
                )}
            </ul>
        </div>
    );
}

export default FinalGrade;
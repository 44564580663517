export const OLC4O = {
    title: "Ontario Secondary School Literacy Course",
    description: {
        code: "OLC4O",
        department: "English",
        grade: "12",
        type: "Open",
        credit: "1.0",
        prerequisite: "Students who have been eligible to write the OSSLT at least twice and who have been unsuccessful at least once are eligible to take the course. (Students who have already met the literacy requirement for graduation may be eligible to take the course under special circumstances, at the discretion of the principal.)",
        document: "English – The Ontario Secondary School Literacy Course (OSSLC), The Ontario Curriculum, Grade 12, 2003",
        link: "https://www.edu.gov.on.ca/eng/curriculum/secondary/english12curr.pdf",
        developer: "Emerald Valley Academy",
        date: 2021,
        content: "This course is designed to help students acquire and demonstrate the cross-curricular literacy skills evaluated by the Ontario Secondary School Literacy Test (OSSLT). Students who complete the course successfully will meet the provincial literacy requirement for graduation.Students will read a variety of informational, narrative, and graphic texts and produce various forms of writing, including summaries, information paragraphs, opinion pieces, and news reports. Students will also maintain and manage a portfolio containing a record of their reading experiences and samples of their writing.",
    },
    outline: {
        title: "OLC4O Ontario Secondary School Literacy Course - Total Hours (110 Hours)",
        units: [
            {
                title: "Unit One: Literacy and Grammar",
                hour: "20 Hours",
                content: "Students will learn about literacy and its importance in their daily lives. They will learn about the processes of reading and writing. They will also review basic grammar concepts, which will help them understand and go through the subsequent units. The students will start their learning journal and portfolio at this point.",
            },
            {
                title: "Unit Two: Informational and Graphic Texts",
                hour: "20 Hours",
                contetn: "Students will learn about informational and graphic texts. They will go through a series of exercises to help them read, understand and write their own informational and graphic texts. .",
            },
            {
                title: " Unit Three: Summaries, Narrative Texts and Grammar",
                hour: "20 Hours",
                content: "Students will learn about grammar concepts that will strengthen their summary and narrative text writing. They will learn how to summarize any text adequately. They will also learn strategies that will help them comprehend narrative texts as well as create them.",
            },
            {
                title: " Unit Four : News Writing, Opinion Essays and Grammar ",
                hour: "25 Hours",
                content: "Students will learn about news writing, opinion essays and grammar in this unit. They will learn how to write news leads as well as solid opinion essays. This knowledge will be supported by grammar concepts relevant to the two writing styles.",
            },
            {
                title: "Unit Five: Oral Literacy",
                hour: "20 Hours",
                content: "Students will learn the importance of vocabulary, fluency and diction. They will also learn about political correctness. They will learn critical techniques to become more effective, more fair speakers.",
            },
            {
                title: " Rich Summative Task",
                hour: "5 Hours",
                content: "RST worth 30% of final mark: Students will complete a summative that includes content that has been worked on through the course, plus an oral metacognitive component.",
            },
        ],
        note1: "This course is entirely online and does not require or rely on any textbook.",
        note2: "A scanner, smartphone camera, or similar device to upload handwritten or hand-drawn work",
    },
    expectations: {
        title: "OLC4O Ontario Secondary School Literacy Course, Grade 11/12,Open",
        content: [
            {
                title: "Building Reading Skills",
                overall: [
                    "demonstrate the ability to read and respond to a variety of texts; demonstrate understanding of the organizational structure and features of a variety of informational, narrative, and graphic texts, includinginformation paragraphs, opinion pieces, textbooks, newspaper reports and magazine stories, and short fiction;demonstrate understanding of the content and meaning of informational, narrative, and graphic texts that they have read using a variety of reading strategies;",
                    "use a variety of strategies to understand unfamiliar and specialized words and expressions in informational, narrative, and graphic texts",
                ]
            },
            {
                title: "Building Writing Skills",
                overall: [
                    "demonstrate the ability to use the writing process by generating and organizing ideas and producing first drafts, revised drafts, and final polished pieces to complete a variety of writing tasks;",
                    "use knowledge of writing forms, and of the connections between form, audience, and purpose, to write summaries, information paragraphs, opinion pieces (i.e., series of paragraphs expressing an opinion), news reports, and personal reflections, incorporating graphic elements where necessary and appropriate.",
                ]
            },
            {
                title: "Understanding And Assessing Growth In Literacy",
                overall: [
                    "demonstrate understanding of the importance of communication skills in their everyday lives – at school, at work, and at home;demonstrate understanding of their own roles and responsibilities in the learning process;",
                    "demonstrate understanding of the reading and writing processes and of the role of reading and writing in learning; demonstrate understanding of their own growth in literacy during the course.",
                ]
            },
        ]
    },
    assessment: {
        content: "There are three forms of assessment that will be used throughout this course:",
        tab: [
            {
                title: "Assessment for Learning: ",
                p: "Assessment for learning will directly influence student learning by reinforcing the connections between assessment and instruction, and provide ongoing feedback to the student. Assessment for learning occurs as part of the daily teaching process and helps teachers form a clear picture of the needs of the students because students are encouraged to be more active in their learning and associated assessment. Teachers gather this information to shape their teaching environment.",
                content: [
                    "Ongoing",
                    "Is tied to learning outcomes",
                    "Provides information that structures the teachers’ planning and instruction",
                    "Allows teachers to provide immediate and descriptive feedback that will guide student learning",
                ],
                p2: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
            },
            {
                title: "Assessment as Learning: ",
                p: "Assessment as learning is the use of a task or an activity to allow students the opportunity to use assessment to further their own learning. Self and peer assessments allow students to reflect on their own learning and identify areas of strength and need. These tasks offer students the chance to set their own personal goals and advocate for their own learning.",
                content: [],
                p2: "The purpose of assessment as learning is to enable students to monitor their own progress towards achieving their learning goals.",
            },
            {
                title: "Assessment of Learning: ",
                p: "Assessment of learning will occur at or near the end of a period of learning; this summary is used to make judgements about the quality of student learning using established criteria, to assign a value to represent that quality and to communicate information about achievement to students and parents.",
                content: [],
                p2: "Evidence of student achievement for evaluation is collected over time from three different sources - observations, conversations, and student products. Using multiple sources of evidence will increase the reliability and validity of the evaluation of student learning.",
            },
        ],
        p1: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
    },
    strategy: {
        p1: "This course has been designed to scaffold literacy skills. Students will be reintroduced to literacy and the building blocks of language: grammar. Reviewing basic concepts will empower the students to become successful in the succeeding units. They are given many opportunities to practice their reading and writing skills through interactive lessons.",
        lst: [
            "Students will also reflect on their performance after each class through their learning journals. The course teacher will support individual learners’ needs by providing constructive, prompt and clear feedback.",
            "In the end, the goal is for students to become independent, critical and skilled learners. Therefore, they are encouraged to go through the course at their own pace, with their teacher facilitating the journey",
        ]
    },
    grade: {
        table1: {
            percentage: [
                "70%",
                "30%",
            ],
            category: [
                "Assessments of Learning Tasks Throughout the Term",
                "Final Written Examination And/Or RST",
            ],
        },
        p1: "A student’s final grade is reflective of their most recent and most consistent level of achievement.",
        p2: "The balance of the weighting of the categories of the achievement chart throughout the course is:",
        table2: {
            ONTARIO_SECONDARY_SCHOOL_LITERACY_COURSE: "100%",
            knowledge: "25%",
            inquiry: "25%",
            communication: "25%",
            applicatoin: "25%",
        },
    },
    report: {
        p1: "Student achievement will be communicated formally to students via an official report card. Report cards are issued at the midterm point in the course, as well as upon completion of the course. Each report card will focus on two distinct, but related aspects of student achievement. ",
        p2: "First, the achievement of curriculum expectations is reported as a percentage grade. Additionally, the course median is reported as a percentage. The teacher will also provide written comments concerning the student’s strengths, areas for improvement, and next steps. Second, the learning skills are reported as a letter grade, representing one of four levels of accomplishment. The report card also indicates whether an OSSD credit has been earned. ",
        p3: "Upon completion of a course, EVA will send a copy of the report card back to the student’s home school (if in Ontario) where the course will be added to the ongoing list of courses on the student’s Ontario Student Transcript. The report card will also be sent to the student’s home address.",
    },
}
export const ENG3U = {
    title: "Chemistry",
    description: {
        code: "ENG3U",
        department: "English",
        type: "University",
        credit: "1.0",
        grade: "12",
        prerequisite: "ENG2D, English, Grade 10, Academic",
        document: "English, The Ontario Curriculum, Grades 11 and 12, 2007",
        link: "https://www.edu.gov.on.ca/eng/curriculum/secondary/english1112currb.pdf",
        developer: "Emerald Valley Academy",
        date: 2021,
        content: "This course emphasizes the development of literacy, communication, and critical and creative thinking skills necessary for success in academic and daily life. Students will analyse challenging literary texts from various periods, countries, and cultures, as well as a range of informational and graphic texts, and create oral, written, and media texts in a variety of forms. An important focus will be on using language with precision and clarity and incorporating stylistic devices appropriately and effectively. The course is intended to prepare students for the compulsory Grade 12 university or college preparation course.",
    },
    outline: {
        title: "English ENG3U - Total Hours (110 Hours)",
        units: [
            {
                title: "Unit One: Short Stories",
                hour: "20 Hours",
                content: "In this unit, students will learn about PACTS and RACES, learn the skills needed to read short stories for meaning and to analyze, develop a deeper understanding of literary and poetic devices as well as figurative language, and integrate quotes in an essay.",
            },
            {
                title: "Unit Two: Non-Fiction and Media Studies",
                hour: "20 Hours",
                contetn: "In this unit, students will identify the topic and purpose of the essay, the purpose of formal and informal essays, write a persuasive essay using a variety of literary, informational and graphic forms. Students will also use grammatic conventions correctly to communicate their intended meaning clearly, analyze non-fiction essays, define communication and media, explain the communication process, and learn about basic communication theories.",
            },
            {
                title: " Unit Three: Poetry",
                hour: "20 Hours",
                content: "In this unit, students will be introduced to poetry, literary devices in poetry, identify the main idea of poetry. Aside from that, they will also understand stereotypes in media, learn about different forms of digital poetry, and learn about sonnets.",
            },
            {
                title: "Unit Four: Grammar/Born A Crime",
                hour: "20 Hours",
                content: "In this unit, students will learn about the simple and continuous verb tenses, perfect and perfect continuous verb tenses, types of sentences, and read and analyze the novel “Born A Crime",
            },
            {
                title: "Unit Five: Independent Novel Study ",
                hour: "20 Hours",
                content: "In this unit, students will learn about the independent novel guidelines and PACTS, and know different literary theories,",
            },
            {
                title: "RST and Final Exam 30%",
                hour: "",
                content: "Final Exam  – 2 Hours This is a proctored exam worth 20% of your final grade. Rich Task Summative – 8 Hours.This is a summative task assigned at the end of the course that brings together many of the expectations covered throughout the course and is created to assess and evaluate a student’s ability to demonstrate their understanding of the expectations through not only the product, but through conversation and observation. ",
            },
        ],
        note1: "This course is entirely online and does not require or rely on any textbook.",
        note2: "A scanner, smartphone camera, or similar device to upload handwritten or hand-drawn work",

    },
    expectations: {
        title: "Grade 11, University Preparation",
        content: [
            {
                title: "Oral Communication",
                overall: [
                    "Listening to Understand: listen in order to understand and respond appropriately in a variety of situations for a variety of purposes;",
                    "Speaking to Communicate: use speaking skills and strategies appropriately to communicate with different audiences for a variety of purposes;",
                    "Reflect on and identify their strengths as listeners and speakers, areas for improvement, and the strategies they found most helpful in oral communication situations.",
                ],
            },
            {
                title: "Reading And Literature Studies",
                overall: [
                    "Reading for Meaning: Read and demonstrate an understanding of a variety of literary, informational, and graphic texts, using a range of strategies to construct meaning;",
                    "Understanding Form and Style: Recognize a variety of text forms, text features, and stylistic elements and demonstrate understanding of how they help communicate meaning;",
                    "Reading With Fluency: use knowledge of words and cueing systems to read fluently;",
                    "Reflect on and identify their strengths as readers, areas for improvement, and the strategies they found most helpful before, during, and after reading."
                ]
            },
            {
                title: "Writing",
                overall: [
                    "Developing and Organizing Content: Generate, gather, and organize ideas and information to write for an intended purpose and audience;",
                    "Using Knowledge of Form and Style: Draft and revise their writing, using a variety of literary, informational, and graphic forms and stylistic elements appropriate for the purpose and audience;",
                    "Applying Knowledge of Conventions: Use editing, proofreading, and publishing skills and strategies, and knowledge of language conventions, to correct errors, refine expression, and present their work effectively;",
                    "Reflecting on Skills and Strategies: Reflect on and identify their strengths as writers, areas for improvement, and the strategies they found most helpful at different stages in the writing process."
                ]
            },
            {
                title: "Media Studies",
                overall: [
                    "Understanding Media Texts: Demonstrate an understanding of a variety of media texts;",
                    "Understanding Media Forms, Conventions, and Techniques: Iidentify some media forms and explain how the conventions and techniques associated with them are used to create meaning",
                    "Creating Media Texts: Ccreate a variety of media texts for different purposes and audiences, using appropriate forms, conventions, and techniques;",
                    "Reflecting on Skills and Strategies: Rreflect on and identify their strengths as media interpreters and creators, areas for improvement, and the strategies they found most helpful in understanding and creating media texts."
                ]
            },
        ]
    },
    assessment: {
        content: "There are three forms of assessment that will be used throughout this course:",
        tab: [
            {
                title: "Assessment for Learning: ",
                p: "Assessment for learning will directly influence student learning by reinforcing the connections between assessment and instruction, and provide ongoing feedback to the student. Assessment for learning occurs as part of the daily teaching process and helps teachers form a clear picture of the needs of the students because students are encouraged to be more active in their learning and associated assessment. Teachers gather this information to shape their teaching environment.",
                content: [
                    "Ongoing",
                    "Is tied to learning outcomes",
                    "Provides information that structures the teachers’ planning and instruction",
                    "Allows teachers to provide immediate and descriptive feedback that will guide student learning",
                ],
                p2: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
            },
            {
                title: "Assessment as Learning: ",
                p: "Assessment as learning is the use of a task or an activity to allow students the opportunity to use assessment to further their own learning. Self and peer assessments allow students to reflect on their own learning and identify areas of strength and need. These tasks offer students the chance to set their own personal goals and advocate for their own learning.",
                content: [],
                p2: "The purpose of assessment as learning is to enable students to monitor their own progress towards achieving their learning goals.",
            },
            {
                title: "Assessment of Learning: ",
                p: "Assessment of learning will occur at or near the end of a period of learning; this summary is used to make judgements about the quality of student learning using established criteria, to assign a value to represent that quality and to communicate information about achievement to students and parents.",
                content: [],
                p2: "Evidence of student achievement for evaluation is collected over time from three different sources - observations, conversations, and student products. Using multiple sources of evidence will increase the reliability and validity of the evaluation of student learning.",
            },
        ],
        p1: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
    },
    strategy: {
        p1: "Using a variety of instructional strategies, the teacher will provide numerous opportunities for students to develop skills of inquiry, problem-solving, and communication as they investigate and learn fundamental concepts. The integration of critical thinking and critical inquiry skills will provide a powerful tool for reasoning and problem solving, and is reflected in a meaningful blend of both process and content.",
        p2: "",
        lst: [
            "Students interact in student-paced and instructor-paced interactive, engaging instructional lessons.",
            "Reading, Writing, and Oral Communication strategies enhance students’ literacy skills, especially for complex texts.",
            "Videos in the course illustrate topics such as literary devices, research methods, MLA formatting, novel studies, advertisements and public service announcements.",
            "Teaching on Children of Blood and Bone leads students through the novel while introducing racial discrimination and social and class inequality. Students connect these themes to real-life issues and situations.",
            "Scaffolding longer writing assignments (literary essays and research proposals) allow students to work with the process of creating thesis statements, outlines, drafts, and final copies. Teacher feedback at each level enables students to improve both style and content in their written pieces.",
            "By accomplishing prompts on interactive lessons, students can reflect on different texts. In addition, constant communication with teachers ensures that the students understand complex topics and apply them in their writing.",
            "Independent, creative and critical thinking are practiced throughout the units to prepare students for university.",
        ]
    },
    grade: {
        table1: {
            percentage: [
                "70%",
                "30%",
            ],
            category: [
                "Assessments of Learning Tasks Throughout the Term",
                "Final Written Examination And/Or RST",
            ],
        },
        p1: "A student’s final grade is reflective of their most recent and most consistent level of achievement.",
        p2: "The balance of the weighting of the categories of the achievement chart throughout the course is:",
        table2: {
            English: "100%",
            knowledge: "25%",
            inquiry: "25%",
            communication: "25%",
            applicatoin: "25%",
        },
    },
    report: {
        p1: "Student achievement will be communicated formally to students via an official report card. Report cards are issued at the midterm point in the course, as well as upon completion of the course. Each report card will focus on two distinct, but related aspects of student achievement. ",
        p2: "First, the achievement of curriculum expectations is reported as a percentage grade. Additionally, the course median is reported as a percentage. The teacher will also provide written comments concerning the student’s strengths, areas for improvement, and next steps. Second, the learning skills are reported as a letter grade, representing one of four levels of accomplishment. The report card also indicates whether an OSSD credit has been earned. ",
        p3: "Upon completion of a course, EVA will send a copy of the report card back to the student’s home school (if in Ontario) where the course will be added to the ongoing list of courses on the student’s Ontario Student Transcript. The report card will also be sent to the student’s home address.",
    },
}
import React, { useState, useEffect } from 'react';
import "../../styles/CompStyle/WordChanger.css";

function WordChanger(props) {
    const [currentWord, setCurrentWord] = useState(0);
    const { words } = props;

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentWord((prevWord) => (prevWord + 1) % words.length);
        }, 3000); // Adjust the interval as needed

        return () => {
            clearInterval(interval);
        };
    }, [currentWord, words.length]);

    return (
        <div className="word-changer">
            <span className="word flip-in-x">
                {words[currentWord]}
            </span>
        </div>
    );
}

export default WordChanger;
// import { useState } from "react";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import TwoColGrid from "../ToolComp/twoColGrid";
import TabPanel from "../ToolComp/TabPanel";
import Divider from "../ToolComp/divider";
function PlanConsideration(props) {
    return (
        <div className="consideration section">
            <div className="overview">
                <h2>Program Planning Consideration</h2>
                <Divider />
                <p>{props.consideration.p1}</p>
                <ul>
                    {props.consideration.lst.map(
                        (item) => (
                            <li><IoMdCheckmarkCircleOutline className="bullet-point-icon" />   {item}</li>
                        )
                    )}
                </ul>
            </div>
            <img class="image-divider"
                src="/img/img-divider.png" />
            <div className="technology">
                <TwoColGrid img="/img/1.jpg" title="The Role of Technology in the Curriculum" content={[props.consideration.technology.p1]} reversed={false} />
            </div>
            <div className="exceptional">
                <TwoColGrid img="/img/2.jpg" title="Education for Exceptional Students" content={[props.consideration.exceptional.p1]} reversed={true} />
            </div>
            <div className="development">
                <h2>{props.consideration.development.title}</h2>
                <Divider />
                <h2>{props.consideration.development.title2}</h2>
                <p>{props.consideration.development.p1}</p>
                <ul>
                {props.consideration.development.lst.map(
                    (item) => (
                        <li><IoMdCheckmarkCircleOutline className="bullet-point-icon" />{item}</li>
                    )
                )}
                </ul>

                <p>
                    <span className="note">Note:</span>
                    {props.consideration.development.note}
                </p>
                <p>
                    {props.consideration.development.p2}
                </p>
                <div className="grid-container-two-equal-col">
                    <div className="grid-col">
                        <p>{props.consideration.development.p3}</p>

                    </div>
                    <div className="grid-col">
                        <p>{props.consideration.development.p4}</p>

                    </div>
                </div>
            </div>
            <div className="others-wrap">
                <TabPanel tab={props.consideration.development.tab} />
            </div>
        </div>
    );
}

export default PlanConsideration;
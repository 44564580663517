export const SCH4U = {
    title: "Chemistry",
    description: {
        code: "SCH4U",
        department: "Science",
        type: "University",
        credit: "1.0",
        prerequisite: "SCH3U, Chemistry, Grade 11, University Preparation",
        document: "Science, The Ontario Curriculum, Grades 11 and 12, 2008(Revised)",
        link: "http://www.edu.gov.on.ca/eng/curriculum/secondary/2009science11_12.pdf",
        developer: "Emerald Valley Academy",
        date: 2021,
        content: "This course enables students to strengthen their understanding of chemistry through the study of organic chemistry, energy changes and rates of reaction, chemical systems and equilibrium, electrochemistry, and atomic and molecular structure. As they examine chemical processes, students will improve their problem-solving and laboratory skills while also honing their ability to communicate scientific facts. The relevance of chemistry in everyday life will be emphasized, as well as assessing the environmental impact of chemical technologies.",
    },
    outline: {
        title: "Chemistry SCH4U - Total Hours (110 Hours)",
        units: [
            {
                title: "Unit One: Organic Chemistry",
                hour: "25 Hours",
                content: "Students will demonstrate an understanding of how the predictable chemical and physical properties of organic compounds are determined by their respective structures. They will also assess the significant implications of organic chemical reactions and their applications for society, human health, and the environment.",
            },
            {
                title: "Unit Two: Structure and Properties of Matter",
                hour: "20 Hours",
                contetn: "Students will demonstrate an understanding of how the nature of attractive forces that exist between particles in a substance determines the properties and limits the uses of that substance. They will also evaluate the societal benefits and costs of technological devices that are based on the principles of atomic and molecular structures.",
            },
            {
                title: "Unit Three:Energy Changes and Rates of Reaction",
                hour: "22 Hours",
                content: "Students will demonstrate an understanding of how energy changes and rates of reaction can be described quantitatively. They will investigate ways to improve the efficiency of chemical reactions by applying optimal conditions. Students will also evaluate the societal and environmental costs and benefits of technologies that transform energy.",
            },
            {
                title: "Unit Four: Chemical Systems and Equilibrium",
                hour: "20 Hours",
                content: "Students will demonstrate an understanding of the predictable ways in which chemical systems are dynamic and respond to changing conditions. They will also assess the significant implications for nature and industry of applying chemical systems at equilibrium.",
            },
            {
                title: "Unit Five: Electrochemistry",
                hour: "20 Hours",
                content: "Students will demonstrate an understanding of the predictable way in which electrons are transferred from one substance to another in oxidation and reduction reactions. They will also assess the significant implications of controlling and applying oxidation and reduction reactions for industry, health and safety, and the environment.",
            },
            {
                title: "Final Exam",
                hour: "3 Hours",
                content: "This is a proctored exam worth 30% of your final grade.",
            },
        ],
        note1: "This course is entirely online and does not require or rely on any textbook.",
        note2: "A scanner, smartphone camera, or similar device to upload handwritten or hand-drawn work",

    },
    expectations: {
        title: "SCH4U – Chemistry,Grade 12 University Preparation",
        content: [
            {
                title: "Scientific Investigation Skills And Career Exploration",
                overall: [
                    "demonstrate scientific investigation skills (related to both inquiry and research) in the four areas of skills (initiating and planning, performing and recording, analysing and interpreting, and communicating);",
                    "identify and describe careers related to the fields of science under study, and describe the contributions of scientists, including Canadians, to those fields.",
                ],
            },
            {
                title: "Organic Chemistry",
                overall: [
                    "assess the social and environmental impact of organic compounds used in everyday life, and propose a course of action to reduce the use of compounds that are harmful to human health and the environment;",
                    "investigate organic compounds and organic chemical reactions, and use various methods to represent the compounds;",
                    "demonstrate an understanding of the structure, properties, and chemical behavior of compounds within each class of organic compounds.",
                ]
            },
            {
                title: "Structure And Properties Of Matter",
                overall: [
                    "assess the benefits to society and evaluate the environmental impact of products and technologies that apply principles related to the structure and properties of matter;",
                    "investigate the molecular shapes and physical properties of various types of matter;",
                    "demonstrate an understanding of atomic structure and chemical bonding, and how they relate to the physical properties of ionic, molecular, covalent network, and metallic substances.",
                ]
            },
            {
                title: "Energy Changes And Rates Of Reaction",
                overall: [
                    "analyse technologies and chemical processes that are based on energy changes, and evaluate them in terms of their efficiency and their effects on the environment;",
                    "investigate and analyse energy changes and rates of reaction in physical and chemical processes, and solve related problems;",
                    "demonstrate an understanding of energy changes and rates of reaction.",
                ]
            },
            {
                title: "Chemical Systems And Equilibrium",
                overall: [
                    "analyse chemical equilibrium processes, and assess their impact on biological, biochemical, and technological systems; activity;",
                    "investigate the qualitative and quantitative nature of chemical systems at equilibrium, and solve related problems;demonstrate an understanding of the concept of dynamic equilibrium and the variables that cause shifts in the equilibrium of chemical systems.",
                ]
            },
            {
                title: "Electrochemistry",
                overall: [
                    "Analyse technologies and processes relating to electrochemistry, and their implications for society, health and safety, and the environment;",
                    "Investigate oxidation-reduction reactions using a galvanic cell, and analyse electrochemical reactions in qualitative and quantitative terms.",
                    "Demonstrate an understanding of the principles of oxidation-reduction reactions and the many practical applications of electrochemistry.",
                ]
            },
        ]
    },
    assessment: {
        content: "There are three forms of assessment that will be used throughout this course:",
        tab: [
            {
                title: "Assessment for Learning: ",
                p: "Assessment for learning will directly influence student learning by reinforcing the connections between assessment and instruction, and provide ongoing feedback to the student. Assessment for learning occurs as part of the daily teaching process and helps teachers form a clear picture of the needs of the students because students are encouraged to be more active in their learning and associated assessment. Teachers gather this information to shape their teaching environment.",
                content: [
                    "Ongoing",
                    "Is tied to learning outcomes",
                    "Provides information that structures the teachers’ planning and instruction",
                    "Allows teachers to provide immediate and descriptive feedback that will guide student learning",
                ],
                p2: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
            },
            {
                title: "Assessment as Learning: ",
                p: "Assessment as learning is the use of a task or an activity to allow students the opportunity to use assessment to further their own learning. Self and peer assessments allow students to reflect on their own learning and identify areas of strength and need. These tasks offer students the chance to set their own personal goals and advocate for their own learning.",
                content: [],
                p2: "The purpose of assessment as learning is to enable students to monitor their own progress towards achieving their learning goals.",
            },
            {
                title: "Assessment of Learning: ",
                p: "Assessment of learning will occur at or near the end of a period of learning; this summary is used to make judgements about the quality of student learning using established criteria, to assign a value to represent that quality and to communicate information about achievement to students and parents.",
                content: [],
                p2: "Evidence of student achievement for evaluation is collected over time from three different sources - observations, conversations, and student products. Using multiple sources of evidence will increase the reliability and validity of the evaluation of student learning.",
            },
        ],
        p1: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
    },
    strategy: {
        p1: "",
        p2: "",
        lst: [
            "This course's main goal is to assist students in learning science and applying their knowledge and skills. Language is used effectively, confidently, and flexibly by course writers.",
            "Effective instructional strategies and learning activities build on students' prior knowledge, stimulate their attention, and provide opportunities for meaningful practice. Students will be more interested if they can understand the link between the scientific concepts they are studying and how they are used in the world around them and in real-life situations.",
            "Teachers will design activities and challenges that actively involve students in investigations that respect the ideas and talents they bring to the table while also improving their conceptual understandings and vital abilities. Students will be able to employ scientific reasoning throughout their life if they understand huge ideas.",
            "Contextualized teaching and learning also gives teachers valuable insights into their students' thinking, conceptual grasp, and ability to reflect on their work. This knowledge enables teachers to provide assistance to students in order to improve their learning. To meet a diversity of learning styles, interests, and skill levels, a number of instructional tactics are employed to give learning opportunities.",
            "The learning goals and objectives in teaching physics are to develop students problem solving, reasoning, and meta-cognitive skills and become independent learners and excellent problem solvers in line with that teachers needs to know the student well by building rapport in order to gauge the capability of the student in order to plan an effective teaching method on how to make the students more engaged in different activities provided based on student needs.",
            "Incorporating interactive tools and recorded video discussion that help students stay focused and engaged in the class. Additionally, it aids students in mastering the physics concept.",
            "Virtual laboratories, simulations, and journal papers have all been used. Virtual laboratories, such as gizmos and labster, are one of the most effective ways to connect students to the actual world. Because of the dangers and limited face-to-face connection, students are able to undertake numerous experiments that are difficult to perform in traditional laboratories.",
            "Virtual clues can easily supplement auditory information, allowing students to better engage with ideas.",
            "The multi-sensory experiences improve their understanding and memorization skills. Drawings, diagrams, and image analysis are used to aid theory, as well as laying up instances to demonstrate its application side.",
            "The sequence of lab procedures can be better taught using pictures with words.",
            "Scaffolding on their laboratory activities and unit projects provide the students support level along the process of learning given by the teacher. Teacher feedback at each level enables students to improve both style and content in their written pieces.",
            "In physics, students must be familiar with the formulas for many types of physics problems, as one of the time-tested strategies of deepening neural pathways is to provide mnemonic devices. Linguistic hacks and rhymes are entertaining and active strategies to keep students engaged in the course.",
            "Students who are not challenged are both recipes for a lack of learning, so the quality and quantity of homework should be evaluated. Giving immediate feedback is the greatest way to keep track of students’' progress."
        ]
    },
    grade: {
        table1: {
            percentage: [
                "70%",
                "30%",
            ],
            category: [
                "Assessments of Learning Tasks Throughout the Term",
                "Final Written Examination And/Or RST",
            ],
        },
        p1: "A student’s final grade is reflective of their most recent and most consistent level of achievement.",
        p2: "The balance of the weighting of the categories of the achievement chart throughout the course is:",
        table2: {
            chemistry: "100%",
            knowledge: "25%",
            inquiry: "25%",
            communication: "25%",
            applicatoin: "25%",
        },
    },
    report: {
        p1: "Student achievement will be communicated formally to students via an official report card. Report cards are issued at the midterm point in the course, as well as upon completion of the course. Each report card will focus on two distinct, but related aspects of student achievement. ",
        p2: "First, the achievement of curriculum expectations is reported as a percentage grade. Additionally, the course median is reported as a percentage. The teacher will also provide written comments concerning the student’s strengths, areas for improvement, and next steps. Second, the learning skills are reported as a letter grade, representing one of four levels of accomplishment. The report card also indicates whether an OSSD credit has been earned. ",
        p3: "Upon completion of a course, EVA will send a copy of the report card back to the student’s home school (if in Ontario) where the course will be added to the ongoing list of courses on the student’s Ontario Student Transcript. The report card will also be sent to the student’s home address.",
    },
}
export const Grade9CourseOverviewData = {
    title: "Grade 9",
    courses: [
        {
            code: "AVI1O",
            name: "Art",
            collab: false,
            link: "/#/academic/Grade9/AVI1O",
        },
        {
            code: "ENL1W",
            name: "English",
            collab: false,
            link: "/#/academic/Grade9/ENL1W",
        },
        {
            code: "FSF1D",
            name: "French",
            collab: false,
            link: "/#/academic/Grade9/FSF1D",
        },
        {
            code: "CGC1D",
            name: "Geography",
            collab: false,
            link: "/#/academic/Grade9/CGC1D",
        },
        {
            code: "PPL1O",
            name: "Healthy Active Living",
            collab: true,
            link: "/#/academic/Grade9/PPL1O",
        },
        {
            code: "MTH1W",
            name: "Math",
            collab: false,
            link: "/#/academic/Grade9/MTH1W",
        },
        {
            code: "SNC1W",
            name: "Science",
            collab: false,
            link: "/#/academic/Grade9/SNC1W",
        },
    ]
}
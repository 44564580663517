export const ASM2O = {
    title: "Media Art",
    description: {
        code: "ASM2O",
        department: "Arts",
        type: "Open",
        credit: "1.0",
        grade: "10",
        prerequisite: "None",
        document: "The Arts, The Ontario Curriculum, Grades 9 to 10, 2010",
        link: "",
        developer: "Emerald Valley Academy",
        date: 2017 - 2018,
        content: "This course enables students to create media art works by exploring new media, emerging technologies such as digital animation, and a variety of traditional art forms such as film, photography, video, and visual arts. Students will acquire communications skills that are transferable beyond the media arts classroom and develop an understanding of responsible practices related to the creative process. Students will develop the skills necessary to create and interpret media art works.",
    },
    outline: {
        title: "ASM2O Media Arts- Total Hours (110 Hours)",
        units: [
            {
                title: "Unit One: Media Arts",
                hour: "25 Hours",
                content: "In this unit, students will demonstrate an understanding of one or more of the principles of media arts, graphics and digital media and apply the principle(s) of design to transform an existing work from one of the contributing arts. Students will explore a variety of traditional and emerging technologies, tools, and techniques, and use them to produce effective media art works.They will describe some ethical and legal practices associated with media arts and use appropriate technologies, tools, and techniques to create and present media art works for a variety of audiences.",
            },
            {
                title: "Unit Two: Photography",
                hour: "25 Hours",
                contetn: "In this unit, students will produce and refine media art works such as digital pictures, using experimentation, peer and/or teacher input, and personal reflection. They will differentiate colored and black and white pictures, its importance and beauty.This lesson will teach the students basic photography, the proper handling of the camera, proper picture taking, knowing their subject and being able to apply techniques in photography.",
            },
            {
                title: "Unit Three: Photoshop",
                hour: "25 Hours",
                content: "In this unit, students will use appropriate technologies and techniques to learn the basic photo editing and using significant tools. Students will be able to identify the stages of the creative and critical analysis processes, and identify and correctly use terminology related to the conventions and concepts of media arts when creating and analysing media artworks. They will learn to produce photo-manipulated images using photoshop and apply the principle(s) to transform an existing work from one of the contributing arts.",
            },
            {
                title: "Unit Four: Video Making and Editing",
                hour: "25 Hours",
                content: "Students will analyse and assess their personal skills and interests in relation to careers connected with design, production, distribution, or management processes in media art. They will communicate personal messages by creating and presenting videos and create stories using a variety of approaches and techniques.Students will be able to create a detailed record of their use of the creative process in the production and presentation of a media art work, using a tracking tool compatible with the medium/media used in that work.",
            },
            {
                title: "RST",
                hour: "10 Hours",
                content: "The RST is 30% of the Final Mark. In this unit, students will be tasked to create a Wix website that will serve as their portfolio. All their works since the start of the course will be showcased, and they will advertise themselves as a professional photographer. Students will be expected to make references from all lessons tackled in describing and advertising their work/service. ",
            },
        ],
        note1: "This course is entirely online and does not require or rely on any textbook.",
        note2: "A scanner, smartphone camera, or similar device to upload handwritten or hand-drawn work",

    },
    expectations: {
        title: "ASM2O: Media Arts ,Grade 10",
        content: [
            {
                title: "Creating And Presenting",
                overall: [
                    "The Creative Process: apply the stages of the creative process when performing notated and/or improvised music and composing and/or arranging music; ",
                    "The Elements of Music: apply elements of music when performing notated and improvised music and composing and/or arranging music;",
                    "Techniques and Technologies: use a variety of techniques and technological tools when performing music and composing and/or arranging music.",
                ],
            },
            {
                title: "Reflecting, Responding, And Andanalysing",
                overall: [
                    "The Critical Analysis Process: use the critical analysis process when responding to, analysing, reflecting on, and interpreting music;",
                    "Music and Society: demonstrate an understanding of how traditional, commercial, and art music reflect the society in which they were created and how they have affected communities or cultures;",
                    "Skills and Personal Growth: demonstrate an understanding of how performing, creating, and critically analysing music has affected their skills and personal development; ",
                    "Connections Beyond the Classroom: identify and describe various opportunities for continued engagement in music.",
                ]
            },
            {
                title: "Foundations",
                overall: [
                    "Theory and Terminology: demonstrate an understanding of music theory with respect to concepts of notation and the elements and other components of music, and use appropriate terminology relating to them; ",
                    "Characteristics and Development of Music: demonstrate an understanding of the history of some musical forms and of characteristics of types of music from around the world;",
                    "Conventions and Responsible Practices: demonstrate an understanding of responsible practices and performance conventions relating to music.",
                ]
            },
        ]
    },
    assessment: {
        content: "There are three forms of assessment that will be used throughout this course:",
        tab: [
            {
                title: "Assessment for Learning: ",
                p: "Assessment for learning will directly influence student learning by reinforcing the connections between assessment and instruction, and provide ongoing feedback to the student. Assessment for learning occurs as part of the daily teaching process and helps teachers form a clear picture of the needs of the students because students are encouraged to be more active in their learning and associated assessment. Teachers gather this information to shape their teaching environment.",
                content: [
                    "Ongoing",
                    "Is tied to learning outcomes",
                    "Provides information that structures the teachers’ planning and instruction",
                    "Allows teachers to provide immediate and descriptive feedback that will guide student learning",
                ],
                p2: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
            },
            {
                title: "Assessment as Learning: ",
                p: "Assessment as learning is the use of a task or an activity to allow students the opportunity to use assessment to further their own learning. Self and peer assessments allow students to reflect on their own learning and identify areas of strength and need. These tasks offer students the chance to set their own personal goals and advocate for their own learning.",
                content: [],
                p2: "The purpose of assessment as learning is to enable students to monitor their own progress towards achieving their learning goals.",
            },
            {
                title: "Assessment of Learning: ",
                p: "Assessment of learning will occur at or near the end of a period of learning; this summary is used to make judgements about the quality of student learning using established criteria, to assign a value to represent that quality and to communicate information about achievement to students and parents.",
                content: [],
                p2: "Evidence of student achievement for evaluation is collected over time from three different sources - observations, conversations, and student products. Using multiple sources of evidence will increase the reliability and validity of the evaluation of student learning.",
            },
        ],
        p1: "The purpose of assessment for learning is to create self-regulated and lifelong learners.",
    },
    strategy: {
        p1: "Media Arts ASM2O provides students opportunities to sharpen the skills they have previously acquired through various assignments ranging from interactive independent learning tutorials, essays, critical analysis, digital media activities and vlog creation. Presentation techniques form the basis of study as students create oral presentations through screencasts, vlogs, and audio files in conjunction with media arts-related assignments that reflect their understanding of visual arts.",
        p2: "",
        lst: [
            "Students interact in student-paced and instructor-paced interactive, engaging instructional lessons.",
            "Creating And presenting, Reflecting, Responding and Analysing and foundations strategies enhance students to develop and refine their critical and creative skills, problem-solving skills, and communication skills, while engaged in arts activities, projects, and exploration.",
            "Videos in the course illustrate topics such as photography, video making and editing and website creation.",
            "Scaffolding longer visual arts-related assignments (music video and website creation) allow students to work with the process of creating. Teacher feedback at each level enables students to improve both style and content in their art work projects.",
            "By accomplishing prompts on interactive lessons, students can reflect on different texts. In addition, constant communication with teachers ensures that the students understand complex topics and apply them in their media arts projects.",
            "Independent, creative and critical thinking are practiced throughout the units to prepare students for university.  ",
        ]
    },
    grade: {
        table1: {
            percentage: [
                "70%",
                "30%",
            ],
            category: [
                "Assessments of Learning Tasks Throughout the Term",
                "Final Written Examination And/Or RST",
            ],
        },
        p1: "A student’s final grade is reflective of their most recent and most consistent level of achievement.",
        p2: "The balance of the weighting of the categories of the achievement chart throughout the course is:",
        table2: {
            Media_Art: "100%",
            knowledge: "25%",
            inquiry: "25%",
            communication: "25%",
            applicatoin: "25%",
        },
    },
    report: {
        p1: "Student achievement will be communicated formally to students via an official report card. Report cards are issued at the midterm point in the course, as well as upon completion of the course. Each report card will focus on two distinct, but related aspects of student achievement. ",
        p2: "First, the achievement of curriculum expectations is reported as a percentage grade. Additionally, the course median is reported as a percentage. The teacher will also provide written comments concerning the student’s strengths, areas for improvement, and next steps. Second, the learning skills are reported as a letter grade, representing one of four levels of accomplishment. The report card also indicates whether an OSSD credit has been earned. ",
        p3: "Upon completion of a course, EVA will send a copy of the report card back to the student’s home school (if in Ontario) where the course will be added to the ongoing list of courses on the student’s Ontario Student Transcript. The report card will also be sent to the student’s home address.",
    },
}